<template lang="pug">
  .profile-doctors
    .profile-doctors__header
      .row.align-items-center
        .col-14.col-md-7
          .d-flex.align-items-center
            app-title(:type="'large'") Автоматический медработник

            .d-md-none.ms-auto
              button(
                type="button"
                @click="openModal('createAutoDoctor')"
                class=['button button--icon button--border-blue']
              )
                span.flex-shrink-0
                  app-svg-icon(:name="is.empty(autoDoctor) ? 'plus-thin' : 'edit'")


          .tabs-nav.separate-theme.mt-20
            .row.g-4
              .col.col-md-auto
                router-link(
                  :to="{name: 'doctors'}"
                  :class="['tabs-nav__button', 'button', 'w-100']"
                  active-class="is-active"
                )
                  span.button__content Медработники

              .col.col-md-auto
                router-link(
                  :to="{name: 'auto-doctor'}"
                  :class="['tabs-nav__button', 'button', 'w-100']"
                  active-class="is-active"
                )
                  span.button__content Автоматический медработник

        .col-14.col-md-7.d-none.d-md-block
          .d-flex.justify-content-end
            //app-auto-doctor-widget(
            //  v-if="autoDoctor"
            //  :type="'full'"
            //  :classes="'mt-auto'"
            //)
            button(
              v-if="is.empty(autoDoctor)"
              type="button"
              @click="openModal('createAutoDoctor')"
              class=['button button--icon button--border-blue']
            )
              span.flex-shrink-0
                app-svg-icon(name="plus-thin")

            button(
              v-else
              type="button"
              @click="openModal('createAutoDoctor')"
              class=['button button--border-blue', 'd-flex align-items-center']
            )
              span.me-3 Редактировать
              span.d-flex.flex-shrink-0
                app-svg-icon(name="edit")


    .profile-doctors__content.position-relative.mt-25
      app-loading(v-if="isLoadingExams")
      app-error-message(v-if="errorExams" :message="errorExams")

      template(v-if="is.empty(autoDoctor)")
        .row
          .col-7
            p.text-color--blue.mb-25 Задайте интервалы артериального давления, пульса, температуры и алкоголя, внутри которых должны находиться измерения пользователя, чтобы автоматически было принято решение о допуске и не допуске пользователя к выполнению трудовых обязанностей.

            .d-flex.align-items-center.justify-content-between
              .text-color--blue
                p Вы можете:
                ul.list-circle
                  li.mb-1 Создать настройки общие для всех
                  li Исключения для конкретного пользователя

              button(
                type="button"
                @click="openModal('createAutoDoctor')"
                class=['button button--accent button--middle']
              )
                .button__content
                  span.me-3
                    app-svg-icon(name="plus-thin")
                  span Создать сценарий

      template(v-else)
        transition(name="translucent")
          div(v-if="examsLocal")
            template(v-if="examsLocal && examsLocal.length")
              .profile-table.is-exams-table
                .profile-table__header.d-none.d-xl-block
                  .row.align-items-center.g-4
                    div(v-for="column in columns" :key="column.key" :class="column.class")
                      button(
                        type="button"
                        @click="sortingExams(column.key, column.inverse)"
                        class=['profile-table__sort-button']
                        :class="{'is-active': sortKey === column.key}"
                      )
                        span.d-flex.align-items-center
                          span(v-if="column.caption") {{ column.caption }}
                          span.flex-shrink-0(v-else-if="column.icon")
                            app-svg-icon(:name="column.icon")
                          span.flex-shrink-0.d-flex.ms-3
                            app-svg-icon(name="down-arrow")

                ul.profile-table__body(id="auto-doctor-exams")
                  li.profile-table__row(v-for="exam in examsLocal" :key="exam.id")
                    app-exam-card(
                      :data="exam"
                      :parent-id="'auto-doctor-exams'"
                      :columns="columns"
                      :classes="'d-none d-xl-block'"
                      @openModal="openExamDetailModal"
                      @openCertificate="openCertificate"
                      @openGallery="openGallery"
                    )
                    app-exam-card-mobile(
                      :data="exam"
                      :classes="'d-xl-none'"
                      @openModal="openExamDetailMobile"
                    )

              .d-flex.justify-content-center.mt-5
                app-pagination(
                  :total="pagination.total"
                  :limit="pagination.limit"
                  :current-page="currentPage"
                  :url="baseUrl"
                )

            template(v-else)
              .text-color--blue.text-size--small Осмотров не найдено



    // create auto-doctor modal
    app-create-auto-doctor-modal(
      v-if="autoDoctor"
      :slug="is.empty(autoDoctor) ? 'create' : 'update'"
      :is-visible="isModalVisible.createAutoDoctor"
      :initial-values="autoDoctor"
      @close="closeModal('createAutoDoctor')"
      @success="getAutoDoctor"
    )
    // exam detail modal
    app-exam-detail-modal(
      :is-visible="isModalVisible.examDetail"
      :data="currentExam.data"
      :type="currentExam.type"
      @close="closeModal('examDetail')"
      @openGallery="openGallery"
    )
    // exam detail modal for mobile
    app-exam-detail-mobile(
      :is-visible="isModalVisible.examDetailMobile"
      :data="currentExam.data"
      @openModal="openExamDetailModal"
      @openCertificate="openCertificate"
      @close="closeModal('examDetailMobile')"
    )
    // certificate modal
    app-certificate-modal(
      :is-visible="isModalVisible.certificate"
      :data="certificate"
      @close="closeModal('certificate')"
    )
    // gallery
    app-gallery(
      :is-visible="isModalVisible.gallery"
      :options="gallery"
      @close="closeGallery"
    )
</template>

<script>
import {mapState} from 'vuex';
import {actionTypes as examsActionTypes} from '@/store/modules/exams';
import {actionTypes as autoDoctorActionTypes} from '@/store/modules/autoDoctor';

import is from 'is_js';
import variables from '@/helpers/variables';

import AppTitle from '@/components/common/Title';
import AppSvgIcon from '@/components/common/SvgIcon';
import AppLoading from '@/components/common/Loading';
import AppErrorMessage from '@/components/common/ErrorMessage';
import AppModal from '@/components/common/Modal';
import AppValidationErrors from '@/components/common/ValidationErrors';
import AppGallery from '@/components/common/Gallery';
import AppPagination from '@/components/common/Pagination';

import AppCertificateModal from '@/components/profile/common/CertificateModal';
import AppAutoDoctorWidget from '@/components/profile/common/AutoDoctorWidget';
import AppExamCard from '@/components/profile/common/exams/ExamCard';
import AppExamCardMobile from '@/components/profile/common/exams/ExamCardMobile';
import AppExamDetailMobile from '@/components/profile/common/exams/ExamDetailMobile';
import AppExamDetailModal from '@/components/profile/common/exams/ExamDetailModal';
import AppCreateAutoDoctorModal from '@/components/profile/admin/doctors/CreateAutoDoctorModal';

import {examDetailModalMixin} from '@/mixins/examDetailModal';
import {examDetailMobileMixin} from '@/mixins/examDetailMobile';
import {certificateModalMixin} from '@/mixins/certificateModal';
import {galleryMixin} from '@/mixins/gallery';

export default {
  name: 'AppAdminAutoDoctor',
  components: {
    AppTitle,
    AppSvgIcon,
    AppLoading,
    AppPagination,
    AppErrorMessage,
    AppValidationErrors,
    AppModal,
    AppGallery,
    AppCertificateModal,
    AppExamCard,
    AppExamCardMobile,
    AppExamDetailMobile,
    AppExamDetailModal,
    AppCreateAutoDoctorModal,
    AppAutoDoctorWidget,
  },
  mixins: [galleryMixin, examDetailModalMixin, examDetailMobileMixin, certificateModalMixin],
  data() {
    return {
      is,
      v: variables,
      isModalVisible: {
        createAutoDoctor: false,
      },
      sortKey: '',
      sortInverse: false,
      pagination: {
        limit: variables.pagination.limit,
        total: 0,
      },
      sortOptions: [],
      columns: [
        {
          class: 'col-2',
          caption: 'Дата и время',
          key: 'exam_date_time',
          inverse: true,
        },
        {
          class: 'col-3',
          caption: 'Ф.И.О. пользователя',
          key: 'patient_full_name',
          inverse: false,
        },
        {
          class: 'col-1',
          icon: 'pulse',
          key: 'bp_high',
          inverse: true,
        },
        {
          class: 'col-1',
          icon: 'cardio',
          key: 'heart_rate',
          inverse: true,
        },
        {
          class: 'col-1',
          icon: 'temp',
          key: 'body_temp',
          inverse: true,
        },
        {
          class: 'col-1',
          icon: 'breathalyzer',
          key: 'alcohol',
          inverse: true,
        },
        {
          class: 'col-2 ms-auto',
          caption: 'Допуск',
          key: 'admission_to_work',
          inverse: false,
        },
      ],
      examsLocal: null,
    };
  },
  computed: {
    ...mapState({
      isLoadingExams: (state) => state.exams.isLoading,
      // exams: (state) => state.exams.data,
      errorExams: (state) => state.exams.error,

      autoDoctor: (state) => state.autoDoctor.data,
    }),
    currentPage() {
      return Number(this.$route.query.page || '1');
    },
    baseUrl() {
      return this.$route.path;
    },
    offset() {
      return this.currentPage * this.pagination.limit - this.pagination.limit;
    },
    settings() {
      return {
        filters: {
          is_auto_doctor: true,
          users: {
            negative: false,
            payload: this.autoDoctor.clients,
          },
          patients: {
            negative: false,
            payload: [],
          },
        },
        sorting_params: {
          desc: this.sortInverse,
          field: this.sortKey,
        },
      };
    },
  },
  watch: {
    currentPage() {
      this.getExams();
    },
  },
  created() {
    this.getAutoDoctor();
  },
  mounted() {},
  methods: {
    getAutoDoctor() {
      this.$store.dispatch(autoDoctorActionTypes.getAutoDoctor).then(() => {
        if (is.not.empty(this.autoDoctor))
          this.getExams(this.settings.filters, this.pagination.limit, this.offset);
      });
    },
    collectData(filters, limit, offset) {
      let data = {
        filters: Object.assign({}, this.settings.filters, filters),
        sorting_params: this.settings.sorting_params,
      };

      if (is.all.existy(limit, offset)) data.paging_params = {limit, offset};

      return data;
    },
    getExams(filters, limit, offset) {
      const data = this.collectData(filters, limit, offset);

      this.$store.dispatch(examsActionTypes.getExams, data).then((response) => {
        this.pagination.total = response.total;
        this.examsLocal = response.result;
        this.reverseSorting();
      });
    },
    sortingExams(sortKey, inverse) {
      this.sortKey = sortKey;
      this.sortInverse = inverse;

      this.getExams(this.settings.filters, this.pagination.limit, this.offset);
    },
    reverseSorting() {
      if (is.not.empty(this.sortKey)) {
        this.columns.forEach((el) => {
          if (this.sortKey === el.key) el.inverse = !el.inverse;
        });
      }
    },
    sortBySelect({value, inverse}) {
      this.sortOptions.forEach((el) => (el.selected = el.value === value));
      this.sortingExams(value, inverse);
    },
  },
};
</script>
