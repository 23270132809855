<template lang="pug">
  .row.justify-content-center
    app-back-button(:classes="'auth__back-button'")
      
    .col-14.col-xl-12.col-xxl-10
      .text-center.mb-5
        app-title(:type="'large'" :classes="'mb-3'") Восстановление доступа
        p.text-color--blue.text-size--small Для восстановления доступа в личный кабинет введите почту, на нее придет письмо со ссылкой для сброса пароля
      
      app-validation-errors(
        v-if='validationErrors'
        :classes="'mb-3'"
        :validation-errors='validationErrors'
      )
      form(@submit.prevent="onSubmit" :class="['form', {'is-submitting': isLoading}]")
        .row.flex-column.align-items-center
          .col.mb-5
            label.form__label
              input(
                :class="['form__field', { 'is-error': v$.form.email.$errors.length }]"
                type="email"
                v-model="v$.form.email.$model"
                placeholder="E-mail"
              )
            ul.form__error
              li(v-for="error of v$.form.email.$errors" :key="error.$uid")
                template(v-if="error.$validator === 'required'") {{ v.errors.required }}
                template(v-if="error.$validator === 'email'") {{ v.errors.email }}
          .col
            button(
              type="submit"
              :disabled="v$.$invalid"
              class=['button', 'button--accent', 'form__button']
            )
              span.button__content Восстановить доступ


    // success modal
    transition(name="fade" mode="out-in")
      app-modal(v-if="isModalVisible.success" @close="$router.push({name: 'home'})" classes="small")
        template(v-slot:body)
          .text-center.pt-4
            app-title(:type="'large'") Заявка создана!
            p.text-size--small.text-color--blue.mt-3 Для смены пароля необходимо пройти по ссылке с
            p.text-size--small {{ form.email }}

</template>

<script>
import {mapState} from 'vuex';
import {actionTypes} from '@/store/modules/users';

import useVuelidate from '@vuelidate/core';
import {required, email} from '@vuelidate/validators';

import variables from '@/helpers/variables';

import AppBackButton from '@/components/common/BackButton';
import AppValidationErrors from '@/components/common/ValidationErrors';
import AppTitle from '@/components/common/Title';
import AppSvgIcon from '@/components/common/SvgIcon';
import AppModal from '@/components/common/Modal';

import {modalsMixin} from '@/mixins/modals';

export default {
  name: 'AppRestore',
  components: {
    AppValidationErrors,
    AppTitle,
    AppSvgIcon,
    AppModal,
    AppBackButton,
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  mixins: [modalsMixin],
  data() {
    return {
      v: variables,
      form: {
        email: '',
      },
      validationErrors: '',
    };
  },
  validations() {
    return {
      form: {
        email: {
          email,
          required,
        },
      },
    };
  },
  computed: mapState({
    isLoading: (state) => state.users.isLoading,
    error: (state) => state.users.error,
  }),
  methods: {
    onSubmit() {
      if (!this.v$.$invalid) {
        this.$store
          .dispatch(actionTypes.forgotPassword, {
            email: this.form.email,
          })
          .then(() => {
            this.openModal('success');
          })
          .catch(() => (this.validationErrors = this.error));
      }
    },
  },
};
</script>
