import axios from '@/api/axios';

const register = (credentials) => axios.post('/api/v1/auth/regRequest', credentials);

const regStatus = (credentials) =>
  axios.get('/api/v1/auth/regRequest', {params: credentials});

const login = (credentials) => axios.post('/api/v1/auth/login', credentials);

const logout = () => axios.post('/api/v1/auth/logout');

const getCurrentUser = () => axios.get('/api/v1/auth/check');

export default {
  register,
  regStatus,
  login,
  logout,
  getCurrentUser,
};
