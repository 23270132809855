import axios from '@/api/axios';

const getFile = (hash) => {
  return axios
    .get(`/api/v1/attachments/document/${hash}`)
    .then((response) => (window.location.href = response.request.responseURL));
};

const getVideo = (hash) =>
  axios.get(`/api/v1/attachments/video/${hash}`).then((response) => response.data.url);

export default {
  getFile,
  getVideo,
};
