<template lang="pug"> 
  .profile-filter(:class="{'is-open': toggle}")
    .profile-filter__container.d-flex.flex-column
      button(
        @click="open"
        type="button"
        :class="{'is-dirty': isDirty}"
        class=['profile-filter__open-button-mobile', 'd-xl-none']
        :style="{'top': style.top}"
      )
        app-svg-icon(name="filter")

      .profile-filter__header.d-flex.align-items-center.justify-content-between.p-3
        button(
          @click="open"
          type="button"
          class="profile-filter__open-button"
        )
          app-svg-icon(name="filter")

        transition(name="fade")
          button(
            
            type="button"
            @click="clear"
            class=['profile-filter__button', 'text-color--blue-dark text-size--small']
          )
            span.me-3
              app-svg-icon(name="close")
            span Сбросить фильтры

        button(
          @click="close"
          type="button"
          class="filter__close-button"
        )
          app-svg-icon(name="right-long-arrow")

      .profile-filter__content.flex-grow-1.d-flex.flex-column.pt-3
        simplebar(class="profile-filter__form flex-grow-1")
          form(@submit.prevent :class="['form']" id="filter-form")
            .container-fluid.px-3
              .row.flex-column.align-items-center.g-3
                template
                  .col
                    .form__caption.mb-2 Пользователи:
                    .row.align-items-center.g-2
                      .col-7
                        label.form__radio
                          input(
                            type="radio"
                            name="users-negative"
                            :value="true"
                            v-model="form.negative.users"
                            :checked="form.negative.users"
                          )
                          span.form__radio-button Все кроме
                      .col-7
                        label.form__radio
                          input(
                            type="radio"
                            name="users-negative"
                            :value="false"
                            v-model="form.negative.users"
                            :checked="!form.negative.users"
                          )
                          span.form__radio-button Выбранные
                  .col
                    app-multiple-select(
                      ref="multipeSelectPatients"
                      :search-type="'patients'"
                      :placeholder="'Введите ФИО'"
                      @update="updateSelectedPatients"
                    )
                  .col
                    app-multiple-select(
                      ref="selectCase"
                      :search-type="'snilses'"
                      :placeholder="'СНИЛС'"
                      @update="updateSnilses"
                    )
                  .col
                    app-multiple-select(
                      ref="selectCase"
                      :search-type="'driving_license'"
                      :placeholder="'Водительское удостоверение'"
                      @update="updateDrivingLicenses"
                    )
                  .col
                    app-multiple-select(
                      ref="selectCase"
                      :search-type="'rfids'"
                      :placeholder="'RFID карта'"
                      @update="updateRfids"
                    )
                           
              // date interval
              template(v-if="options.date")
                .row.flex-column.align-items-center.g-3.mt-3
                  .col
                    .form__caption.mb-3 Интервал даты регистрации
                    .row.g-3
                      .col-7
                        date-picker(
                          v-model="form.date.from"
                          prefix-class="app"
                          placeholder="от"
                          format="DD.MM.YYYY"
                          :lang="datepicker.lang"
                          :editable="false"
                          :disabled-date="notAfterDateTo"
                          @change="datepickerChange('dateFrom')"
                          @clear="datepickerClear('dateFrom')"
                        )
                          template(v-slot:icon-calendar)
                            app-svg-icon(name="calendar")
  
                      .col-7
                        date-picker(
                          v-model="form.date.to"
                          prefix-class="app"
                          placeholder="до"
                          format="DD.MM.YYYY"
                          :lang="datepicker.lang"
                          :editable="false"
                          :disabled-date="notBeforeDateFrom"
                          @change="datepickerChange('dateTo')"
                          @clear="datepickerClear('dateTo')"
                        )
                          template(v-slot:icon-calendar)
                            app-svg-icon(name="calendar")
    
        // submit button
        .profile-filter__submit-button.flex-shrink-0.mt-auto
          button(
            type="submit"
            form="filter-form"
          
            @click='submit'
            class=['button', 'button--accent', 'form__button', 'mt-auto d-flex justify-content-center']
          )
            span.button__content
              span Показать
              transition(name="translucent" mode="out-in")
                //span.profile-filter__count(v-if="isDirty && count" :key="count") &nbsp;({{ count }})
      
      button(
        v-if="isFiltered && isDirty"
        type="button"
        @click="clear"
        class=['profile-filter__clear-button', 'd-flex flex-column align-items-center']
      )
        span.d-flex.mb-2
          app-svg-icon(name="close")
        span.text-size--extra-small.text-decoration--underline Сбросить
        
</template>

<script>
import {mapState} from 'vuex';
import {actionTypes as filtersActionTypes} from '@/store/modules/filters';
import {actionTypes as patientsActionTypes} from '@/store/modules/patients';


import {toLocalDate, toLocalTime} from '@/helpers/utils';
import is from 'is_js';

import simplebar from 'simplebar-vue';
import Slider from '@vueform/slider/dist/slider.vue2';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/ru';

import AppValidationErrors from '@/components/common/ValidationErrors';
import AppSvgIcon from '@/components/common/SvgIcon';
import AppMultipleSelect from '@/components/common/MultipleSelect';
import AppSelect from '@/components/common/Select';
import AppTooltip from '@/components/common/Tooltip';

export default {
  name: 'AppFilter',
  components: {
    AppTooltip,
    AppSelect,
    AppMultipleSelect,
    AppSvgIcon,
    AppValidationErrors,
    DatePicker,
    Slider,
    simplebar,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
      default: true,
    },
    isFiltered: {
      type: Boolean,
      required: true,
      default: false,
    },
    count: {
      type: Number,
      required: false,
      default: null,
    },
    options: {
      type: Object,
      required: true,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      toggle:false,
      admissionsChecked:[true,true],
      admissions:["admissed","not_admissed"],
      style: {
        top: null,
      },
      delay: 2000,
      anchor: '#isAnchorForBtn',
      hide_bp:true,
      hide_rate:true,
      hide_alcohol:true,
      hide_temp:true,
      form: {
        isDirty: {
          users: false,
          patients: false,
        
          dateFrom: false,
          dateTo: false,
          timeFrom: false,
          timeTo: false,
          caseOpened: false,
          snilses: [],
          rfids: [],
          driving_license: []
        },
        users: [],
        userTypes: ['person', 'company'],
        patients: [],
        negative: {
          users: null,
          patients: null,
        },
        devices: [],
        date: {
          from: null,
          to: new Date(),
        },
        time: {
          from: null,
          to: null,
        },
        status: null,
        caseOpened: null,
        coords: null,
        video: null,
        sync: null,
        bpHigh: {
          min: null,
          max: null,
        },
        bpLow: {
          min: null,
          max: null,
        },
        heartRate: {
          min: null,
          max: null,
        },
        bodyTemp: {
          min: null,
          max: null,
        },
      },

      datepicker: {
        lang: {
          formatLocale: {
            firstDayOfWeek: 1,
          },
          monthBeforeYear: false,
        },
      },
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.filters.isLoading,
      filters: (state) => state.filters.data,
      error: (state) => state.filters.error,
    }),
    isDirty() {
      return Object.values(this.form.isDirty).some((item) => item === true);
    },
    isTouchDevice() {
      return is.touchDevice();
    },
    collectData() {
      let data = {};

      if (is.existy(this.options.patients)) {
        data.patients = {
          negative: true,
          payload: [],
        };

        if (is.not.empty(this.form.patients)) {
          data.patients.payload = this.form.patients;

          if (is.existy(this.form.negative.patients))
            data.patients.negative = this.form.negative.patients;
        }
      }

             if (is.not.empty(this.form.users)) {
//alert('ssss')
         data.users = this.form.users;
          // if (is.existy(this.form.negative.users)) data.users.negative = this.form.negative.users;
         }
     

      if (is.existy(this.options.userTypes)) data.user_types = this.form.userTypes;

      if (is.existy(this.options.devices)) data.devices = this.form.devices;

      if (is.existy(this.form.status)) data.exam_completed = this.form.status;
      if (is.existy(this.form.caseOpened)) data.case_opened = this.form.caseOpened;
      if (is.existy(this.form.video)) data.has_video = this.form.video;
      if (is.existy(this.form.coords)) data.location_known = this.form.coords;
      if (is.existy(this.form.sync)) data.ntp_sync = this.form.sync;

      if (is.existy(this.form.date.from)) data.date_from = toLocalDate(this.form.date.from);
      if (is.existy(this.form.date.to)) data.date_to = toLocalDate(this.form.date.to);
      if (is.existy(this.form.time.from)) data.time_from = toLocalTime(this.form.time.from);
      if (is.existy(this.form.time.to)) data.time_to = toLocalTime(this.form.time.to);

      if (is.existy(this.form.bpHigh.min)) data.bp_high_min = this.form.bpHigh.min;
      if (is.existy(this.form.bpHigh.max)) data.bp_high_max = this.form.bpHigh.max;

      if (is.existy(this.form.bpLow.min)) data.bp_low_min = this.form.bpLow.min;
      if (is.existy(this.form.bpLow.max)) data.bp_low_max = this.form.bpLow.max;

      if (is.existy(this.form.heartRate.min)) data.heart_rate_min = this.form.heartRate.min;
      if (is.existy(this.form.heartRate.max)) data.heart_rate_max = this.form.heartRate.max;

      if (is.existy(this.form.bodyTemp.min)) data.body_temp_min = this.form.bodyTemp.min;
      if (is.existy(this.form.bodyTemp.max)) data.body_temp_max = this.form.bodyTemp.max;

      return data;
    },
  },
  watch: {
    hide_bp(){
this.$store.commit('hideBp', this.hide_bp)
 this.$store.commit('hideRate', this.hide_rate)
    },
       hide_rate(){
 this.$store.commit('hideRate', this.hide_rate)
    },
     hide_alcohol(){
 this.$store.commit('hideAlcohol', this.hide_alcohol)
    },
      hide_temp(){
 this.$store.commit('hideTemp', this.hide_temp)
    },

    isDirty(value) {
      if (!value) this.$emit('clear');
    },
  },
  async mounted() {
    const currentDate = new Date();
    const dateTo = currentDate.toLocaleString("ru", currentDate).split(',')
    currentDate.setDate(currentDate.getDate() - 30);
    this.form.date.from = currentDate
    const dateFrom =currentDate.toLocaleString("ru", currentDate).split(',')
    console.log(dateFrom[0])
    console.log(dateTo[0])
 

                 const data = {
                    "filters": {
                          "users": {
                            "negative": false,
                            "payload": []
                        },
                        "date_from": dateFrom[0],
                        "date_to": dateTo[0],
                        "admission_to_work": ["admissed", "not_admissed"],
                        "patients": {
                            "negative": false,
                            "payload": []
                        },
                    },
                    "paging_params": {
                        "limit": 15,
                        "offset": 0
                    },
                }
          
      await this.$store.dispatch("actionGetChartDataTable", data);
      await this.$store.dispatch("actionGetChartDataOnlyChart", data);
    
   // this.$store.commit('hideRate', true)
    if (
      is.existy(this.options.patients) ||
      is.existy(this.options.users) ||
      is.existy(this.options.devices)
    ) {
      this.getSearchData();
    }
  },
  methods: {
    handleTasks(task){
      const indexArr = this.admissions.indexOf(task);
      if(indexArr == -1){
        this.admissions.push(task)
      } else {
        this.admissions.splice(indexArr, 1);
      }
      
      console.log(task)
    },
    updateSelectedUsers(data) {
      this.form.isDirty.users = data.length > 0;
      this.form.users = data.map((el) => el.value);

      if (data.length) {
        if (is.null(this.form.negative.users)) this.form.negative.users = false;
      } else {
        this.form.negative.users = null;
        if (is.not.empty(this.$route.query)) this.$router.replace(this.$route.path);
      }

      //this.getCount();
    },
    updateSelectedPatients(data) {
      this.form.isDirty.patients = data.length > 0;
      this.form.patients = data.map((el) => el.value);

      if (data.length) {
        if (is.null(this.form.negative.patients)) this.form.negative.patients = false;
      } else this.form.negative.patients = null;

     // this.getCount();
    },
    updateSnilses(data){
      this.form.snilses = data;
    },
    updateRfids(data){
      this.form.rfids = data;
    },
    updateDrivingLicenses(data){
      this.form.driving_licenses = data;
    },
    changeCheckbox() {
      this.form.isDirty.userTypes = true;
    },

    datepickerChange(slug) {
      this.form.isDirty[slug] = true;
     // this.getCount();
    },
    datepickerClear(slug) {
      this.form.isDirty[slug] = false;
      this.form.isDirty[slug] = false;

      if (slug === 'dateTo') {
        this.form.time.to = null;
        this.form.isDirty.timeTo = false;
      }
      if (slug === 'dateFrom') {
        this.form.time.from = null;
        this.form.isDirty.timeFrom = false;
      }
    },
    notAfterDateTo(date) {
      if (this.form.date.to) return date > this.form.date.to;
    },
    notBeforeDateFrom(date) {
      if (this.form.date.from) return date < this.form.date.from;
    },
    notAfterTimeTo(time) {
      if (this.form.time.to) return time > this.form.time.to;
    },
    notBeforeTimeFrom(time) {
      if (this.form.time.from) return time < this.form.time.from;
    },
    
    setBtnPosition(anchor) {
      const el = document.querySelector(anchor || this.anchor);
      this.style.top = el ? `${el.getBoundingClientRect().y}px` : null;
    },
    open() {
      this.toggle = true
      //this.$emit('open');
    },
    close() {
      this.toggle = false
      //this.$emit('close');
     // if (this.isTouchDevice) this.submit();
    },
   async clear() {
     this.toggle = false;
      //this.admissionsChecked = [true,true,false,false];
      this.admissionsChecked = [true,true]
      this.admissions = ["admissed","not_admissed"]
      if (is.existy(this.options.patients)) this.$refs.multipeSelectPatients.clear();
        if(this.$store.state.auth.userRole=='admin'){
this.$refs.multipeSelectUsers.clear();
     }
    //  if (is.existy(this.options.devices)) this.$refs.multipeSelectDevices.clear();

      if (is.existy(this.options.patients)) this.form.patients = [];
    

      if (is.existy(this.options.time)) {
        this.form.time.from = null;
        this.form.time.to = null;
      }

     
      const currentDate = new Date();
const dateTo = currentDate.toLocaleString("ru", currentDate).split(',')
  currentDate.setDate(currentDate.getDate() - 30);
  this.form.date.from = currentDate
     this.form.date.to =new Date();
 const dateFrom =currentDate.toLocaleString("ru", currentDate).split(',')
    const data = {
                    "filters": {
                          "users": {
                            "negative": false,
                            "payload": []

                        },
                        "date_from": dateFrom[0],
                        "date_to": dateTo[0],
                        "admission_to_work": ["admissed", "not_admissed"],
                        "patients": {
                            "negative": true,
                            "payload": []
                        },
                    },
                    "paging_params": {
                        "limit": 15,
                        "offset": 0
                    },
                }
       await this.$store.dispatch("actionGetChartData", data);
        await this.$store.dispatch("actionGetChartDataOnlyChart", data);
      for (let key in this.form.isDirty) {
        if (Object.prototype.hasOwnProperty.call(this.form.isDirty, key)) {
          this.form.isDirty[key] = false;
        }
      }
    },
    // getCount() {
    //   this.$emit('getCount', this.collectData);
    // },
    getSearchData() {
      this.$store.dispatch(filtersActionTypes.getFilters);
    },
    async submit() {
      //if (is.falsy(this.isDirty)) return false;
      //alert('Submit')
      //this.$emit('submit', this.collectData);
      const data = {
          "filters": {
            'date_from': this.collectData.date_from,
            'date_to':  this.collectData.date_to,
            'time_from': this.collectData.time_from,
            'time_to': this.collectData.time_to,
            'admission_to_work':this.admissions,
            'users': {
                'negative': false,
                'payload': this.collectData.users
            },
            'patients': {
                'negative': true,
                'payload': this.collectData.patients.payload
            },
            'snilses': this.form.snilses,
            'rfids': this.form.rfids,
            'driving_licenses': this.form.driving_licenses
          },
          "paging_params": {
              "limit": 15,
              "offset": 0
          },
          "sorting_params": {
              "desc": false,
              "field": ""
          }
        }
        this.$store.commit('admissions', this.admissions);
        this.$store.commit('loading', true);
        await this.$store.dispatch(patientsActionTypes.getPatients, data);
        this.$store.commit('loading', false);
  
    },
  },
};
</script>

<style lang="scss">
$bg-color: #0c1120;

.profile-filter {
  pointer-events: none;
  position: fixed;
  z-index: get($index, xl);
  top: 0;
  right: 0;
  width: 16.66667%;
  height: 100%;
  padding-top: $header-height;
  @include transition(transform);
  transform: translateX(85%);

  &:before {
    @include pseudo-wrapper();
    background-color: rgba($bg-color, 0.8);
    backdrop-filter: blur(4px);
    @include transition();
    opacity: 0;
  }
  @include mediaToDown($xxl) {
    width: 25%;
  }
  @include mediaToDown($xl) {
    width: 50%;
    transform: translateX(100%);

    &.is-open {
      .profile-filter {
        &__container {
          box-shadow: get($shadows, default);
        }
      }
    }
  }
  @include mediaToDown($md) {
    padding-top: 0;
    width: 100%;

    &.is-open {
      padding-top: $spacer;
      padding-left: $spacer;
      padding-right: $spacer;

      &:before {
        opacity: 1;
        transition-delay: #{$transition-duration}s;
      }
    }
  }
  &.is-open {
    transform: translateX(0);

    .profile-filter {
      &__content {
        opacity: 1;
        visibility: visible;
      }
      &__clear-button,
      &__open-button-mobile {
        opacity: 0;
        visibility: hidden;
      }
    }
  }
  &__container {
    position: relative;
    pointer-events: auto;
    width: 100%;
    height: 100%;
    background-color: get($colors, white);
    box-shadow: get($shadows, default);
    border-radius: $border-radius-sm 0 0 0;
    color: get($colors, blue);
    padding-top: $spacer * 1.3;
    @include transition();

    @include mediaToDown($xl) {
      box-shadow: none;
    }
  }
  &__header {
    width: 100%;
  }
  &__content {
    position: relative;
    @include transition();
    opacity: 0;
    visibility: hidden;
    height: 90%;
  }
  &__form {
    max-height: 90%;
    overflow-x: hidden;
    padding-bottom: $spacer;
  }
  &__submit-button {
    position: relative;
    width: 105%;
    left: -5%;
    padding: $spacer * 1.2 $spacer * 1.2 $spacer * 1.2 $spacer * 2.3;
    border: $border-width solid get($colors, blue-light);
    border-radius: $border-radius-lg 0 0 0;
    background-color: get($colors, white);
    box-shadow: get($shadows, default);

    @include mediaToDown($xl) {
      border-radius: $border-radius-lg $border-radius-lg 0 0;
    }
    @include mediaToDown($md) {
      width: 100vw;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  &__count {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 100%;
  }
  &__clear-button {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 15%;
    background: get($gradients, orange);
    color: get($colors, white);
    padding: $spacer 0;
    @include transition();

    @include interaction('mouse') {
      &:hover {
        box-shadow: get($shadows, default);
      }
    }
  }
  &__open-button,
  &__close-button {
    @include transition(color);

    @include interaction('mouse') {
      &:hover {
        color: get($colors, orange-hover);
      }
    }
  }
  &__open-button-mobile {
    position: absolute;
    z-index: get($index, default);
    width: $spacer * 4.4;
    height: $spacer * 4.4;
    right: 100%;
    top: 0;
    background-color: get($colors, white);
    border-radius: $spacer * 3 $spacer * 1.6 0 $spacer * 3;
    padding: $spacer;
    box-shadow: get($shadows, default);
    transition: background-color #{$transition-duration}s, color #{$transition-duration}s;
    transform: translateY($spacer);

    &.is-dirty {
      background-color: get($colors, blue-dark);
      color: get($colors, white);
    }
  }
  .icon {
    &__filter {
      width: $spacer * 2.2;
      height: $spacer * 2.2;
    }
  }
}
</style>
