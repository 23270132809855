<template lang="pug">
  section(class=['patient-card'] :class="classes")
    app-accordion(
      :id="data.code"
      :parent-id="'patient-exams'"
      class=['profile-card', 'patient-card__accordion']
    )
      template(v-slot:header)
        .patient-card__header
          .row.align-items-center.gx-4
            .col-3
              .d-flex.align-items-center
                app-avatar(
                  :src="data.avatar"
                  :viewPhoto="true"
                  :type="'patient'"
                  @openGallery="openGallery"
                  @change="changeAvatar"
                )
                .ms-3
                  span.text-overflow(v-if="data.email" :title="data.email") {{ data.email }}
                  span.text-color--blue(v-else) нет e-mail

            .col-2
              span(v-if="data.phone") {{ data.phone }}
              span.text-color--blue(v-else) нет телефона

            .col-2
              span {{ toLocalDate(data.creation_date) }}

            .col-3
              span.text-overflow(:title="data.full_name") {{ data.full_name }}

            .col-2
              .d-flex.align-items-center
                span(v-if="data.exam_count") {{ data.exam_count }} {{ declOfNum(data.exam_count, ['осмотр', 'осмотра', 'осмотров']) }}
                span.text-color--blue(v-else) нет осмотров

            .col-2
              .d-flex.align-items-center
                span(v-if="data.devices_count") {{ data.devices_count }} {{ declOfNum(data.devices_count, ['устройство', 'устройства', 'устройств']) }}
                span.text-color--blue(v-else) нет устройств


      template(v-slot:body)
        .patient-card__content.pb-4
          .patient-card__detail
            .row.align-items-center.gx-4
              .col-auto
                app-signature(
                  :sample="data.signature"
                  :theme="'shadow'"
                  @openGallery="openGallery"
                  @change="changeSignature"
                )
              .col
                .patient-card__detail-content
                  .row.align-items-center
                    .col
                      .text-color--blue.mb-2 Водительское&nbsp;удостоверение
                      span(v-if="data.serial_number_driving_license") {{ data.serial_number_driving_license }} от {{ toLocalDate(data.driving_license_receiving) }}
                      span.text-color--blue(v-else) &#8212;

                    .col
                      .text-color--blue.mb-2 СНИЛС
                      span(v-if="data.snils") {{data.snils}}
                      span.text-color--blue(v-else) &#8212;
                    .col
                      .text-color--blue.mb-2 Дата рождения
                      span(v-if="data.date_birth") {{ toLocalDate(data.date_birth) }}
                      span.text-color--blue(v-else) &#8212;

                    .col
                      .text-color--blue.mb-2 Пол
                      span(v-if="data.gender") {{ data.gender == '1' ? 'Мужской' : 'Женский' }}
                      span.text-color--blue(v-else) &#8212;

                    .col
                      .text-color--blue.mb-2 Табельный&nbsp;номер
                      span(v-if="data.personal_number") {{ data.personal_number }}
                      span.text-color--blue(v-else) &#8212;

                    .col
                      .text-color--blue.mb-2 Карта RFID
                      span(v-if="data.rfid_id") {{ data.rfid_id }}
                      span.text-color--blue(v-else) &#8212;
                    .col
                      //.text-color--blue.mb-2 Сведения о состоянии здоровья
                      //span.text-color--blue &#8212;

                    .col-auto
                      .d-flex.flex-column.align-items-end
                        button(
                          type="button"
                          @click.prevent="changePatient(data)"
                          class=['text-color--blue-dark', 'text-decoration--underline', 'link--accent', 'mb-2']
                        ) Изменить

                        button(
                          type="button"
                          @click.prevent="deletePatient(data)"
                          class=['text-color--danger', 'text-decoration--underline', 'link--accent']
                        ) Удалить

          ul.patient-exams__list(v-if="sortedExams" :id="`patient-${data.code}`")
            li.patient-exams__list-el(v-for="exam in sortedExams.slice(0, pagination.offset)" :key="exam.exam_id")
              app-accordion(
                :id="`sub-accordion-${exam.exam_id}`"
                :parent-id="`patient-${data.code}`"
                class="patient-exams__accordion"
              )
                template(v-slot:header)
                  .patient-exams__card-header
                    .row.align-items-end.g-5.gy-xxl-0
                      .col-3
                        .d-flex.align-items-center
                          app-status(:type="'exam'" :data="exam" :caption="false")
                          .ms-4
                            .text-color--blue Серийный номер устройства
                            .mt-3 {{ exam.device_serial }}

                      .col-auto.col-xl-2
                        .text-color--blue.mb-3 Дата осмотра
                        span {{ toLocalDate(exam.exam_datetime) }}
                        span.ms-3 {{ toLocalTime(exam.exam_datetime) }}

                      .col-5
                        .text-color--blue.mb-3 Результаты осмотра
                        .row.align-items-center
                          .col
                            .d-flex.align-items-center
                              span.flex-shrink-0.me-2
                                app-svg-icon(name="pulse")
                              span(:class="bpHighColor(exam.bp_high)" v-if="exam.bp_high && exam.bp_low")  {{ exam.bp_high }}
                              span(:class="bpLowColor(exam.bp_low)" v-if="exam.bp_high && exam.bp_low") /{{ exam.bp_low }}
                              span.text-color--blue(v-else) &#8212;

                          .col
                            .d-flex.align-items-center
                              span.flex-shrink-0.me-2
                                app-svg-icon(name="cardio")
                              span(v-if="exam.heart_rate") {{ exam.heart_rate }}&nbsp;уд&#65279;/&#65279;мин
                              span.text-color--blue(v-else) &#8212;

                          .col
                            .d-flex.align-items-center
                              span.flex-shrink-0.me-2
                                app-svg-icon(name="temp")
                              span(v-if="exam.body_temp" :class="bpTemp(exam.body_temp)") {{ exam.body_temp }}&nbsp;c&#176;
                              span.text-color--blue(v-else) &#8212;

                          .col
                            .d-flex.align-items-center
                              span.flex-shrink-0.me-2
                                app-svg-icon(name="breathalyzer")
                              span(v-if="exam.alcohol || exam.alcohol === 0" :class="alcoholColor(exam.alcohol)") {{ exam.alcohol }}&nbsp;мг&#65279;/&#65279;л
                              span.text-color--blue(v-else) &#8212;

                          .col
                            app-tooltip(
                              v-if="exam.health_complaint"
                              :content="`<p class=\"mb-2\">Жалоба на состояние здоровья:</p><p class=\"text-color--black\">${exam.health_complaint}<p>`"
                              :icon="'comment'"
                            )


                      .col-2
                        .text-color--blue.mb-3 Допуск
                        app-status(
                          :data="exam"
                          :type="'exam-caption'"
                          :line="true"
                        )
                      .col-auto
                        .text-color--blue.mb-3
                        div.js-status.profile-status.flex-shrink-0.null-theme №{{exam.exam_number}}

                      .col-auto.ms-auto
                        .row.gx-4
                          .col-auto(v-if="exam.exam_video")
                            button(
                              type="button"
                              @click.prevent.stop="openModal('video', exam)"
                              class=['link--accent']
                            )
                              app-svg-icon(name="video")

                          .col-auto(v-if="exam.lat && exam.long")
                            button(
                              type="button"
                              @click.prevent.stop="openModal('map', exam)"
                              class=['link--accent']
                            )
                              app-svg-icon(name="map-point")


                template(v-slot:body)
                    li.patient-exams__card-content-el
                      .row.align-items-center.gy-3
                        .col-3
                          .air-params
                           .air-params__item(:vall="exam.area_temp+'C'")
                            app-tooltip(
                            :content="`<p class=\"mb-2\">Температура воздуха при осмотре<p>`"
                            :icon="'comment'"
                            )
                           .air-params__item.air-params__item--water(:vall="exam.area_humidity+'%'")
                            app-tooltip(
                            :content="`<p class=\"mb-2\">Влажность воздуха при осмотре<p>`"
                            :icon="'comment'"
                           )
                          .d-flex.align-items-center
                            app-signature(
                              v-if="data.signature"
                              :sample="data.signature"
                              :instance="exam.signature"
                              :theme="'shadow'"
                              :classes="'small'"
                              @openGallery="openGallery"
                            )
                        .col-2
                           div
                            .text-color--blue.mb-3 Тип осмотра
                            span(v-if="exam.exam_type_name") {{exam.exam_type_name}}
                            span(else) -
                        .col-3.col-xl-6
                          .text-color--blue.mb-3 Статус
                          .text-color--blue-dark(v-if="exam.exam_completed") Завершён
                          .text-color--blue(v-else) Не завершен
                        .col-2.col-xl-2
                          template(v-if="exam.number_kep")
                              .text-color--blue.mb-2 Медработник
                              router-link(
                                v-if="userRole === 'admin' && type !== 'doctor-detail'"
                                :to="{name: 'doctor-detail', params: {id: exam.doctor_id}}"
                                class="text-decoration--underline link--accent"
                              ) {{exam.exam_doctor_full_name}}

                              span.text-color--blue-dark.d-flex(v-else)  {{exam.exam_doctor_full_name}}
                               span.doctorSign(type="button" @click.prevent="openCertificate(exam)")
                        .col-14
                         hr.mt-0.mb-0
                        .col-3
                          div.ps-55
                              .text-color--blue.mb-3 Координаты
                              span(v-if="exam.lat && exam.long") {{ exam.lat }}, {{ exam.long }}
                              span.text-color--accent(v-else) Нет
                        .col-3.col-xl-2
                          .text-color--blue.mb-2 Контроль целостности ПО
                          span.text-color--blue-dark.green(v-if='exam.device_integrity === true') Пройден
                          span.text-color--blue-dark.yellow(v-else-if='exam.device_integrity === false') Не пройден
                          span.text-color--blue-dark.red(v-else) -
                        .col-2.col-xxl
                          .text-color--blue.mb-3 Поверка
                          span.green(v-if='exam.device_verified') Поверено
                          span.red(v-else) Не поверено
                        .col-2.col-xxl
                          .text-color--blue.mb-3 Сон
                          span.orange(v-if="exam.sleep_info=='no'") Не выспался
                          span(v-if="exam.sleep_info=='yes'") Выспался
                          span(v-if="!exam.sleep_info") -

                        .col-2.col-xxl-2
                          .text-color--blue.mb-3 Состояние корпуса
                          template(v-if="exam.case_opened")
                            .d-flex.align-items-center.text-color--danger
                              span.me-2 Вскрыт
                              span.d-flex
                                app-svg-icon(name="attention")
                          span(v-else) Не вскрыт
                        .col-3.col-xxl
                          .text-color--blue.mb-3 Синхронизация с устройством
                          span(v-if="exam.ntp_sync") Синхронизировано
                          span.text-color--accent(v-else) Не синхронизировано
          .px-4.mt-4
            button(
              v-if="pagination.offset < sortedExams.length"
              type="button"
              @click="pagination.offset += pagination.limit"
              class=['text-size--small link--accent', 'me-4']
            ) Показать еще (+{{ (sortedExams.length - pagination.offset) >= pagination.limit ? pagination.limit : sortedExams.length - pagination.offset}})

            button(
              v-if="pagination.offset > pagination.limit"
              type="button"
              @click="pagination.offset = pagination.limit"
              class=['text-size--small link--accent']
            ) Свернуть

</template>

<script>
import variables from '@/helpers/variables';
import {declOfNum, toLocalDate, toLocalTimeExam, sortBy} from '@/helpers/utils';
import is from 'is_js';

import AppSignature from '@/components/profile/common/Signature';
import AppAvatar from '@/components/profile/common/Avatar';
import AppAccordion from '@/components/common/Accordion';
import AppSvgIcon from '@/components/common/SvgIcon';
import AppStatus from '@/components/profile/common/Status';
import AppTooltip from '@/components/common/Tooltip';

export default {
  name: 'AppClientPatientCard',
  components: {
    AppAccordion,
    AppSvgIcon,
    AppAvatar,
    AppStatus,
    AppTooltip,
    AppSignature,
  },
  props: {
    data: {
      type: Object,
      required: true,
      default() {
        return null;
      },
    },
    classes: {
      type: String,
      required: false,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      is,
      v: variables,
      declOfNum,
      pagination: {
        limit: 10,
        offset: 10,
      },
      isBlocked: false,
      sortKey: 'exam_datetime',
    };
  },
  computed: {
    sortedExams() {
      return this.data.exams && this.sortKey
        ? sortBy(this.data.exams, this.sortKey)
        : this.data.exams;
    },
  },
  methods: {
    toLocalDate(date) {
      if (date) {
        return toLocalDate(date);
      } else return false;
    },
    toLocalTime(time) {
      if (time) {
        return toLocalTimeExam(time);
      } else return false;
    },
    openModal(slug, data) {
      this.$emit('openModal', slug, data);
    },
    openGallery(data) {
      this.$emit('openGallery', {gallery: data, patient: this.data});
    },
    openCertificate(data) {
      this.$emit('openCertificate', data);
    },
    changeAvatar() {
      this.$emit('changeAvatar', this.data);
    },
    changeSignature() {
      this.$emit('changeSignature', this.data);
    },
    changePatient(data) {
      this.$emit('change', data);
    },
    deletePatient(data) {
      this.$emit('delete', data);
    },
     bpHighColor(key){
        return {
            'yellowText':  (key>140 && key<160)|| (key>60 && key<100),
            'redText':  key>=160}
    },
    bpLowColor(key){
        return {
            'yellowText':  (key>=90 && key<=99)|| (key<60),
            'redText':  key>=100}
    },
    alcoholColor(key){
        return {
          'yellowText': (key>0 && key<=0.16),
          'redText': key>0.16
        }
    },
      bpTemp(key){
       return {
          'yellowText': (key>37 && key<=37.9),
          'redText': key>=38
        }
    }
  },
};
</script>

<style lang="scss">
.patient-card {
  &.is-blocked {
    pointer-events: none;

    button {
      pointer-events: auto;
    }
  }
  &__accordion {
    padding: 0;

    & > .accordion__header {
      padding: $spacer $spacer * 7 $spacer $spacer * 1.6;
      @include transition();

      @include mediaToDown($xxl) {
        padding-right: $spacer * 4;
      }
      &[aria-expanded='true'] {
        color: get($colors, black);

        .accordion {
          &__button {
            transform: translateY(-50%) rotate(180deg);
          }
        }
      }
      .icon {
        &__down-arrow {
          fill: get($colors, blue);
        }
      }
    }
  }
  &__detail {
    padding: $spacer $spacer * 2.5 $spacer $spacer * 1.6;

    &-content {
      color: get($colors, black);
      padding: $spacer $spacer * 1.6;
      background: get($gradients, blue-extra-light);
      border-radius: $border-radius-sm;
    }
  }
  .accordion {
    &__button {
      top: 50%;
      right: $spacer * 3;
      transform: translateY(-50%);

      @include mediaToDown($xxl) {
        right: $spacer * 1.6;
      }
    }
  }
  .icon {
    &__circle {
      width: $spacer;
      height: $spacer;
    }
    &__pulse,
    &__cardio,
    &__temp,
    &__breathalyzer {
      fill: get($colors, blue);
      width: $spacer * 2.2;
      height: $spacer * 2.2;
    }
    &__video {
      height: $spacer * 2;
      width: $spacer * 2.5;

      @include mediaToDown($xxl) {
        height: $spacer * 2;
        width: $spacer * 2;
      }
    }
    &__map-point {
      width: $spacer * 2;
      height: $spacer * 2;

      @include mediaToDown($xxl) {
        height: $spacer * 1.6;
        width: $spacer * 1.6;
      }
    }
    &__video,
    &__map-point {
      fill: get($colors, blue-dark);
    }
  }
}
.patient-exams {
  &__list {
  }
  &__accordion {
    & > .accordion__header {
      padding: $spacer $spacer * 7 $spacer $spacer * 1.6;

      @include mediaToDown($xxl) {
        padding-right: $spacer * 6;
      }
      &[aria-expanded='true'] {
        color: get($colors, black);

        .accordion {
          &__button {
            transform: translateY(-50%) rotate(180deg);
          }
        }
      }
      .icon {
        &__down-arrow {
          fill: get($colors, blue);
        }
      }
    }
    & > .accordion__collapse {
      &.show {
        color: get($colors, black);
      }
      .accordion__body {
        padding-bottom: $spacer;
        padding-left: $spacer * 3;
        padding-right: $spacer * 3;
      }
    }
  }
  &__card {
    &-content {
      background: get($gradients, blue-extra-light);
      border-radius: $border-radius-sm;
      padding: 0 $spacer * 1.3;

      &-el {
        padding: $spacer * 1.2 0;

        & + .patient-exams__card-content-el {
          margin-top: $spacer;
          border-top: $border-width solid get($colors, gray);
        }
      }
      @include mediaToDown($xxl) {
        padding: $spacer;
      }
    }
  }
}
.profileItem{
  .patient-card__header{
  .row>div:nth-child(2){
  display: none;
  }
   .row>div:nth-child(6){
   display: none;
  }
  }

}
.ps-55{
  padding-left: 55px;
}
</style>
