<template lang="pug">
  .profile-doctors
    .profile-doctors__header
      .row.align-items-center
        .col-14.col-md-7
          .d-flex.align-items-center
            app-title(:type="'large'") Просмотр медработников

            .d-md-none.ms-auto
              button(
                type="button"
                @click="openModal('createDoctor')"
                class=['button button--icon button--border-blue']
              )
                span.flex-shrink-0
                  app-svg-icon(name="plus-thin")

          //- .tabs-nav.separate-theme.mt-20
            .row.g-4
              .col.col-md-auto
                app-tab-nav(
                  :tabs="tabs"
                  :selected="selected"
                  @selected="changeActiveTab"
                )

        .col-14.col-md-7.d-none.d-md-block
          .d-flex.justify-content-end
            button(
              type="button"
              @click="openModal('createDoctor')"
              class=['button button--border-blue']
            )
              span.button__content
                span.d-flex.flex-shrink-0.me-3
                  app-svg-icon(name="plus-thin")
                span Добавить медработника


    .profile-doctors__content.position-relative.mt-25
        transition(name="translucent")
        app-loading(v-if="isLoading")
        app-error-message(v-if="error" :message="error")

        div(v-if="doctors")
          template(v-if="doctors && doctors.length")
            .profile-table
              .profile-table__header.d-none.d-xl-block
                .row.align-items-center.g-4
                  div(v-for="column in columns" :key="column.key" :class="column.class")
                    button(
                      type="button"
                      @click="sortingDoctors(column.key, column.inverse)"
                      class=['profile-table__sort-button']
                      :class="{'is-active': sortKey === column.key}"
                    )
                      span.d-flex.align-items-center
                        span.text-overflow(:title="column.caption") {{ column.caption }}
                        span.d-flex.ms-3
                          app-svg-icon(name="down-arrow")

              ul.profile-table__body
                li.profile-table__row(v-for="doctor in doctors" :key="doctor.id")
                  router-link(:to="{name: 'doctor-detail', params: {id: doctor.id}}" class=['d-block'])
                    app-admin-doctor-card(
                      :data="doctor"
                      :columns="columns"
                    )

            .d-flex.justify-content-center.mt-5
              app-pagination(
                :total="pagination.total"
                :limit="pagination.limit"
                :current-page="currentPage"
                :url="baseUrl"
              )

          template(v-else-if="!isLoading && !doctors.length")
            .text-color--blue.text-size--small Медработники отсутствуют


    // create doctor modal
    app-create-doctor-modal(
      :is-visible="isModalVisible.createDoctor"
      @close="closeModal('createDoctor')"
      @success="getDoctors(pagination.limit, 0)"
    )

</template>

<script>
import {mapState} from 'vuex';
import doctorsApi from '@/api/doctors';

import variables from '@/helpers/variables';

import AppTitle from '@/components/common/Title';
import AppSvgIcon from '@/components/common/SvgIcon';
import AppLoading from '@/components/common/Loading';
import AppErrorMessage from '@/components/common/ErrorMessage';
import AppModal from '@/components/common/Modal';
import AppValidationErrors from '@/components/common/ValidationErrors';
import AppPagination from '@/components/common/Pagination';

import AppCreateDoctorModal from '@/components/profile/admin/doctors/CreateDoctorModal';
import AppAdminDoctorCard from '@/components/profile/admin/doctors/AdminDoctorCard';

import AppTabNav from '@/components/common/TabNav.vue';

import {modalsMixin} from '@/mixins/modals';

export default {
  name: 'AppAdminDoctors',
  components: {
    AppTitle,
    AppSvgIcon,
    AppLoading,
    AppPagination,
    AppErrorMessage,
    AppValidationErrors,
    AppModal,
    AppAdminDoctorCard,
    AppCreateDoctorModal,
    AppTabNav,
  },
  mixins: [modalsMixin],
  data() {
    return {
      isLoading: false,
      doctors: {},
      sortKey: 'creation_date',
      sortInverse: false,
      v: variables,
      isModalVisible: {
        createDoctor: false,
      },
      pagination: {
        limit: 0,
        total: 0,
      },
      tabs: [
        {id: 'doctors', caption: 'Медработник'},
        {id: 'auto-doctor', caption: 'Автоматический медработник'},
      ],
      selected: 'doctors',
      columns: [
        {
          class: 'col-14 col-md-2',
          caption: 'Дата регистрации',
          key: 'creation_date',
          inverse: true,
        },
        {
          class: 'col-14 col-md-3',
          caption: 'ФИО медработника',
          key: 'full_name',
          inverse: true,
        },
        {
          class: 'col-14 col-md-2',
          caption: 'E-mail',
          key: 'email',
          inverse: true,
        },
        {
          class: 'col-14 col-md-3',
          caption: 'Название мед.организации',
          key: 'place_of_work',
          inverse: true,
        },
        {
          class: 'col-auto col-md-2',
          caption: 'Кол-во клиентов',
          key: 'clients_count',
          inverse: true,
        },
        {
          class: 'col-auto col-md-2 flex-grow-1',
          caption: 'Пользователей на осмотре',
          key: 'patients_count',
          inverse: true,
        },
      ],
    };
  },
  computed: {
    ...mapState({
      error: (state) => state.doctors.error,
    }),
    currentPage() {
      return Number(this.$route.query.page || '1');
    },
    baseUrl() {
      return this.$route.path;
    },
    offset() {
      return this.currentPage * this.pagination.limit - this.pagination.limit;
    },
  },
  watch: {
    currentPage() {
      this.getDoctors();
    },
  },
  async created() {
    this.getDoctors();
  },
  methods: {
    async getDoctors() {
      let sortInverse = '-';
      if (this.sortInverse) {
        sortInverse = '+';
      }
      let order_by = `${sortInverse}${this.sortKey}`;

      this.isLoading = true;
      let res = await doctorsApi.getDoctorsNew(this.currentPage - 1, order_by);
      this.pagination.total = res.data.total;
      this.pagination.limit = res.data.per_page;
      this.doctors = res.data.items;
      this.isLoading = false;
    },
    sortingDoctors(sortKey, inverse) {
      if (inverse === false) {
        return;
      } else {
        this.sortInverse = !this.sortInverse;
        this.sortKey = sortKey;
        this.getDoctors();
      }
    },

    //временно не актуально

    // changeActiveTab(tab) {
    //   switch (tab) {
    //     case 'doctors':
    //       this.$router.push({name: 'doctors'});
    //       break;
    //     case 'auto-doctor':
    //       this.$router.push({name: 'auto-doctor'});
    //       break;
    //   }
    // },
  },
};
</script>
