import is from 'is_js';
import {blockScroll} from '@/helpers/utils';

export const filterMixin = {
  data() {
    return {
      filters: {},
      isFiltered: false,
      isFilterOpen: false,
    };
  },
  computed: {
    isTouchDevice() {
      return is.touchDevice();
    },
  },
  methods: {
    getData() {
      this.filterOptions.methods.getData.forEach((method) => {
        this[method](
          this.filters,
          this.pagination ? this.pagination.limit : false,
          this.offset ? this.offset : 0
        );
      });
    },
    openFilter() {
      this.isFilterOpen = true;
      if (this.isTouchDevice) blockScroll('enable');
    },
    closeFilter(event) {
      this.isFilterOpen = false;
      if (this.isTouchDevice) blockScroll('disable');

      if (Object.keys(this.filters).length && !this.isFiltered && event !== 'submit')
        this.submitFilter(this.filters);
    },
    submitFilter(data) {
      this.filters = data;
      if (this.goToFirstPage) this.goToFirstPage();
      this.getData();
    },
    clearFilter() {
      this.filters = {};
      if (is.not.empty(this.$route.query)) this.$router.replace(this.$route.path);
      if (this.goToFirstPage) this.goToFirstPage();
      this.closeFilter();
      this.getData();
    },
    getCountFilter(data) {
      this.filters = data;
      this.isFiltered = false;
      this[this.filterOptions.methods.getCount](this.filters);
    },
  },
};
