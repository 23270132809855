import axios from '@/api/axios';

const getDoctors = (credentials) => axios.post('/api/v1/doctor/info', credentials);

const getDoctorsNew = (page, order_by) => axios.get(`/api/v2/doctor/?page=${page}&per_page=10&doctor_order_by=${order_by}`);

const getDoctorInfo = (doctor_id) => axios.get(`/api/v2/doctor/${doctor_id}/info`);

const getDoctorExams = (credentials) => axios.post('/api/v1/doctor/exams', credentials);

const getDoctorUsers = (doctor_id, page, order_by) => axios.get(`/api/v2/doctor/${doctor_id}/users?page=${page}&per_page=10&doctor_order_by=${order_by}`);
const getDoctorUsersNoFilter = (doctor_id) => axios.get(`/api/v2/doctor/${doctor_id}/users?page=0&per_page=10`);

const getDoctorExamsAdmin = (credentials) => axios.post('/api/v1/doctor/exams_by_admin', credentials);

const createDoctor = (credentials) => axios.post('/api/v1/doctor', credentials);

const changeDoctor = (credentials) => axios.post('/api/v1/doctor/edit', credentials);

const setAdmission = (credentials) => axios.post('/api/v1/doctor/set_admission_to_work', credentials);

const deleteDoctor = (id) => axios.delete(`/api/v1/doctor/delete/${id}`);

const getToken = () => axios.get('/api/v2/broker/token');

const getExamsCount = () => axios.get('/api/v2/broker/incoming_exams');

const setDoctorStatus = (credentials) => axios.post('/api/v2/doctor/work', credentials);

const doctorStatsEvent = (credentials) => axios.post('/api/v2/stats/event', credentials);

export default {
  getDoctors,
  getDoctorExams,
  getDoctorExamsAdmin,
  createDoctor,
  changeDoctor,
  setAdmission,
  deleteDoctor,
  getToken,
  getExamsCount,
  setDoctorStatus,
  getDoctorInfo,
  doctorStatsEvent,
  getDoctorsNew,
  getDoctorUsers,
  getDoctorUsersNoFilter
};
