<template lang="pug">
.profile-users
  .profile-exams__inner(:class="{'filter-is-open': isFilterOpen}")
    .profile-users__header
      .row.align-items-center
        .col-14.col-md-auto
          app-title(
            :type="'large'"
            :classes="'text-center mb-3 mb-md-0'"
          ) {{ getTitleByRole() }}

    .profile-users__content.mt-20.position-relative
      app-error-message(v-if="error" :message="error")
      transition(name="translucent")
        div(v-if="users")
          template(v-if="users && users.length")
            .profile-table
              .profile-table__header.d-none.d-xl-block
                .row.align-items-center.gx-4
                  div(v-for="column in columns" :key="column.key" :class="column.class")
                    button(
                      type="button"
                      @click="sortingUsers(column.key, column.inverse)"
                      class=['profile-table__sort-button']
                      :class="{'is-active': sortKey === column.key && column.key !== 'exam_count'}"
                    )
                      span.d-flex.align-items-center
                        span.text-overflow(v-if="column.caption") {{ column.caption }}
                        span(v-else-if="column.icon")
                          app-svg-icon(:name="column.icon")
                        span.d-flex.ms-3
                          app-svg-icon(name="down-arrow")

              ul.profile-table__body
                li.profile-table__row(v-for="user in users")
                  app-report-card(:data="user")

            .d-flex.justify-content-center.mt-5
              app-pagination(
                :total="pagination.total"
                :limit="pagination.limit"
                :current-page="currentPage"
                :url="baseUrl"
              )

          template(v-else)
            .text-color--blue.text-size--small Отчеты не найдены

</template>

<script>
import {mapState, mapGetters} from 'vuex';
import {gettersTypes as authGettersTypes} from '@/store/modules/auth';

import AppTitle from '@/components/common/Title';
import AppSvgIcon from '@/components/common/SvgIcon';
import AppLoading from '@/components/common/Loading';
import AppErrorMessage from '@/components/common/ErrorMessage';
import AppPagination from '@/components/common/Pagination';

import AppReportCard from '@/components/profile/admin/users/ReportCard';

import ReportsApi from '@/api/reports';

export default {
  name: 'AppAdminReport',
  components: {
    AppTitle,
    AppSvgIcon,
    AppLoading,
    AppErrorMessage,
    AppPagination,
    AppReportCard,
  },
  data() {
    return {
      pagination: {
        limit: 10,
        total: 0,
      },
      sortKey: 'creation_date',
      filterString: '',
      isFilterOpen: false,
      users: {},
      filterOptions: {
        users: true,
        userTypes: true,
        date: true,
        devices: true,
        methods: {
          getData: ['getUsers'],
          getCount: 'getCountUsers',
        },
      },

      sortInverse: false,
      columns: [
        {
          class: 'col-3',
          caption: 'Дата и время',
          key: 'creation_date',
          inverse: true,
        },
        {
          class: 'col-5',
          caption: 'Имя файла',
          key: 'user_type',
          inverse: true,
        },
        {
          class: 'col-3',
          caption: 'Тип документа',
          key: 'company_name',
          inverse: true,
        },
        {
          class: 'col-3',
          caption: 'Статус',
          key: 'email',
          inverse: true,
        },
      ],
    };
  },
  computed: {
    ...mapState({
      error: (state) => state.users.error,
    }),
    ...mapGetters({
      userRole: authGettersTypes.userRole,
    }),
    ...mapState({
      currentUser: (state) => state.auth.currentUser,
    }),
    currentPage() {
      return Number(this.$route.query.page || '1');
    },
    baseUrl() {
      return this.$route.path;
    },
    settings() {
      return {
        filters: {
          users: {
            negative: false,
            payload: [],
          },
        },
        sorting_params: {
          desc: this.sortInverse,
          field: this.sortKey,
        },
      };
    },
  },
  watch: {
    currentPage() {
      this.getUsers();
    },
  },
  created() {
    this.getUsers();
  },
  methods: {
    getTitleByRole() {
      return this.userRole === 'admin'
        ? 'Настройки системы. Медицинская документация'
        : 'Выгруженные документы';
    },

    goToFirstPage() {
      if (this.currentPage !== 1) this.$router.push({query: {page: '1'}});
    },
    async getUsers() {
      let sortInverse = '-';
      if (this.sortInverse) {
        sortInverse = '+';
      }

      let order_by = `${sortInverse}${this.sortKey}`;
      let resultFilter =
        `?per_page=10&page=${this.currentPage - 1}${this.filterString}` + `&order_by=${order_by}`;

      let res = await ReportsApi.getReports(resultFilter);
      this.users = res.data.items;
      this.pagination.total = res.data.total;
      this.pagination.limit = res.data.per_page;
    },
    getCountUsers(filters) {
      let data = {
        sorting_params: this.settings.sorting_params,
      };
      data.filters = Object.assign({}, this.settings.filters, filters);
    },
    sortingUsers(sortKey, inverse) {
      if (inverse === false) {
        return;
      } else {
        this.sortInverse = !this.sortInverse;
        this.sortKey = sortKey;
        this.getUsers();
      }
    },
    async submitFilter(data) {
      this.filterString = data;
      this.getUsers();
    },
    close() {
      this.isFilterOpen = false;
    },
    openFilter() {
      this.isFilterOpen = true;
    },
  },
};
</script>

<style lang="scss">
.profile-users {
  .profile-table {
    &__row {
      & + .profile-table__row {
        margin-top: $border-width * 2;
      }
      @include mediaToDown($xl) {
        & + .profile-table__row {
          margin-top: $spacer * 0.6;
        }
      }
    }
  }
}
</style>
