import axios from 'axios';

export default {
    state: {
        firmware: [],
    },
    mutations: {
        firmware(state, payload) {
            state.firmware = payload.firmware;
        },
    },

    actions: {
        async actionGetFirmware({ commit }) {
            //Data for table
            try {
                var res = await axios({
                    url: '/api/v1/device/integrity_controls',
                });
                commit('firmware', {
                    firmware: res.data,
                });
            } catch (error) {
                alert(error)
            }
            return res;
        },
        async actionNewFirmware(_, data) {
            try {
                var resw = await axios({
                    url: '/api/v1/device/new_integrity_control',
                    method: 'POST',
                    data: data,
                });
            } catch (error) {
                alert(error)
            }
            return resw;
        },
        async actionEditFirmware(_, data) {
            try {
                var resw = await axios({
                    url: '/api/v1/device/update_integrity_control',
                    method: 'PUT',
                    data: data,
                });
            } catch (error) {
                alert(error)
            }
            return resw;
        },
        async actionDeleteFirmware(_, data) {
            try {
                var resw = await axios({
                    url: '/api/v1/device/delete_integrity_control',
                    method: 'POST',
                    data: data,
                });
            } catch (error) {
                alert(error)
            }
            return resw;
        },
        async actionUploadPdn(_, data) {
            try {
                var resw = await axios({
                    url: '/api/v1/user_agreements/create',
                    method: 'POST',
                    data: data,
                });
            } catch (error) {
                alert(error)
            }
            return resw;
        },
    },



    getters: {

        heartRate(state) {
            return state.chart.result.map((item) => item.heart_rate);
        },


    },
};
