<template lang="pug">
  transition(name="fade" mode="out-in")
    app-modal(v-if="isVisible" @close="closeModal" classes="small")
      template(v-slot:body)
        app-title(:classes="'mb-4'") Смена e-mail
        app-validation-errors(
          v-if='error && showError'
          :validation-errors='error'
          :classes="'mb-4'"
        )
        form(@submit.prevent="onSubmit" :class="['form', {'is-submitting': isLoading}]")
          .row.flex-column.align-items-center.g-3.g-md-4
            .col
              label.form__label
                input(
                  class="form__field"
                  type="email"
                  v-model="email"
                  readonly
                )

            .col
              label.form__label
                input(
                  :class="['form__field', { 'is-error': v$.newEmail.$errors.length }]"
                  type="email"
                  v-model="v$.newEmail.$model"
                  placeholder="Новый E-mail"
                )
                ul.form__error
                  li(v-for="error of v$.newEmail.$errors" :key="error.$uid")
                    template(v-if="error.$validator === 'email'") {{ v.errors.email }}
                    template(v-if="error.$validator === 'required'") {{ v.errors.required }}
                    template(v-if="error.$validator === 'noMatches'") {{ v.errors.noMatches.email }}

            .col.mt-5
              button(
                type="submit"
                :disabled="v$.$invalid"
                class=['button', 'button--accent', 'form__button']
              )
                span.button__content Подтвердить смену e-mail

            .col-auto
              p.text-center.text-color--blue.text-size--small Для смены e-mail необходимо пройти по ссылке с нового электронного адреса

</template>

<script>
import useVuelidate from '@vuelidate/core';
import {required, email} from '@vuelidate/validators';

import variables from '@/helpers/variables';

import AppValidationErrors from '@/components/common/ValidationErrors';
import AppTitle from '@/components/common/Title';
import AppModal from '@/components/common/Modal';

export default {
  name: 'AppChangeEmailModal',
  components: {
    AppModal,
    AppTitle,
    AppValidationErrors,
  },
  props: {
    isVisible: {
      required: true,
      type: Boolean,
      default: false,
    },
    initialValues: {
      type: String,
      required: true,
      default() {
        return null;
      },
    },
    error: {
      type: String,
      required: false,
      default: null,
    },
    isLoading: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      v: variables,
      email: this.initialValues,
      newEmail: '',
      slug: 'changeEmail',
      showError: false,
    };
  },
  validations() {
    return {
      newEmail: {
        required,
        email,
        noMatches: function (value) {
          return this.email !== value;
        },
      },
    };
  },
  methods: {
    onSubmit() {
      const form = {email: this.newEmail};
      this.$emit('submit', {slug: this.slug, form});
      this.showError = true;
    },
    resetForm() {
      this.v$.$reset();
      this.email = this.initialValues;
      this.newEmail = '';
      this.showError = false;
    },
    closeModal() {
      this.resetForm();
      this.$emit('close', this.slug);
    },
  },
};
</script>

<style scoped></style>
