<template lang="pug">
  .profile-dashboard
    component(:is="currentComponent")
</template>

<script>
import AppClientDashboard from '@/components/profile/client/ClientDashboard';
import AppAdminDashboard from '@/components/profile/admin/AdminDashboard';
import Exam from '@/components/profile/Exam';

export default {
  name: 'AppDashboard',
  data() {
    return {
      roleMapping: {
        client: AppClientDashboard,
        admin: AppAdminDashboard,
        doctor: Exam,
      },
    };
  },
  computed: {
    currentComponent() {
      return this.roleMapping[this.$store.state.auth.userRole];
    },
  },
};
</script>
