import axios from '@/api/axios';

const getRequests = () => axios.get('/api/v1/requests');

const createDeviceRequest = (credentials) => axios.post('/api/v1/requests/device', credentials);

const createUpdateDeviceRequest = (credentials) => axios.post('/api/v1/requests/update_device', credentials);

const changeUpdateDeviceRequest = (credentials) => axios.post(`/api/v1/requests/update_device/${credentials.id}`, credentials.body);

const changeStatusDeviceRequest = (credentials) =>
  axios.post(`/api/v1/requests/device/${credentials.id}`, credentials.body);

const changeStatusRegRequest = (credentials) =>
  axios.post(`/api/v1/auth/regRequest/${credentials.id}`, credentials.body);

const approveRequest = (credential) =>
  axios.post(`/api/v1/requests/approveCode/${credential.code}`, credential.body);

export default {
  getRequests,
  createDeviceRequest,
  changeStatusRegRequest,
  changeStatusDeviceRequest,
  approveRequest,
  createUpdateDeviceRequest,
  changeUpdateDeviceRequest
};
