<template lang="pug">
  div(
    :class="classes"
    class=['error-message', 'd-flex align-items-center', 'text-color--danger text-size--small text-family--italic']
  )
    span.d-flex.me-3
      app-svg-icon(name="wrong")
    span {{ message }}

</template>

<script>
import AppSvgIcon from '@/components/common/SvgIcon';

export default {
  name: 'AppErrorMessage',
  components: {
    AppSvgIcon,
  },
  props: {
    message: {
      type: String,
      required: false,
      default: 'Something went wrong...',
    },
    classes: {
      type: String,
      required: false,
      default: null,
    },
  },
};
</script>
