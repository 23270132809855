<template lang="pug">
  .profile-mobile-tab-bar.d-xl-none
    component(:is="currentComponent")
</template>

<script>
import AppClientMobileTabBar from '@/components/profile/client/ClientMobileTabBar';
import AppAdminMobileTabBar from '@/components/profile/admin/AdminMobileTabBar';

export default {
  name: 'AppMobileTabBar',
  data() {
    return {
      roleMapping: {
        client: AppClientMobileTabBar,
        admin: AppAdminMobileTabBar,
      },
    };
  },
  computed: {
    currentComponent() {
      return this.roleMapping[this.$store.state.auth.userRole];
    },
  },
};
</script>

<style lang="scss">
.profile-mobile-tab-bar {
  position: fixed;
  z-index: get($index, xl);
  width: 100%;
  height: $mobile-tab-bar-height;
  bottom: 0;
  left: 0;
  background-color: get($colors, white);
  box-shadow: get($shadows, default);
  border: $border-width solid get($colors, blue-light);

  .is-modal-open & {
    opacity: 0;
    transition: opacity #{$transition-duration}s;
  }
  &__content {
    width: 100%;
    height: 100%;
    position: relative;
    padding: 0 $spacer * 1.6 0 $spacer * 10;
  }
  &__logo {
    @include transition();
    position: absolute;
    z-index: get($index, default);
    left: 0;
    bottom: -$spacer * 0.4;
    width: $mobile-tab-bar-height * 2;
    height: $mobile-tab-bar-height * 2;
    border-radius: 50%;
    background-color: get($colors, white);
    box-shadow: get($shadows, extra-small);

    &.is-active {
      border-color: transparent;
      box-shadow: get($shadows, small), get($shadows, green-line);
    }
  }
  &__menu {
    width: 100%;

    &-link, &-button {
      position: relative;

      &:before {
        @include pseudo-wrapper();
        @include transition();
        top: 50%;
        left: 50%;
        width: $mobile-tab-bar-height * 1.2;
        height: $mobile-tab-bar-height * 1.2;
        transform: translate3d(-50%, -50%, 0);
        border-radius: 50%;
        background-color: get($colors, white);
        box-shadow: get($shadows, small), get($shadows, green-line);
        opacity: 0;
        z-index: get($index, negative);
      }
      svg {
        @include transition();
        width: $spacer * 2.4;
        height: $spacer * 2.4;
        fill: get($colors, gray-hover);

        rect {
          @include transition();
          stroke: get($colors, gray-hover);
        }
      }
      &.is-active:not(.is-open) {
        &:before {
          opacity: 1;
        }
        svg {
          fill: get($colors, blue-dark);

          rect {
            stroke: get($colors, blue-dark);
          }
        }
      }
    }
    &-button {
      z-index: get($index, xs);

      &.is-open {
        svg {
          fill: get($colors, gray);
        }
      }
    }
    &-notify {
      position: absolute;
      z-index: get($index, default);
      top: 0;
      right: 0;
      width: $spacer * 0.8;
      height: $spacer * 0.8;
      background: get($gradients, orange);
      border-radius: 50%;
    }
    &-hidden {
      position: absolute;
      z-index: get($index, default);
      right: 0;
      bottom: 0;
      width: calc(100% - #{$spacer * 10});
      padding: $spacer * 1.6 $spacer $mobile-tab-bar-height $spacer;
      background-color: get($colors, white);
      box-shadow: get($shadows, small), get($shadows, green-line);
      border-radius: $border-radius-lg 0 0 0;

      &-item {
        padding: $spacer * 0.6 0;

        a {
          &.is-active {
            color: get($colors, orange-hover);
          }
        }
      }
    }
  }
}
</style>
