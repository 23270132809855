import notificationsApi from '@/api/notifications';

const state = {
  data: null,
  isLoading: false,
  error: null,
};

export const mutationTypes = {
  getNotificationsStart: '[notifications] get data start',
  getNotificationsSuccess: '[notifications] get data success',
  getNotificationsFailure: '[notifications] get data failure',

  deleteNotificationStart: '[notifications] delete notification start',
  deleteNotificationSuccess: '[notifications] delete notification success',
  deleteNotificationFailure: '[notifications] delete notification failure',
};

export const actionTypes = {
  getNotifications: '[notifications] get data',
  deleteNotification: '[notifications] delete notification',
};

export const gettersTypes = {};

const getters = {};

const mutations = {
  [mutationTypes.getNotificationsStart](state) {
    state.isLoading = true;
    state.data = null;
    state.error = null;
  },
  [mutationTypes.getNotificationsSuccess](state, payload) {
    state.isLoading = false;
    state.data = payload;
  },
  [mutationTypes.getNotificationsFailure](state, payload) {
    state.isLoading = false;
    state.error = payload;
  },

  [mutationTypes.deleteNotificationStart](state) {
    state.isLoading = true;
    state.error = null;
  },
  [mutationTypes.deleteNotificationSuccess](state) {
    state.isLoading = false;
  },
  [mutationTypes.deleteNotificationFailure](state, payload) {
    state.isLoading = false;
    state.error = payload;
  },
};

const actions = {
  [actionTypes.getNotifications](context) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getNotificationsStart);
      notificationsApi
        .getNotifications()
        .then((response) => {
          context.commit(mutationTypes.getNotificationsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getNotificationsFailure, result.response.data.message);
          reject(result.response.data.message);
        });
    });
  },
  [actionTypes.deleteNotification](context, {id}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.deleteNotificationStart);
      notificationsApi
        .deleteNotification(id)
        .then(() => {
          context.commit(mutationTypes.deleteNotificationSuccess);
          resolve();
        })
        .catch((result) => {
          context.commit(mutationTypes.deleteNotificationFailure, result.response.data.message);
          reject(result.response.data.message);
        });
    });
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
