<template lang="pug">
  transition(name="fade" mode="out-in")
    app-modal(v-if="isVisible" @close="closeModal" classes="small")
      template(v-slot:body)
        app-title(:type="'large'" :classes="'text-center mb-4 mb-md-5'") Смена данных клиента
        app-validation-errors(
          v-if='error && showError'
          :validation-errors='error'
          :classes="'mb-4'"
        )
        .mb-5
          app-tab-nav(
            :tabs="tabs"
            :selected="activeTab"
            :theme="'separate'"
            @selected="setActiveTab"
          )
        app-tab(:is-selected="activeTab === 'names'")
          form(@submit.prevent="onSubmit('changeNames')" :class="['form', {'is-submitting': isLoading}]")
            .row.flex-column.align-items-center.g-2.g-md-4
              template(v-if="isPerson")
                .col
                  label.form__label
                    input(
                      :class="['form__field', { 'is-error': v$.firstName.$errors.length }]"
                      type="text"
                      v-model="v$.firstName.$model"
                      placeholder="Имя"
                    )
                  ul.form__error
                    li(v-for="error of v$.firstName.$errors" :key="error.$uid")
                      template(v-if="error.$validator === 'required'") {{ v.errors.required }}
                      template(v-if="error.$validator === 'noMatches'") {{ v.errors.noMatches.names }}

                .col
                  label.form__label
                    input(
                      :class="['form__field', { 'is-error': v$.lastName.$errors.length }]"
                      type="text"
                      v-model="v$.lastName.$model"
                      placeholder="Фамилия"
                    )
                  ul.form__error
                    li(v-for="error of v$.lastName.$errors" :key="error.$uid")
                      template(v-if="error.$validator === 'required'") {{ v.errors.required }}
                      template(v-if="error.$validator === 'noMatches'") {{ v.errors.noMatches.names }}

                .col
                  label.form__label
                    input(
                      :class="['form__field', { 'is-error': v$.middleName.$errors.length }]"
                      type="text"
                      v-model="v$.middleName.$model"
                      placeholder="Отчество"
                    )
                  ul.form__error
                    li(v-for="error of v$.middleName.$errors" :key="error.$uid")
                      template(v-if="error.$validator === 'noMatches'") {{ v.errors.noMatches.names }}

              template(v-if="isCompany")
                .col
                  label.form__label
                    input(
                      :class="['form__field', { 'is-error': v$.companyName.$errors.length }]"
                      type="text"
                      v-model="v$.companyName.$model"
                      placeholder="Полное наименование юридического лица"
                    )
                  ul.form__error
                    li(v-for="error of v$.companyName.$errors" :key="error.$uid")
                      template(v-if="error.$validator === 'required'") {{ v.errors.required }}
                      template(v-if="error.$validator === 'noMatches'") {{ v.errors.noMatches.names }}

              .col.mt-5
                button(
                  type="submit"
                  :disabled="v$.$invalid"
                  class=['button', 'button--accent', 'form__button']
                )
                  span.button__content Подтвердить смену {{ isPerson ? 'Ф.И.О.' : 'наименования' }}

              .col-auto
                p.text-center.text-color--blue.text-size--small Для смены {{ isPerson ? 'Ф.И.О.' : 'наименования' }} введите новые данные, клиент будет уведомлен об изменениях


        app-tab(:is-selected="activeTab === 'email'")
          form(@submit.prevent="onSubmit('changeEmail')" :class="['form', {'is-submitting': isLoading}]")
            .row.flex-column.align-items-center.g-4
              .col
                label.form__label
                  input(
                    class="form__field"
                    type="email"
                    v-model="user.email"
                    placeholder='Старый e-mail не обнаружен'
                    readonly
                  )

              .col
                label.form__label
                  input(
                    :class="['form__field', { 'is-error': v$.newEmail.$errors.length }]"
                    type="email"
                    v-model="v$.newEmail.$model"
                    placeholder="Новый E-mail"
                  )
                ul.form__error
                  li(v-for="error of v$.newEmail.$errors" :key="error.$uid")
                    template(v-if="error.$validator === 'email'") {{ v.errors.email }}
                    template(v-if="error.$validator === 'required'") {{ v.errors.required }}
                    template(v-if="error.$validator === 'noMatches'") {{ v.errors.noMatches.email }}

              .col.mt-5
                button(
                  type="submit"
                  :disabled="v$.$invalid"
                  class=['button', 'button--accent', 'form__button']
                )
                  span.button__content Подтвердить смену e-mail

              .col-auto
                p.text-center.text-color--blue.text-size--small Для смены e-mail введите новые данные, клиент будет уведомлен об изменениях


</template>

<script>
import useVuelidate from '@vuelidate/core';
import {email, required} from '@vuelidate/validators';

import is from 'is_js';
import variables from '@/helpers/variables';

import AppValidationErrors from '@/components/common/ValidationErrors';
import AppTitle from '@/components/common/Title';
import AppModal from '@/components/common/Modal';
import AppTabNav from '@/components/common/TabNav.vue';
import AppTab from '@/components/common/Tab.vue';

export default {
  name: 'AppAdminChangeUserModal',
  components: {
    AppModal,
    AppTitle,
    AppTabNav,
    AppTab,
    AppValidationErrors,
  },
  props: {
    isVisible: {
      required: true,
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      required: true,
      default() {
        return null;
      },
    },
    error: {
      type: String,
      required: false,
      default: null,
    },
    isLoading: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      v: variables,
      companyName: this.user.company_name,
      firstName: this.user.first_name,
      middleName: this.user.middle_name,
      lastName: this.user.last_name,
      email: this.user.email,
      newEmail: '',
      slug: 'changeUser',
      showError: false,
      activeTab: 'names',
    };
  },
  validations() {
    return {
      companyName: {
        noMatches: function (value) {
          return this.isCompany && this.activeTab === 'names'
            ? this.user.company_name !== value
            : true;
        },
        required: this.isCompany && this.activeTab === 'names' ? required : false,
      },
      firstName: {
        noMatches: function (value) {
          return this.isPerson && this.activeTab === 'names'
            ? this.user.first_name !== value ||
                this.user.middle_name !== this.middleName ||
                this.user.last_name !== this.lastName
            : true;
        },
        required: this.isPerson && this.activeTab === 'names' ? required : false,
      },
      middleName: {
        noMatches: function (value) {
          return this.isPerson && this.activeTab === 'names'
            ? this.user.first_name !== this.firstName ||
                this.user.middle_name !== value ||
                this.user.last_name !== this.lastName
            : true;
        },
        required: this.isPerson && this.activeTab === 'names' ? required : false,
      },
      lastName: {
        noMatches: function (value) {
          if (is.not.empty(this.user.last_name))
            return this.isPerson && this.activeTab === 'names'
              ? this.user.first_name !== this.firstName ||
                  this.user.middle_name !== this.middleName ||
                  this.user.last_name !== value
              : true;
          else return true;
        },
      },
      newEmail: {
        required: this.activeTab === 'email' ? required : false,
        email,
        noMatches: function (value) {
          return this.activeTab === 'email' ? this.email !== value : true;
        },
      },
    };
  },
  computed: {
    isPerson() {
      return this.user.user_type === this.v.user.types.person;
    },
    isCompany() {
      return this.user.user_type === this.v.user.types.company;
    },
    tabs() {
      return [
        {
          id: 'names',
          caption: this.isPerson ? 'Смена Ф.И.О. клиента' : 'Смена наименования',
        },
        {
          id: 'email',
          caption: 'Смена e-mail клиента',
        },
      ];
    },
  },
  methods: {
    setActiveTab(tab) {
      console.log(this.user);
      this.resetForm();
      this.activeTab = tab;
    },
    onSubmit(slug) {
      let form = {};

      if (slug === 'changeNames') {
        form = {
          company_name: this.companyName,
          first_name: this.firstName,
          middle_name: this.middleName,
          last_name: this.lastName,
        };
      } else if (slug === 'changeEmail') {
        form = {email: this.newEmail};
      }

      this.$emit('submit', {slug, form});
      this.showError = true;
    },
    resetForm() {
      this.v$.$reset();

      this.companyName = this.user.company_name;
      this.firstName = this.user.first_name;
      this.middleName = this.user.middle_name;
      this.lastName = this.user.last_name;
      this.newEmail = '';

      this.showError = false;
    },
    closeModal() {
      this.resetForm();
      this.$emit('close', this.slug);
    },
  },
};
</script>

<style scoped lang="scss">
.modal {
  .form {
    &__field {
      color: get($colors, blue-dark);
    }
  }
  .tabs-nav {
    justify-content: space-between;

    &::v-deep .tabs-nav__item {
      width: 49%;
      margin-right: 0;
    }
    &::v-deep .tabs-nav__button {
      width: 100%;
      padding: $spacer * 0.4;
    }
  }
}
</style>
