import authApi from '@/api/auth';
import variables from '@/helpers/variables';

const state = {
  isLoading: false,
  isSubmitting: false,
  currentUser: null,
  validationErrors: null,
  isLoggedIn: null,
  userRole: null,
  regStatus: null,
};

export const mutationTypes = {
  registerStart: '[auth] register start',
  registerSuccess: '[auth] register success',
  registerFailure: '[auth] register failure',

  loginStart: '[auth] login start',
  loginSuccess: '[auth] login success',
  loginFailure: '[auth] login failure',

  logoutStart: '[auth] logout start',
  logoutSuccess: '[auth] logout success',
  logoutFailure: '[auth] logout failure',

  getCurrentUserStart: '[auth] get current user start',
  getCurrentUserSuccess: '[auth] get current user success',
  getCurrentUserFailure: '[auth] get current user failure',

  regStatusStart: '[auth] register status start',
  regStatusSuccess: '[auth] register status success',
  regStatusFailure: '[auth] register status failure',
};

export const actionTypes = {
  register: '[auth] register',
  login: '[auth] login',
  logout: '[auth] logout',
  getCurrentUser: '[auth] get current user',
  getRegStatus: '[auth] get register status',
};

export const gettersTypes = {
  currentUser: '[auth] currentUser',
  isLoggedIn: '[auth] isLoggedIn',
  isAnonymous: '[auth] isAnonymous',
  regStatus: '[auth] regStatus',
  userRole: '[auth] userRole',
};

const getters = {
  [gettersTypes.currentUser]: (state) => {
    return state.currentUser;
  },
  [gettersTypes.userRole]: (state) => {
    if (state.currentUser) {
      switch (state.currentUser.role) {
        case variables.user.roles.admin:
          state.userRole = 'admin';
          break;

        case variables.user.roles.client:
          state.userRole = 'client';
          break;

        case variables.user.roles.doctor:
          state.userRole = 'doctor';
          break;

        default:
          state.userRole = 'client';
      }
    }

    return state.userRole;
  },
  [gettersTypes.isLoggedIn]: (state) => {
    return Boolean(state.isLoggedIn);
  },
  [gettersTypes.isAnonymous]: (state) => {
    return state.isLoggedIn === false;
  },
  [gettersTypes.regStatus]: (state) => {
    return state.regStatus;
  },
};

const mutations = {
  [mutationTypes.registerStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.registerSuccess](state, payload) {
    state.isSubmitting = false;
    state.currentUser = payload;
  },
  [mutationTypes.registerFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.regStatusStart](state) {
    state.isLoading = true;
    state.regStatus = false;
    state.validationErrors = null;
  },
  [mutationTypes.regStatusSuccess](state, payload) {
    state.isLoading = false;
    state.regStatus = payload;
  },
  [mutationTypes.regStatusFailure](state, payload) {
    state.isLoading = false;
    state.regStatus = null;
    state.validationErrors = payload;
  },

  [mutationTypes.loginStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.loginSuccess](state, payload) {
    state.isSubmitting = false;
    state.currentUser = payload;
    state.isLoggedIn = true;
  },
  [mutationTypes.loginFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.logoutStart](state) {
    state.isSubmitting = true;
  },
  [mutationTypes.logoutSuccess](state) {
    state.isSubmitting = false;
    state.currentUser = null;
    state.isLoggedIn = false;
  },
  [mutationTypes.logoutFailure](state) {
    state.isSubmitting = false;
  },

  [mutationTypes.getCurrentUserStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.getCurrentUserSuccess](state, payload) {
    state.isLoading = false;
    state.currentUser = payload;
    state.isLoggedIn = true;
  },
  [mutationTypes.getCurrentUserFailure](state) {
    state.isLoading = false;
    state.isLoggedIn = false;
    state.currentUser = null;
    state.userRole = null;
  },
};

const actions = {
  [actionTypes.register](context, credentials) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.registerStart);
      authApi
        .register(credentials)
        .then((response) => {
          context.commit(mutationTypes.registerSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.registerFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionTypes.login](context, credentials) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.loginStart);
      authApi
        .login(credentials)
        .then((response) => {
          context.commit(mutationTypes.loginSuccess, response.data);
          // setItem('accessToken', response.data.token);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.loginFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionTypes.logout](context) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.logoutStart);
      authApi
        .logout()
        .then((response) => {
          context.commit(mutationTypes.logoutSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.logoutFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionTypes.getCurrentUser](context) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getCurrentUserStart);
      authApi
        .getCurrentUser()
        .then((response) => {
          context.commit(mutationTypes.getCurrentUserSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getCurrentUserFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionTypes.getRegStatus](context, credentials) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.regStatusStart);
      authApi
        .regStatus(credentials)
        .then((response) => {
          context.commit(mutationTypes.regStatusSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.regStatusFailure, result.response.data.message);
          reject(result);
        });
    });
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
