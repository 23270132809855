<template lang="pug">
  .profile-map
    .profile-map__inner.is-filterable(:class="{'filter-is-open': isFilterOpen}")
      .profile-map__header
        .row.align-items-center.flex-md-row.g-4
          .col-14  
           .profile__title.d-flex.align-items-center.justify-content-center.justify-content-md-start.position-relative(id="isAnchorForBtn")
            //app-back-button(:classes="'me-5'")
            app-title(
                :type="'large'"
                :classes="'text-center'"
              ) {{ isTouchDevice ? 'Отчеты' : 'Отчеты' }}
           nav-chart
           .chart-heder Графики измерений выбранного пользователя за период
           .legend.mt-md-5.d-none.d-md-flex(v-if="$store.state.chart.chart.total>0")  
            .legend-item.legend-item--violet.ms-3(v-if="$store.state.chart.hide_bp") Артериальное давление Верхнее 
            .legend-item.legend-item--blue.ms-3(v-if="$store.state.chart.hide_bp") Артериальное давление Нижнее
            .legend-item.legend-item--red.ms-3(v-if="$store.state.chart.hide_rate") Пульс
            .legend-item.legend-item--yellow.ms-3(v-if="$store.state.chart.hide_alcohol") Алкоголь
            .legend-item.legend-item--green.ms-3(v-if="$store.state.chart.hide_temp") Температура
          .col-14 
           app-loading(v-if="$store.state.chart.loading")
           .charts(v-if="$store.state.chart.chart.total>0")  
            .d-block(v-if="$store.state.chart.hide_bp") 
              line-chart-pressure
            .d-block.mt-5.pt-2(v-if="$store.state.chart.hide_rate")
              line-chart-rate
            .d-block.mt-5.pt-2(v-if="$store.state.chart.hide_temp") 
              line-chart-temp
            .d-block.mt-5.pt-2(v-if="$store.state.chart.hide_alcohol")  
              line-chart-alcohol
           .charts-empty(v-else) {{$store.state.chart.chartResultMessage}} 
          .col-14
           .legend.mt-5.d-block.d-md-none(v-if="$store.state.chart.chart.total>0")  
            .legend-item.legend-item--violet(v-if="$store.state.chart.hide_bp") Артериальное давление Верхнее 
            .legend-item.legend-item--blue(v-if="$store.state.chart.hide_bp") Артериальное давление Нижнее
            .legend-item.legend-item--red(v-if="$store.state.chart.hide_rate") Пульс
            .legend-item.legend-item--yellow(v-if="$store.state.chart.hide_alcohol") Алкоголь
            .legend-item.legend-item--green(v-if="$store.state.chart.hide_temp") Температура
           
    // filter
    app-filter(
      ref="filter"
      :options="filterOptions"
      :is-open="isFilterOpen"
      :is-filtered="isFiltered"
      :count="examsCount"
      @getCount="getCountFilter"
      @open="openFilter"
      @close="closeFilter"
      @clear="clearFilter"
      @submit="submitFilter"
    )
</template>
<script>
import {mapState} from 'vuex';


import is from 'is_js';
import variables from '@/helpers/variables';

import AppBackButton from '@/components/common/BackButton';
import AppTitle from '@/components/common/Title';
import AppSvgIcon from '@/components/common/SvgIcon';
import AppLoading from '@/components/common/Loading';
import AppErrorMessage from '@/components/common/ErrorMessage';

import AppFilter from '@/components/profile/common/FilterChart';
import {filterMixin} from '@/mixins/filter';
import LineChartAlcohol from '@/components/common/LineChartAlcohol';
import LineChartRate from '@/components/common/LineChartRate';
import LineChartTemp from '@/components/common/LineChartTemp';
import LineChartPressure from '@/components/common/LineChartPressure';
import NavChart from '@/components/profile/common/NavChart';
export default {
  name: 'AppMapExams',
  components: {
    AppBackButton,
    AppTitle,
    AppSvgIcon,
    AppLoading,
    AppErrorMessage,
    AppFilter,
    LineChartAlcohol,
    LineChartRate,
    LineChartTemp,
    LineChartPressure,
    NavChart
  },
  mixins: [ filterMixin],

  data() {
    return {
      t:{},
      v: variables,

      filterOptions: {
        patients: true,
        date: true,
        time: true,
        devices: true,
        methods: {
          getData: ['getExams'],
          getCount: 'getCountExams',
        }
      },


    };
  },

  computed: {
    ...mapState({
    //  examsLoading: (state) => state.exams.isLoading,
    
    }),
    isTouchDevice() {
      return is.touchDevice();
    },

  },

  mounted() {
 
  },
  methods: {

  //  upd(){
  //    this.t.update();
  //    console.log('upd',this.chartData)
  //  }
  },
};
</script>
<style lang="scss" scoped>
  .d-block{
    position: relative;
   // overflow: hidden;
   //height: fit-content;
   
  }
  .legend{
    display: flex;
    max-width: 700px;
    //justify-content: space-between;
  }
  .legend-item{
    font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 18px;
position: relative;
padding-left: 20px;
color:#5B6483;
 @media (max-width: 768px){
   padding-bottom: 10px;
 }
&:before{
  content: '';
  width: 10px;
  height: 10px;
  background: red;
position: absolute;
left: 0;
border-radius: 50%;
top:5px;


}
    &--violet{
      &:before{
  content: '';
  background: #90AAED;
}
    }
     &--blue{
             &:before{
  content: '';
   background: #96E6FF;
}
    
    }
     &--red{
                    &:before{
  content: '';
 background: #E37FAF;
}
     
    }
     &--yellow{
                          &:before{
  content: '';
background: #F5C261;
}
    }
     &--green{
                          &:before{
  content: '';
 background: #5BCB88;
}
    }
  }
  .charts-empty{
   background: #FFFFFF;
border-radius: 6px;
max-width: 770px;
padding-top: 25px;
padding-bottom: 25px;
text-align: center;
  }
  .chart-heder{
    font-weight: 400;
font-size: 14px;
line-height: 18px;
color:#5B6483;
padding-top: 30px;
padding-bottom: 10px;
letter-spacing: 0.06em;
text-transform: uppercase;
@media (max-width: 768px){
  padding-top: 0;
}
  }
</style>
