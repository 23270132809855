import autoDoctorApi from '@/api/autoDoctor';

const state = {
  data: null,
  isLoading: false,
  isSubmitting: false,
  validationErrors: null,
  error: null,
};

export const mutationTypes = {
  updateAutoDoctorStart: '[auto-doctor] update start',
  updateAutoDoctorSuccess: '[auto-doctor] update success',
  updateAutoDoctorFailure: '[auto-doctor] update failure',

  getAutoDoctorStart: '[auto-doctor] get settings start',
  getAutoDoctorSuccess: '[auto-doctor] get settings success',
  getAutoDoctorFailure: '[auto-doctor] get settings failure',
};

export const actionTypes = {
  updateAutoDoctor: '[auto-doctor] update',
  getAutoDoctor: '[auto-doctor] get settings',
};

export const gettersTypes = {};

const getters = {};

const mutations = {
  [mutationTypes.updateAutoDoctorStart](state) {
    state.isSubmitting = true;
    state.validationErrors = null;
  },
  [mutationTypes.updateAutoDoctorSuccess](state) {
    state.isSubmitting = false;
  },
  [mutationTypes.updateAutoDoctorFailure](state, payload) {
    state.isSubmitting = false;
    state.validationErrors = payload;
  },

  [mutationTypes.getAutoDoctorStart](state) {
    state.isLoading = true;
    state.error = null;
    state.data = null;
  },
  [mutationTypes.getAutoDoctorSuccess](state, payload) {
    state.isLoading = false;
    state.data = payload;
  },
  [mutationTypes.getAutoDoctorFailure](state, payload) {
    state.isLoading = false;
    state.error = payload;
  },
};

const actions = {
  [actionTypes.updateAutoDoctor](context, credentials) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.updateAutoDoctorStart);
      autoDoctorApi
        .updateAutoDoctor(credentials)
        .then((response) => {
          context.commit(mutationTypes.updateAutoDoctorSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.updateAutoDoctorFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionTypes.getAutoDoctor](context) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getAutoDoctorStart);
      autoDoctorApi
        .getAutoDoctor()
        .then((response) => {
          context.commit(mutationTypes.getAutoDoctorSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getAutoDoctorFailure, result.response.data.message);
          reject(result);
        });
    });
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
