import docsApi from '@/api/docs';

const state = {
  data: null,
  isLoading: false,
  error: null,
};

export const mutationTypes = {
  getDocsStart: '[docs] get data start',
  getDocsSuccess: '[docs] get data success',
  getDocsFailure: '[docs] get data failure',
};

export const actionTypes = {
  getDocs: '[docs] get data',
};

export const gettersTypes = {};

const getters = {};

const mutations = {
  [mutationTypes.getDocsStart](state) {
    state.isLoading = true;
    state.data = null;
  },
  [mutationTypes.getDocsSuccess](state, payload) {
    state.isLoading = false;
    state.data = payload;
  },
  [mutationTypes.getDocsFailure](state) {
    state.isLoading = false;
  },
};

const actions = {
  [actionTypes.getDocs](context) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getDocsStart);
      docsApi
        .getDocs()
        .then((response) => {
          context.commit(mutationTypes.getDocsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.getDocsFailure,
            result.response.data.message
          );
          reject(result.response.data.message);
        });
    });
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
