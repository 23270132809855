<template lang="pug">
.profile-patients.is-filterable(:class="{'filter-is-open': isFilterOpen}")
  .profile-patients__header
    .row.align-items-center
      .col-14.col-md-auto
        app-title(
          :type="'large'"
          :classes="'text-center mb-3 mb-md-0'"
        ) Медицинская документация
      .col.col-md-auto.ms-md-auto
        transition-group(name="translucent")
          template
            button(
              v-if="showButton"
              type="button"
              key="one"
              @click="exportExamsExls()"
              class=['link--accent']
            )
              .d-flex.align-items-center(key="ones")
                span.flex-shrink-0.me-3
                  app-svg-icon(name="xls-doc")
                span.text-size--small {{ isTouchDevice ? 'Выгрузить .xlsx' : 'Выгрузить данные .xlsx' }}
      .col-12
        nav-notices

  .profile-patients__content.mt-20.position-relative
    transition-group(name="translucent")
      app-loading(v-if="isLoading" key="isLoading")
      app-error-message(v-if="error" :message="error" key='isError')
      div(v-if="outgoingMedDocs && outgoingMedDocs.length" key="hasOutgoingMedDocs")
        .profile-table
          .profile-table__header.d-none.d-xl-block
            .row.align-items-center.g-4
              div(v-for="column in columns" :key="column.key" :class="column.class")
                button(
                  v-if="column.caption || column.icon"
                  type="button"
                  @click="sortingPatients(column.key, column.inverse)"
                  class=['profile-table__sort-button']
                  :class="{'is-active': sortKey === column.key}"
                )
                  span.d-flex.align-items-center
                    span.text-overflow(v-if="column.caption") {{ column.caption }}
                    span.flex-shrink-0.d-flex.ms-3
                      app-svg-icon(name="down-arrow")

          ul.profile-table__body
            li.profile-table__row(v-for='item in outgoingMedDocs')
              client-notice(
                :data="item"
              )
          .d-flex.justify-content-center.mt-5
            app-pagination(
              :total="pagination.total"
              :limit="pagination.limit"
              :current-page="currentPage"
              :url="baseUrl"
            )
      div(v-else-if="!isLoading" key="NoOutgoingMedDocs")
        .text-color--blue.text-size--small Медицинская документация не найдена

  // filter
  app-filter-page-outgoing(
    ref="filter"
    :options="filterOptions"
    :is-open="isFilterOpen"
    :is-filtered="isFiltered"
    @open="openFilter"
    @close="close"
    @clear="close"
    @submit="submitFilterDoc"
  )
</template>

<script>
import outgoingMedDocs from '@/api/outgoingMedDocs';
import AppFilterPageOutgoing from '@/components/FilterPageOutgoing';
import {mapState} from 'vuex';

import is from 'is_js';
import variables from '@/helpers/variables';

import AppModal from '@/components/common/Modal';
import AppTitle from '@/components/common/Title';
import AppSvgIcon from '@/components/common/SvgIcon';
import AppLoading from '@/components/common/Loading';
import AppErrorMessage from '@/components/common/ErrorMessage';
import AppPagination from '@/components/common/Pagination';
import AppSelect from '@/components/common/Select';
import {blobToFile} from '@/helpers/utils';

import AppFilter from '@/components/profile/common/FilterNotices';
import ClientNotice from '@/components/profile/client/patients/ClientPatientNotice';
import AppPatientChangeAvatarModal from '@/components/profile/common/patients/PatientChangeAvatarModal';
import NavNotices from '@/components/profile/common/NavNotices';

import {filterMixin} from '@/mixins/filter';
import {galleryMixin} from '@/mixins/gallery';
import useVuelidate from '@vuelidate/core';
export default {
  name: 'AppPageReferences',
  components: {
    AppModal,
    AppTitle,
    AppSelect,
    AppSvgIcon,
    AppLoading,
    AppErrorMessage,
    AppPagination,
    AppFilter,
    ClientNotice,
    AppPatientChangeAvatarModal,
    NavNotices,
    AppFilterPageOutgoing,
  },
  mixins: [filterMixin, galleryMixin],
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      showButton: true,
      isLoading: false,
      outgoingMedDocs: {},
      doc_type: 'all',
      filterString: '',
      resultFilterForLoad: '',
      isFilterOpen: false,
      form: {
        file: null,
      },
      v: variables,
      filterOptions: {
        patients: true,
        date: true,
        methods: {
          getData: ['getPatients'],
          getCount: 'getCountPatients',
        },
      },
      pagination: {
        limit: 15,
        total: 0,
      },
      sortKey: 'exams_data_id',
      sortInverse: false,
      columns: [
        {
          class: 'col-1',
          caption: '№',
          key: 'exams_data_id',
          inverse: true,
        },
        {
          class: 'col-2',
          caption: 'Дата и время',
          key: 'created_at',
          inverse: true,
        },
        {
          class: 'col-2',
          caption: 'Тип документа',
          key: 'doc_type',
          inverse: true,
        },
        {
          class: 'col-2',
          caption: 'ФИО медработника',
          key: 'doctor_full_name',
          inverse: true,
        },
        {
          class: 'col-3',
          caption: 'ФИО пользователя',
          key: 'patient_full_name',
          inverse: true,
        },
        {
          class: 'col-4',
          caption: 'Предварительный диагноз',
          key: 'pre_diagnosis',
          inverse: true,
        },
      ],
      isModalVisible: {
        changeAvatar: false,
        deleteAvatar: false,
        addPdn: false,
      },
      successMessage: 'Успех!',
      filter: {},
    };
  },
  computed: {
    ...mapState({
      error: (state) => state.patients.error,
    }),
    currentPage() {
      return Number(this.$route.query.page || '1');
    },
    baseUrl() {
      return this.$route.path;
    },
    settings() {
      return {
        filters: {
          patients: {
            negative: false,
            payload: [],
          },
        },
        sorting_params: {
          desc: this.sortInverse,
          field: this.sortKey,
        },
      };
    },
  },
  watch: {
    currentPage() {
      this.getPatients();
    },
  },
  created() {
    this.getPatients();
  },
  methods: {
    goToFirstPage() {
      if (this.currentPage !== 1)
        this.$router.push({
          query: {
            page: '1',
          },
        });
    },
    async getPatients() {
      let sortInverse = '-';
      if (this.sortInverse) {
        sortInverse = '+';
      }
      let order_by = `${sortInverse}${this.sortKey}`;
      let resultString = '';
      resultString = `doc_type=${this.doc_type}&page=${this.currentPage - 1}&per_page=10${
        this.filterString
      }&reports_order_by=${order_by}`;

      this.isLoading = true;
      let res = await outgoingMedDocs.getOutgoingMedDocumentV2(resultString);
      this.pagination.total = res.data.total;
      this.pagination.limit = res.data.per_page;
      this.outgoingMedDocs = res.data.items;
      this.isLoading = false;

      for (let i of this.outgoingMedDocs) {
        console.log(i.doc_type);
        if (i.doc_type !== 'alco') {
          this.showButton = false;
        } else {
          this.showButton = true;
        }
      }
    },
    sortBySelect({value, inverse}) {
      this.sortOptions.forEach((el) => (el.selected = el.value === value));
      this.sortingPatients(value, inverse);
    },
    sortingPatients(sortKey, inverse) {
      console.log(sortKey, inverse);
      this.sortInverse = !this.sortInverse;
      this.sortKey = sortKey;
      this.getPatients();
    },
    reverseSorting() {
      if (is.not.empty(this.sortKey)) {
        this.columns.forEach((el) => {
          if (this.sortKey === el.key) el.inverse = !el.inverse;
        });
      }
    },
    async submitFilterDoc(data) {
      this.filterString = data;
      this.goToFirstPage();
      this.getPatients();
    },
    close() {
      this.isFilterOpen = false;
    },
    openFilter() {
      this.isFilterOpen = true;
    },

    exportExamsExls() {
      let filterStringNoSmbl = this.filterString.substring(1);
      if (this.filterString.length) {
        this.resultFilterForLoad = `${filterStringNoSmbl}` + `&reports_order_by=-${this.sortKey}`;
      } else {
        this.resultFilterForLoad = `reports_order_by=-${this.sortKey}`;
      }
      outgoingMedDocs
        .exportOutcomingMeddocs(this.resultFilterForLoad, {})
        .then((response) => {
          return response.data;
        })
        .then((binary) => blobToFile(binary, 'xlsx'))
        .catch(function (error) {
          if (error.request.status == '303') {
            console.log(error);
          }
        });
    },
  },
};
</script>
