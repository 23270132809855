<template lang="pug">
  .notification(:class="[classes, `is-${data.type}`, isWelcome ? 'order-0' : 'order-1']")
    .row.align-items-center.flex-column.flex-lg-row.g-4.g-lg-0
      .col
        .d-flex.flex-column.flex-xxl-row.align-items-xxl-center
          span.text-size--small.mb-2.mb-xxl-0.me-xxl-3(v-if="!isWelcome") {{ data.creation_date }}
          app-title {{ data.title }}
        
        .mt-2.text-size--small {{ data.text }}

      .col.col-lg-auto(v-if="linkContent")
        router-link(
          :to="{name: String(data.content_type)}"
          class=['text-color--accent link--accent', 'd-flex align-items-xl-center']
        )
          span.text-uppercase.ls-lg {{ linkContent }}
          span.ms-3.d-flex
            app-svg-icon(name="right-long-arrow")

    button(
      type="button"
      @click="deleteNotification"
      class=['notification__delete-button', 'link--accent']
    )
      app-svg-icon(name="close")

</template>

<script>
import AppSvgIcon from '@/components/common/SvgIcon';
import AppTitle from '@/components/common/Title';

export default {
  name: 'AppNotification',
  components: {
    AppSvgIcon,
    AppTitle,
  },
  props: {
    data: {
      type: Object,
      required: true,
      default() {
        return {};
      },
    },
    classes: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {};
  },
  computed: {
    isWelcome() {
      return this.data.content_type === 'welcome';
    },
    linkContent() {
      let content;

      switch (this.data.content_type) {
        case 'settings':
          content = 'Перейти в настройки ЛК';
          break;

        case 'devices':
          content = 'Перейти к устройствам';
          break;

        case 'users':
          content = 'Перейти к пользователям';
          break;

        case 'welcome':
          content = null;
          break;
      }

      return content;
    },
  },
  methods: {
    deleteNotification() {
      this.$emit('delete');
    },
  },
};
</script>

<style lang="scss">
.notification {
  position: relative;
  background-color: get($colors, white);
  border: $border-width solid get($colors, orange-light);
  box-shadow: get($shadows, default);
  border-radius: $border-radius-sm;
  padding: $spacer * 1.5 $spacer * 4 $spacer * 1.5 $spacer * 3;

  @include mediaToDown($md) {
    padding: $spacer * 1.5 $spacer * 3 $spacer * 1.5 $spacer * 1.5;
  }
  & + .notification {
    margin-top: $spacer * 2;
  }
  &.is-info {
    border-color: transparent;
  }
  &__delete-button {
    position: absolute;
    top: $spacer * 0.5;
    right: $spacer;
    width: $spacer * 1.4;
    height: $spacer * 1.4;

    svg {
      fill: get($colors, blue);
      width: 100%;
      height: 100%;
    }
  }
}
</style>
