<template lang="pug">
  .tabs-content(v-if="isSelected" :id="id")
    slot
</template>

<script>
export default {
  name: 'AppTab',
  props: {
    isSelected: {
      type: Boolean,
      required: false,
      default: false,
    },
    id: {
      type: String,
      required: false,
      default: null,
    },
  },
};
</script>

<style scoped></style>
