<template lang="pug">
    .profile-doctors
      .profile-doctors__header
        .row.align-items-center
          .col-14.col-md-7
            .d-flex.align-items-center
              app-title(:type="'large'") Подтверджения пользовательских соглашений

            .tabs-nav.separate-theme.mt-20
              .row.g-4
                .col.col-md-auto
                  router-link(
                    :to="{name: 'pdn'}"
                    :class="['tabs-nav__button', 'button', 'w-100']"
                    active-class="is-active"
                  )
                    span.button__content Пользовательские соглашения

                .col.col-md-auto
                  router-link(
                    :to="{name: 'ids'}"
                    :class="['tabs-nav__button', 'button', 'w-100']"
                    active-class="is-active"
                  )
                    span.button__content ИДС

                .col.col-md-auto
                  router-link(
                    :to="{name: 'pdn-users'}"
                    :class="['tabs-nav__button', 'button', 'w-100']"
                    active-class="is-active"
                  )
                    span.button__content Подтверждения



      .profile-doctors__content.position-relative.mt-25
        transition(name="translucent")
          app-loading(v-if="isLoading")
          app-error-message(v-if="error" :message="error")

          div(v-if="pdns")
            template(v-if="pdns.result && pdns.result.length")
              .profile-table
                .profile-table__header.d-none.d-xl-block
                  .row.align-items-center.g-4
                    div(v-for="column in columns" :key="column.key" :class="column.class")
                      button(
                        type="button"
                        @click="sortingDoctors(column.key, column.inverse)"
                        class=['profile-table__sort-button']
                        :class="{'is-active': sortKey === column.key}"
                      )
                        span.d-flex.align-items-center
                          span.text-overflow(:title="column.caption") {{ column.caption }}
                          span.d-flex.ms-3
                            app-svg-icon(name="down-arrow")

                ul.profile-table__body
                  li.profile-table__row(v-for="pdn in pdns.result" :key="pdn.id")
                      section(
                          class=['user-card', 'profile-card']
                      )
                          .row.align-items-center.g-4.gy-xl-0

                              .col-14.col-md-2
                                  div {{$moment(pdn.signed_time).format('DD.MM.YYYY')}}  {{$moment(pdn.signed_time).format('HH:mm:ss')}}

                              .col-7.col-md-3
                                  div {{ pdn.first_name }} {{ pdn.middle_name }} {{ pdn.last_name }}

                              .col-7.col-md-3
                                  div {{ pdn.agreement_id }}

                              .col-7.col-md-3
                                  div
                                      a(v-bind:href="pdn.link") {{ "скачать" }}

            template(v-else)
              .text-color--blue.text-size--small В системе нет существующих медработников
  </template>

<script>
import AppTitle from '@/components/common/Title';
import AppSvgIcon from '@/components/common/SvgIcon';
import AppLoading from '@/components/common/Loading';

import userAgreements from '@/api/pdns';

export default {
  name: 'PdnUsers',
  components: {
    AppTitle,
    AppSvgIcon,
    AppLoading,
  },
  data() {
    return {
      columns: [
        {
          class: 'col-14 col-md-2',
          caption: 'Дата',
          key: 'creation_date',
          inverse: false,
        },
        {
          class: 'col-14 col-md-3',
          caption: 'Пользователь',
          key: 'user',
          inverse: false,
        },
        {
          class: 'col-14 col-md-3',
          caption: '№ соглашения',
          key: 'agreement_id',
          inverse: false,
        },
        {
          class: 'col-14 col-md-3',
          caption: 'Ссылка',
          key: 'agreement_link',
          inverse: false,
        },
      ],
      pdns: {
        result: [
          {
            id: 0,
            date: '10.02.2023',
            link: 'http://link.png',
            name: 'name',
          },
        ],
      },
    };
  },
  async created() {
    console.log('created');
    let data = await userAgreements.userAgreementsToUsers();
    this.pdns.result = data.data.user_agreements_by_users;
    console.log(data.data.user_agreements_by_users);
  },
};
</script>
