<template lang="pug">
  svg(
    :width="width"
    :height="height"
    :class="className"
    :viewBox="viewBox"
    xmlns="http://www.w3.org/2000/svg"
  )
    template(v-if="name === 'smartwatch'")
      rect(x="3.36621" y="0.5" width="13.069" height="20.5172" rx="1.5" fill="none" stroke-width="1" stroke="currentColor" stroke-linejoin="round")
      path(d="M0.28125 6.13789C0.28125 5.03332 1.17668 4.13789 2.28125 4.13789H3.59159V8.27582H2.28125C1.17668 8.27582 0.28125 7.38039 0.28125 6.27582V6.13789Z")
      path(d="M0.333008 21.862C0.333008 20.7574 1.22844 19.862 2.33301 19.862H3.36988V19.862C3.36988 20.719 4.06461 21.4137 4.9216 21.4137H14.9345C15.8417 21.4137 16.6353 20.8031 16.8677 19.9261L16.8847 19.862V22.9999C16.8847 23.5522 16.437 23.9999 15.8847 23.9999H1.33301C0.780724 23.9999 0.333008 23.5522 0.333008 22.9999V21.862Z")
      path(d="M4.62402 10.0736H4.99389L6.73511 6.04538C6.80955 5.8728 6.9472 5.77772 7.08806 5.80143C7.22874 5.82513 7.34716 5.96355 7.3915 6.15627L9.26094 14.2599L11.1656 7.98201C11.2244 7.78776 11.3594 7.66387 11.5072 7.66795C11.655 7.67203 11.7866 7.80357 11.84 8.00087L12.9824 12.2116L13.882 10.3033C13.9498 10.1595 14.0631 10.0734 14.1844 10.0734H15.3826V2.35542C15.3826 1.96867 15.0615 1.65515 14.6654 1.65515H5.34126C4.94514 1.65515 4.62402 1.96867 4.62402 2.35542V10.0736Z")
      path(d="M13.1969 13.2846C13.1209 13.4444 12.989 13.5308 12.854 13.5096C12.7192 13.4881 12.6038 13.3619 12.5546 13.1824L11.4812 9.2576L9.54906 15.5747C9.49119 15.7638 9.35977 15.8862 9.21464 15.8862C9.20912 15.8862 9.2036 15.8862 9.1979 15.8857C9.04689 15.8758 8.91565 15.7347 8.86828 15.5307L6.94399 7.25727L5.50728 10.5545C5.44086 10.707 5.3235 10.7998 5.19725 10.7998H4.62402V19.1618C4.62402 19.5485 4.94514 19.862 5.34126 19.862H14.6654C15.0615 19.862 15.3826 19.5485 15.3826 19.1618V10.7998H14.3781L13.1969 13.2846Z")

    use(v-else :xlink:href="iconPath")

    slot(name="defs")

</template>

<script>
export default {
  name: 'AppSvgIcon',
  props: {
    name: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      required: false,
      default: '1rem',
    },
    height: {
      type: String,
      required: false,
      default: '1rem',
    },
    viewBox: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    iconPath() {
      return require(`@/assets/svg/${this.name}.svg`).default.url;
    },
    className() {
      return 'icon icon__' + this.name;
    },
  },
};
</script>
