import examsApi from '@/api/exams';

const state = {
  data: null,
  count: null,
  isLoading: false,
  error: null,
};

export const mutationTypes = {
  getExamsStart: '[exams] get data start',
  getExamsSuccess: '[exams] get data success',
  getExamsFailure: '[exams] get data failure',

  getExamByIdStart: '[exams] get data by id start',
  getExamByIdSuccess: '[exams] get data by id success',
  getExamByIdFailure: '[exams] get data by id failure',

  getCountExamsStart: '[exams] get count start',
  getCountExamsSuccess: '[exams] get count success',
  getCountExamsFailure: '[exams] get count failure',

  exportExamsStart: '[exams] export data start',
  exportExamsSuccess: '[exams] export data success',
  exportExamsFailure: '[exams] export data failure',
};

export const actionTypes = {
  getExams: '[exams] get data',
  getExamById: '[exams] get data by id',
  getCountExams: '[exams] get count',
  exportExams: '[exams] export data',
  exportExams835: '[exams] export data to Form 835',
};

export const gettersTypes = {};

const getters = {};

const mutations = {
  [mutationTypes.getExamsStart](state) {
    state.isLoading = true;
    state.data = null;
    state.error = null;
  },
  [mutationTypes.getExamsSuccess](state, payload) {
    state.isLoading = false;
    state.data = payload;
  },
  [mutationTypes.getExamsFailure](state, payload) {
    state.isLoading = false;
    state.error = payload;
  },

  [mutationTypes.getExamByIdStart](state) {
    state.isLoading = true;
    state.data = null;
    state.error = null;
  },
  [mutationTypes.getExamByIdSuccess](state, payload) {
    state.isLoading = false;
    state.data = payload;
  },
  [mutationTypes.getExamByIdFailure](state, payload) {
    state.isLoading = false;
    state.error = payload;
  },

  [mutationTypes.getCountExamsStart](state) {
    state.count = null;
  },
  [mutationTypes.getCountExamsSuccess](state, payload) {
    state.count = payload;
  },
  [mutationTypes.getCountExamsFailure]() {},

  [mutationTypes.exportExamsStart]() {},
  [mutationTypes.exportExamsSuccess]() {},
  [mutationTypes.exportExamsFailure]() {},
};

const actions = {
  [actionTypes.getExams](context, credentials) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getExamsStart);
      examsApi
        .getExams(credentials)
        .then((response) => {
          context.commit(mutationTypes.getExamsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getExamsFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionTypes.getExamById](context, credentials) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getExamByIdStart);
      examsApi
        .getExamById(credentials)
        .then((response) => {
          context.commit(mutationTypes.getExamByIdSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getExamByIdFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionTypes.getCountExams](context, credentials) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getCountExamsStart);
      examsApi
        .getCountExams(credentials)
        .then((response) => {
          context.commit(mutationTypes.getCountExamsSuccess, response.data.total);
          resolve(response.data.total);
        })
        .catch((result) => {
          context.commit(mutationTypes.getCountExamsFailure);
          reject(result);
        });
    });
  },
  [actionTypes.exportExams](context, credentials) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.exportExamsStart);
      examsApi
        .exportExams(credentials)
        .then((response) => {
          context.commit(mutationTypes.exportExamsSuccess);
          resolve(response.data);
        })
        .catch(() => {
          context.commit(mutationTypes.exportExamsFailure);
        });
    });
  },
  [actionTypes.exportExams835](context, credentials) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.exportExamsStart);
      examsApi
        .exportExams835(credentials)
        .then((response) => {
          context.commit(mutationTypes.exportExamsSuccess);
          resolve(response.data);
        })
        .catch(() => {
          context.commit(mutationTypes.exportExamsFailure);
        });
    });
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
