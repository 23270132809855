<template lang="pug">
.profile-filter(:class="{'is-open': isOpen}")
  .profile-filter__container.d-flex.flex-column
    button(
      @click="open"
      type="button"
      :class="{'is-dirty': isDirty}"
      class=['profile-filter__open-button-mobile', 'd-xl-none']
      :style="{'top': style.top}"
    )
      app-svg-icon(name="filter")

    .profile-filter__header.d-flex.align-items-center.justify-content-between.p-3
      button(
        @click="open"
        type="button"
        class="profile-filter__open-button"
      )
        app-svg-icon(name="filter")

      transition(name="fade")
        button(
          v-if="isDirty"
          type="button"
          @click="clear"
          class=['profile-filter__button', 'text-color--blue-dark text-size--small']
        )
          span.me-3
            app-svg-icon(name="close")
          span Сбросить фильтры

      button(
        @click="close"
        type="button"
        class="filter__close-button"
      )
        app-svg-icon(name="right-long-arrow")

    .profile-filter__content.flex-grow-1.d-flex.flex-column.pt-3
      simplebar(class="profile-filter__form flex-grow-1")
        form(@submit.prevent="submit" :class="['form']" id="filter-form")
          .container-fluid.px-3
            .row.flex-column.align-items-center.g-3
              // search patients
              template
                .col.mb-4
                  .form__caption.mb-2 Пользователи:
                  app-multiple-select(
                    ref="multipeSelectPatients"
                    :search-type="'patients'"
                    :placeholder="'Введите ФИО'"
                    @update="updateSelectedPatients"
                    :clean='cleanSelect'
                  )
                  .form__caption.mb-2.mt-4 Организация:
                  app-multiple-select(
                    ref="multipeSelectUsers"
                    :search-type="'users'"
                    :placeholder="'Введите ФИО'"
                    @update="updateSelectedUsers"
                    :clean='cleanSelect'
                  )
                  .form__caption.mb-2.mt-4 Медработник:
                  app-multiple-select(
                    ref="multipeSelectDcotors"
                    :search-type="'doctors'"
                    :placeholder="'Введите ФИО'"
                    @update="updateSelectedDoctors"
                    :clean='cleanSelect'
                  )
                  .mt-3
                    app-multiple-select(
                      ref="multipeSelectPre"
                      :search-type="'pre_diagnosis'"
                      :placeholder="'Предварительный диагноз'"
                      @update="updateSelectedPreDiag"
                      :clean='cleanSelect'
                  )

            // date interval
            template
              .row.flex-column.align-items-center.g-3.mt-2
                .col
                  .form__caption.mb-3 Интервал даты и времени
                  .row.g-3
                    .col-7
                      date-picker(
                        v-model="form.date.from"
                        prefix-class="app"
                        placeholder="от"
                        format="DD.MM.YYYY"
                        :lang="datepicker.lang"
                        :editable="false"
                        :disabled-date="notAfterDateTo"
                        @change="datepickerChange('dateFrom')"
                        @clear="datepickerClear('dateFrom')"
                      )
                        template(v-slot:icon-calendar)
                          app-svg-icon(name="calendar")

                    .col-7
                      date-picker(
                        v-model="form.date.to"
                        prefix-class="app"
                        placeholder="до"
                        format="DD.MM.YYYY"
                        :lang="datepicker.lang"
                        :editable="false"
                        :disabled-date="notBeforeDateFrom"
                        @change="datepickerChange('dateTo')"
                        @clear="datepickerClear('dateTo')"
                      )
                        template(v-slot:icon-calendar)
                          app-svg-icon(name="calendar")

                    // time interval
                    template
                      .col-7
                        date-picker(
                          v-model="form.time.from"
                          prefix-class="app"
                          placeholder="от"
                          format="HH:mm"
                          type="time"
                          :editable="false"
                          :disabled-time="notAfterTimeTo"
                          @change="datepickerChange('timeFrom')"
                          @clear="datepickerClear('timeFrom')"
                          :disabled="!form.date.from"
                        )
                          template(v-slot:icon-calendar)
                            app-svg-icon(name="time")
                      .col-7
                        date-picker(
                          v-model="form.time.to"
                          prefix-class="app"
                          placeholder="до"
                          format="HH:mm"
                          type="time"
                          :editable="false"
                          :disabled-time="notBeforeTimeFrom"
                          @change="datepickerChange('timeTo')"
                          @clear="datepickerClear('timeTo')"
                          :disabled="!form.date.to"
                        )
                          template(v-slot:icon-calendar)
                            app-svg-icon(name="time")

      // submit button
      .profile-filter__submit-button.flex-shrink-0.mt-auto
        button(
          type="submit"
          form="filter-form"
          :disabled="!isDirty || count === 0 || isFiltered"
          class=['button', 'button--accent', 'form__button', 'mt-auto d-flex justify-content-center']
        )
          span.button__content
            span Показать
            transition(name="translucent" mode="out-in")
              span.profile-filter__count(v-if="isDirty && count" :key="count") &nbsp;({{ count }})

    button(
      v-if="isFiltered && isDirty"
      type="button"
      @click="clear"
      class=['profile-filter__clear-button', 'd-flex flex-column align-items-center']
    )
      span.d-flex.mb-2
        app-svg-icon(name="close")
      span.text-size--extra-small.text-decoration--underline Сбросить
</template>

<script>
import {
    mapState
} from 'vuex';
import {
    actionTypes as filtersActionTypes
} from '@/store/modules/filters';

import {
    toLocalTime
} from '@/helpers/utils';
import is from 'is_js';

import simplebar from 'simplebar-vue';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/ru';

import AppValidationErrors from '@/components/common/ValidationErrors';
import AppSvgIcon from '@/components/common/SvgIcon';
import AppMultipleSelect from '@/components/common/MultipleSelect';
import AppSelect from '@/components/common/Select';
import AppTooltip from '@/components/common/Tooltip';

import Autocomplete from 'vuejs-auto-complete'

//import timeFromTo from '@/helpers/timeFromTo';
export default {
    name: 'AppFilter',
    components: {
        AppTooltip,
        AppSelect,
        AppMultipleSelect,
        AppSvgIcon,
        AppValidationErrors,
        DatePicker,
        simplebar,
        Autocomplete,

    },
    props: {
        isOpen: {
            type: Boolean,
            required: true,
            default: true,
        },
        isFiltered: {
            type: Boolean,
            required: true,
            default: false,
        },
        count: {
            type: Number,
            required: false,
            default: null,
        },
        options: {
            type: Object,
            required: true,
            default () {
                return {};
            },
        },
        typeAction: {
            type: String,
            required: true,
             default () {
                return 'ref';
            },
        },
    },
    data() {
        return {
            cleanSelect: false,
            style: {
                top: null,
            },
            delay: 2000,
            anchor: '#isAnchorForBtn',
            form: {
                isDirty: {
                    patients: false,
                    doctors: false,
                    pre_diagnosis: false,
                    dateFrom: false,
                    dateTo: false,
                    timeFrom: false,
                    timeTo: false,
                    users:false
                },
                patients: [],
                doctors: [],
                users:[],
                pre_diagnosis: [],
                date: {
                    from: null,
                    to: null,
                },
                time: {
                    from: null,
                    to: null,
                },
            },
            datepicker: {
                lang: {
                    formatLocale: {
                        firstDayOfWeek: 1,
                    },
                    monthBeforeYear: false,
                },
            },
        };
    },
    computed: {
        ...mapState({
            isLoading: (state) => state.filters.isLoading,
            filters: (state) => state.filters.data,
            error: (state) => state.filters.error,
        }),
        isDirty() {
            return Object.values(this.form.isDirty).some((item) => item === true);
        },
        isTouchDevice() {
            return is.touchDevice();
        },
        collectData() {
            let data = {};
            data.patients = {
                negative: false,
                payload: [],
            };
            if (is.not.empty(this.form.patients)) {
                data.patients.payload = this.form.patients;
            }
            data.doctors = {
                negative: false,
                payload: [],
            };
            if (is.not.empty(this.form.doctors)) {
                data.doctors.payload = this.form.doctors;
            }
            data.pre_diagnosis = {
                negative: false,
                payload: [],
            };
            if (is.not.empty(this.form.pre_diagnosis)) {
                data.pre_diagnosis.payload = this.form.pre_diagnosis;
            }
             data.users = {
                negative: false,
                payload: [],
            };
            if (is.not.empty(this.form.users)) {
                data.users.payload = this.form.users;
            }
            //  if (is.not.empty(this.form.doctors)) {
            //     data.doctors.payload = this.form.doctors;
            // }
            if (is.existy(this.form.date.from)) data.date_from = this.$moment(this.form.date.from).format('DD.MM.YYYY')
            if (is.existy(this.form.date.to)) data.date_to = this.$moment(this.form.date.to).format('DD.MM.YYYY')
            if (is.existy(this.form.time.from)) data.time_from = toLocalTime(this.form.time.from);
            if (is.existy(this.form.time.to)) data.time_to = toLocalTime(this.form.time.to);

            return data;
        },
    },
    watch: {
        async isDirty(value) {
            if (!value) this.$emit('clearClose');
                const data = {
                "filters": {
                    'paging_params': {'limit': 15, 'offset': 0}
                },
            }
            this.$store.commit('loading', true);
            //await this.$store.dispatch("actionGetChartDataOnlyChart", data);
            if(this.typeAction == 'ref'){
            await this.$store.dispatch("actionGetOutRef", data);
            } else {
                await this.$store.dispatch("actionGetOutDir", data);
            }
             this.$emit('submit', data);
            this.$router.push({
                query: {
                    page: '1'
                }
            });
        },
    },
    async mounted() {
       this.getSearchData();
    },
    methods: {
        updateSelectedPatients(data) {
            this.form.isDirty.patients = data.length > 0;
            this.form.patients = data.map((el) => el.value);

            //  this.getCount();
        },
         updateSelectedUsers(data) {
            this.form.isDirty.users = data.length > 0;
            this.form.users = data.map((el) => el.value);

            //  this.getCount();
        },
        updateSelectedDoctors(data) {
            this.form.isDirty.doctors = data.length > 0;
            this.form.doctors = data.map((el) => el.value);
        },
        updateSelectedPreDiag(data) {
            this.form.isDirty.pre_diagnosis = data.length > 0;
            this.form.pre_diagnosis = data.map((el) => el.value);
        },

        datepickerChange(slug) {
            this.form.isDirty[slug] = true;
            // this.getCount();
        },
        datepickerClear(slug) {
            this.form.isDirty[slug] = false;
            this.form.isDirty[slug] = false;

            if (slug === 'dateTo') {
                this.form.time.to = null;
                this.form.isDirty.timeTo = false;
            }
            if (slug === 'dateFrom') {
                this.form.time.from = null;
                this.form.isDirty.timeFrom = false;
            }
        },
        notAfterDateTo(date) {
            if (this.form.date.to) return date > this.form.date.to;
        },
        notBeforeDateFrom(date) {
            if (this.form.date.from) return date < this.form.date.from;
        },
        notAfterTimeTo(time) {
            if (this.form.time.to) return time > this.form.time.to;
        },
        notBeforeTimeFrom(time) {
            if (this.form.time.from) return time < this.form.time.from;
        },

        setBtnPosition(anchor) {
            const el = document.querySelector(anchor || this.anchor);
            this.style.top = el ? `${el.getBoundingClientRect().y}px` : null;
        },
        open() {
            this.$emit('open');
        },
        close() {
            this.$emit('closeFilter');
            if (this.isTouchDevice) this.submit();
        },
        async clear() {
            this.cleanSelect = !this.cleanSelect;
            // this.$refs.multipeSelectPatients.clear();
            //  this.$refs.multipeSelectDcotors.clear();
            //  this.$refs.multipeSelectPre.clear();
            //  this.$refs.multipeSelectUsers.clear();
            this.form.date.from = null;
            this.form.date.to = null;
            this.form.time.from = null;
            this.form.time.to = null;
            this.form.patients = [];
            this.form.doctors = [];
            this.form.users = [];
             this.form.pre_diagnosis = [];
              const data = {
                "filters": {
                    'paging_params': {'limit': 15, 'offset': 0}
                },
                "sorting_params":{"desc":false, "field":"datetime"}
            }
            if(this.typeAction == 'ref'){
            await this.$store.dispatch("actionGetOutRef", data);
            } else {
                await this.$store.dispatch("actionGetOutDir", data);
            }
            for (let key in this.form.isDirty) {
                if (Object.prototype.hasOwnProperty.call(this.form.isDirty, key)) {
                    this.form.isDirty[key] = false;
                }
            }
        },
        // getCount() {
        //   this.$emit('getCount', this.collectData);
        // },
        getSearchData() {
            this.$store.dispatch(filtersActionTypes.getFilters);
        },
        async submit() {
            if (is.falsy(this.isDirty)) return false;
            //alert('Sended')
            //this.$emit('submit', this.collectData);
            const data = {
                "filters": {
                    'date_from': this.collectData.date_from,
                    'date_to': this.collectData.date_to,
                    'time_from': this.collectData.time_from,
                    'time_to': this.collectData.time_to,
                    "patients": {
                        "negative": false,
                        "payload": this.collectData.patients.payload
                    },
                    "doctors": {
                        "negative": false,
                        "payload": this.collectData.doctors.payload
                    },
                     "pre_diagnosis": {
                        "negative": false,
                        "payload": this.collectData.pre_diagnosis.payload
                    },
                    "users": {
                        "negative": false,
                        "payload": this.collectData.users.payload
                    },
                    'paging_params': {'limit': 15, 'offset': 0}
                },
                "sorting_params":{"desc": true, "field":"datetime"}
            }
            this.$store.commit('loading', true);
             this.$router.push({
                query: {
                    page: '1'
                }
            });
            //await this.$store.dispatch("actionGetChartDataOnlyChart", data);
            if(this.typeAction == 'ref'){
                await this.$store.dispatch("actionGetOutRef", data);
            } else {
                await this.$store.dispatch("actionGetOutDir", data);
            }
        },
    },
};
</script>
