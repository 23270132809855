cc
<template lang="pug">
    .profile-doctors
      .profile-doctors__header
        .row.align-items-center
          .col-14.col-md-12
            .d-flex.align-items-center
              app-title(:type="'large'") Настройки системы. Управление осмотрами
</template>

<script>
import AppTitle from '@/components/common/Title';
import AppMultipleSelect from '@/components/common/MultipleSelect';
import {gettersTypes as authGettersTypes} from '@/store/modules/auth';
import {mapGetters} from 'vuex';

export default {
  name: 'SystemSetupInspectionControl',
  components: {
    AppTitle,
    AppMultipleSelect,
  },
  data() {
    return {
      user: null,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: authGettersTypes.currentUser,
    }),
  },

  methods: {},
};
</script>

<style></style>
