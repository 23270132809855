<template lang="pug">
    .profile-doctors
      .profile-doctors__header
        .row.align-items-center
          .col-14.col-md-7
            .d-flex.align-items-center
              app-title(:type="'large'") Идс

            .tabs-nav.separate-theme.mt-20
              .row.g-4
                .col.col-md-auto
                  router-link(
                    :to="{name: 'pdn'}"
                    :class="['tabs-nav__button', 'button', 'w-100']"
                    active-class="is-active"
                  )
                    span.button__content Пользовательские соглашения

                .col.col-md-auto
                  router-link(
                    :to="{name: 'ids'}"
                    :class="['tabs-nav__button', 'button', 'w-100']"
                    active-class="is-active"
                  )
                    span.button__content ИДС

                .col.col-md-auto
                  router-link(
                    :to="{name: 'pdn-users'}"
                    :class="['tabs-nav__button', 'button', 'w-100']"
                    active-class="is-active"
                  )
                    span.button__content Подтверждения




        .col-14.col-md-7.d-none.d-md-block
          .d-flex
            button(
                type="button"
                class=['button button--border-blue ids-modal']
                @click='isModalVisible.addPdn=true'
            )
              span.button__content
                span.d-flex.flex-shrink-0.me-3
                  app-svg-icon(name="plus-thin")
                span Загрузить ИДС

      app-modal(v-if="isModalVisible.addPdn" @close="closeModal('addPdn')")
          template(v-slot:body)
            .row.justify-content-center
              .col-14.col-md-10
                .text-center
                  app-title(:type="'large'") Загрузить ИДС
                  .mt-3
                    p Загрузите ИДС пожалуйста

                .mt-5
                  form(@submit.prevent :class="['form', {'is-submitting': isLoading}]")
                    .row.flex-column.align-items-center
                     .col-14.col-md-13
                      .form__file.d-flex.align-items-center(:class="{ 'is-error': v$.form.file.$invalid && v$.form.$dirty }")
                        input(
                          class="d-none"
                          type="file"
                          name="document"
                          @change="onFileSelected"
                          @click="v$.$touch()"
                          ref="fileInput"
                          accept="application/pdf"
                        )

                        div(v-if="form.file" class=['form__file-element', 'd-flex align-items-center', 'text-size--small'])
                          span.flex-shrink-0.me-3
                            app-svg-icon(name="document")

                          ul.flex-grow-1.overflow-hidden
                            li.form__file-name.text-color--black {{ form.file.name }}
                            li.mt-1 {{ Number(form.file.size/1000000).toFixed(2) }} mb

                          button(
                            type="button"
                            @click="clearFileInput"
                            class=['form__file-delete', 'flex-shrink-0 ms-3']
                          )
                            app-svg-icon(name="trash")

                        .text-color--blue.text-size--small(v-else) Приложите документ .pdf

                        button(
                          class=['form__file-button', 'd-flex justify-content-end align-items-center']
                          @click="$refs.fileInput.click()"
                        )
                          app-svg-icon(name="clip")

                      //- ul.form__error(v-if="v$.form.file.$invalid && v$.form.$dirty")
                      //-   li {{ v.errors.required }}
                      .col
                        button(
                          type="submit"
                          :disabled="v$.form.$invalid"
                          class=['button mt-5', 'button--accent', 'form__button']
                          @click="onSubmitPdnForm"
                        )
                          span.button__content Добавить
  </template>

<script>
import {mapState} from 'vuex';

import AppTitle from '@/components/common/Title';
import AppSvgIcon from '@/components/common/SvgIcon';
import AppLoading from '@/components/common/Loading';
import AppModal from '@/components/common/Modal';
import {requiredIf} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import {modalsMixin} from '@/mixins/modals';
export default {
  name: 'Ids',
  components: {
    AppTitle,
    AppSvgIcon,
    AppLoading,
    AppModal,
  },
  mixins: [modalsMixin],
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      isModalVisible: {
        addPdn: false,
      },
      form: {
        file: null,
      },
    };
  },
  validations() {
    return {
      form: {
        file: {
          required: requiredIf(() => this.form.file === null),
        },
      },
    };
  },
  computed: {
    ...mapState({
      settings: (state) => state.settings.data,
    }),
  },
  methods: {
    clearFileInput() {
      this.form.file = null;
      this.$refs.fileInput.value = '';
    },
    async onSubmitPdnForm() {
      const formData = new FormData();
      console.log('this.form.file', this.form.file);
      formData.append('agreements', this.form.file);
      formData.append('title', '123');
      await this.$store.dispatch('actionUploadPdn', formData);
      this.isModalVisible.addPdn = false;
      this.$toast.success('ПДН добавлен');
    },
  },
};
</script>

<style>
.pdn-link {
  cursor: pointer;
}

.ids-modal {
  margin-top: 40px;
}
</style>
