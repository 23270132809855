<template lang="pug">
  transition(name="fade" mode="out-in")
    app-modal(v-if="isVisible" @close="closeModal")
      template(v-slot:body)
        .text-center.mb-5
          app-title(:type="'large'") Редактировать поверку устройства
        form(@submit.prevent="onSubmit" :class="['form', {'is-submitting': isLoading}]")

          .row.flex-column.align-items-center.g-4
            .col-14.col-md-10(v-if="validationErrors.message")
              app-validation-errors(:validation-errors="validationErrors.message")

              template(v-if="validationErrors.info")
                ul(
                  v-for="(request, i) in validationErrors.info"
                  :key="request.serial"
                  class=['form__info', 'text-size--small', 'mt-2']
                )
                  li
                    span.text-family--italic.me-3
                      template(v-if="request.serial") {{ `#${request.serial}` }}
                      template(v-else) {{ `#${request}` }}

                    span(v-if="request.comment") {{ request.comment }}
            .col-14.col-md-10.mt-3
              .row.g-4.posRelative
                .col
                 div
                  input(
                      :class="['form__field form__field--animate']"
                      type="text"
                      name="id"
                      placeholder="&nbsp;"
                      autocomplete="off"
                      id="id"
                      v-model="form.serial"
                      disabled
                    )
                  label.textFloat(for='id') Серийный номер устройства
                  input(
                      :class="['form__field form__field--animate','mt-3']"
                      type="text"
                      name="id"
                      placeholder="&nbsp;"
                      autocomplete="off"
                      id="id"
                      v-model="form.device_attestation"
                    )
                  label.textFloat(for='id') Номер поверки
                  .row.gx-4
                   .col-7
                      .text-size--small.mb-2.mt-3 Дата поверки
                      date-picker(
                          prefix-class="app"
                          placeholder="Дата поверки"
                          format="DD.MM.YYYY"
                          lang="ru"
                          :editable="true"
                          :disabled-date="disableAfterToday"
                          v-model='form.device_attestation_start'
                      )
                   .col-7
                     .text-size--small.mb-2.mt-3  Окончание срока поверки
                     date-picker(
                          prefix-class="app"
                          placeholder="Окончание срока поверки"
                          format="DD.MM.YYYY"
                          lang="ru"
                          :editable="true"
                          v-model='form.device_attestation_end'
                          :disabled-date="disableExpiration"
                      )

            .col-14.col-md-10.mt-5
              button(
                type="submit"
                :disabled="v$.$invalid"
                class=['button', 'button--accent', 'form__button']
              )
                span.button__content Отправить
            .col-14.col-md-9

</template>

<script>
import {mapState} from 'vuex';
import {actionTypes as requestsActionTypes} from '@/store/modules/requests';

import variables from '@/helpers/variables';

import useVuelidate from '@vuelidate/core';
//import {helpers, required, requiredIf, alphaNum} from '@vuelidate/validators';

import AppTitle from '@/components/common/Title';
import AppModal from '@/components/common/Modal';
import AppValidationErrors from '@/components/common/ValidationErrors';
import AppSvgIcon from '@/components/common/SvgIcon';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/ru';
export default {
  name: 'AppCreateDeviceModal',
  components: {
    AppModal,
    AppTitle,
    AppValidationErrors,
    AppSvgIcon,
    DatePicker,
  },
  props: {
    isVisible: {
      required: true,
      type: Boolean,
      default: false,
    },
    toEdit: {
      required: true,
      type: Object,
      default() {
        return {};
      },
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      v: variables,
      form: {
        responsible: true,
        serial: '',
        device_attestation_start: '',
        device_attestation_end: '',
        device_attestation: '',
        number: '',
        devices: [
          {
            id: 0,
            value: '',
          },
        ],
        file: null,
      },
      validationErrors: {
        message: '',
        info: null,
      },
      limit: 100,
    };
  },
  validations() {
    return {
      form: {},
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.requests.isLoading,
      error: (state) => state.requests.error,
    }),
  },
  watch: {
    toEdit: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      handler() {
        (this.form.responsible = this.toEdit.device_verification_owner == null ? false : true),
          (this.form.serial = this.toEdit.serial),
          (this.form.device_attestation = this.toEdit.device_attestation),
          (this.form.device_attestation_start = new Date(
            new Date(this.toEdit.device_attestation_start).setHours(0, 0, 0, 0)
          )),
          (this.form.device_attestation_end = new Date(
            new Date(this.toEdit.device_attestation_end).setHours(0, 0, 0, 0)
          )),
          (this.form.number = this.toEdit.serial);
      },
    },
  },

  methods: {
    closeModal() {
      // this.resetForm();
      this.$emit('close');
    },
    success() {
      this.$emit('success');
    },
    changeNumOfDevices(slug, index) {
      slug === 'add'
        ? this.form.devices.push({id: index, value: ''})
        : this.form.devices.splice(index, 1);
    },
    onFileSelected({target}) {
      this.form.file = target.files[0];
    },
    clearFileInput() {
      this.form.file = null;
      this.$refs.fileInput.value = '';
    },
    onSubmit() {
      if (!this.v$.$invalid) {
        const formData = new FormData();
        formData.append('device_id', this.form.number);
        formData.append('device_attestation', this.form.device_attestation);
        formData.append('device_attestation_start', this.form.device_attestation_start);
        formData.append('device_attestation_end', this.form.device_attestation_end);
        this.$store
          .dispatch(requestsActionTypes.createDeviceUpdateRequest, formData)
          .then(() => {
            this.closeModal();
            this.$toast.success('Заявка на обновление устройства отправлена');
            this.success();
          })
          .catch((result) => {
            if (this.error === 'device_exists') {
              this.validationErrors.message = this.error;
              this.validationErrors.info = result;
            } else this.validationErrors.message = result;
          });
      }
    },
    resetForm() {
      this.v$.$reset();

      this.validationErrors.message = '';
      this.validationErrors.info = null;

      this.form.devices = [{id: 0, value: ''}];
      this.clearFileInput();
    },
    disableAfterToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      if (this.form.device_attestation_start != '') {
        return date > this.form.device_attestation_end;
      } else {
        this.form.device_attestation_end = '';
        return date > today;
      }
    },
    disableExpiration(date) {
      return date < this.form.device_attestation_start;
    },
  },
};
</script>
<style lang="scss">
.form__radio-button--high {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.form__radio-button--left {
  border-radius: 6px 0 0 6px !important;
}
.form__radio-button--right {
  border-radius: 0 6px 6px 0 !important;
}
</style>
