import requestsApi from '@/api/requests';

const state = {
  data: null,
  isLoading: false,
  error: null,
};

export const mutationTypes = {
  getRequestsStart: '[requests] get data start',
  getRequestsSuccess: '[requests] get data success',
  getRequestsFailure: '[requests] get data failure',

  changeStatusStart: '[requests] change status start',
  changeStatusSuccess: '[requests] change status success',
  changeStatusFailure: '[requests] change status failure',

  createDeviceRequestStart: '[requests] create device request start',
  createDeviceRequestSuccess: '[requests] create device request success',
  createDeviceRequestFailure: '[requests] create device request failure',

  approveRequestStart: '[requests] approve request start',
  approveRequestSuccess: '[requests] approve request success',
  approveRequestFailure: '[requests] approve request failure',

  createDeviceUpdateRequestStart: '[requests] create device update request start',
  createDeviceUpdateRequestSuccess: '[requses] create device update request success',
  createDeviceUpdateRequestFailure: '[requses] create device update request failure',

  changeDeviceUpdateRequestStart: '[requests] change device update request start',
  changeDeviceUpdateRequestSuccess: '[requses] change device update request success',
  changeDeviceUpdateRequestFailure: '[requses] change device update request failure',
};

export const actionTypes = {
  getRequests: '[requests] get data',
  approveRequest: '[requests] approve request',

  changeStatusRegistration: '[requests] change status registration',
  changeStatusDevice: '[requests] change status device',

  createDeviceRequest: '[requests] create device request',

  createDeviceUpdateRequest: '[requests] create device update request',
  changeDeviceUpdateRequest: '[requests] change device update request'
};

export const gettersTypes = {};

const getters = {};

const mutations = {
  [mutationTypes.getRequestsStart](state) {
    state.isLoading = true;
    state.data = null;
    state.error = null;
  },
  [mutationTypes.getRequestsSuccess](state, payload) {
    state.isLoading = false;
    state.data = payload;
  },
  [mutationTypes.getRequestsFailure](state, payload) {
    state.isLoading = false;
    state.error = payload;
  },

  [mutationTypes.changeStatusStart](state) {
    state.isLoading = true;
    state.error = null;
  },
  [mutationTypes.changeStatusSuccess](state) {
    state.isLoading = false;
  },
  [mutationTypes.changeStatusFailure](state, payload) {
    state.isLoading = false;
    state.error = payload;
  },

  [mutationTypes.createDeviceRequestStart](state) {
    state.isLoading = true;
    state.error = null;
  },
  [mutationTypes.createDeviceRequestSuccess](state) {
    state.isLoading = false;
  },
  [mutationTypes.createDeviceRequestFailure](state, payload) {
    state.isLoading = false;
    state.error = payload;
  },

  [mutationTypes.createDeviceUpdateRequestStart](state) {
    state.isLoading = true;
    state.error = null;
  },
  [mutationTypes.createDeviceUpdateRequestSuccess](state) {
    state.isLoading = false;
  },
  [mutationTypes.createDeviceUpdateRequestFailure](state, payload) {
    state.isLoading = false;
    state.error = payload;
  },

  [mutationTypes.changeDeviceUpdateRequestStart](state) {
    state.isLoading = true;
    state.error = null;
  },
  [mutationTypes.changeDeviceUpdateRequestSuccess](state) {
    state.isLoading = false;
  },
  [mutationTypes.changeDeviceUpdateRequestFailure](state, payload) {
    state.isLoading = false;
    state.error = payload;
  },

  [mutationTypes.approveRequestStart](state) {
    state.isLoading = true;
    state.error = null;
  },
  [mutationTypes.approveRequestSuccess](state) {
    state.isLoading = false;
  },
  [mutationTypes.approveRequestFailure](state, payload) {
    state.isLoading = false;
    state.error = payload;
  },
};

const actions = {
  [actionTypes.getRequests](context, credentials) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getRequestsStart);
      requestsApi
        .getRequests(credentials)
        .then((response) => {
          context.commit(mutationTypes.getRequestsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getRequestsFailure, result.response.data.message);
          reject(result.response.data.message);
        });
    });
  },
  [actionTypes.changeStatusRegistration](context, credentials) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.changeStatusStart);
      requestsApi
        .changeStatusRegRequest(credentials)
        .then((response) => {
          context.commit(mutationTypes.changeStatusSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.changeStatusFailure, result.response.data.message);
          reject(result.response.data.message);
        });
    });
  },
  [actionTypes.changeStatusDevice](context, credentials) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.changeStatusStart);
      requestsApi
        .changeStatusDeviceRequest(credentials)
        .then((response) => {
          context.commit(mutationTypes.changeStatusSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.changeStatusFailure, result.response.data.message);
          reject(result.response.data.message);
        });
    });
  },
  [actionTypes.createDeviceRequest](context, credentials) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.createDeviceRequestStart);
      requestsApi
        .createDeviceRequest(credentials)
        .then((response) => {
          context.commit(mutationTypes.createDeviceRequestSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.createDeviceRequestFailure, result.response.data.code);
          reject(result.response.data.message);
        });
    });
  },
  [actionTypes.approveRequest](context, credentials) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.approveRequestStart);
      requestsApi
        .approveRequest(credentials)
        .then((response) => {
          context.commit(mutationTypes.approveRequestSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.approveRequestFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionTypes.createDeviceUpdateRequest](context, credentials) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.createDeviceUpdateRequestStart);
      requestsApi
        .createUpdateDeviceRequest(credentials)
        .then((response) => {
          context.commit(mutationTypes.createDeviceUpdateRequestSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.createDeviceUpdateRequestFailure);
          reject(result);
        });
    })
  },
  [actionTypes.changeDeviceUpdateRequest](context, credentials) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.changeDeviceUpdateRequestStart);
      requestsApi
        .changeUpdateDeviceRequest(credentials)
        .then((response) => {
          context.commit(mutationTypes.changeDeviceUpdateRequestSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.changeDeviceUpdateRequestFailure);
          reject(result);
        });
    })
  }
};

export default {
  state,
  mutations,
  actions,
  getters,
};
