import axios from '@/api/axios';

const suspendedWorkers = (credentials) => axios.post('/api/v1/reports/suspended', credentials);

const suspendedWorkersExport = (credentials) => axios.post('/api/v1/reports/suspended/export', credentials, {responseType: 'blob'});


export default {
  suspendedWorkers,
  suspendedWorkersExport
};