import axios from '@/api/axios';

const getExams = (credentials) => axios.post('/api/v1/exams', credentials);

const getExamsV2 = () => axios.get(`/api/v2/exams`);

const getExamsNew = (page) => axios.get(`/api/v2/exams?page=${page}`);

const getExamsOrderBy = (credentials) => axios.get(`/api/v2/exams?${credentials}`);

const getExamsForAdminPanel = (page, order_by) => axios.get(`/api/v2/exams?page=${page}&per_page=10&exams_order_by=${order_by}`);

const getExamsNewNew = (page, params, order_by) => axios.get(`/api/v2/exams?page=${page}&admission_to_work=${params}&exams_order_by=${order_by}`);

const getExamByPatientCode = (patient_code) => axios.get(`/api/v2/exams/${patient_code}`);

const getExamsByUserId = (user_id) => axios.get(`/api/v2/users/${user_id}/exams`);

const getExamsByDoctorId = (page, order_by, doctor_id) => axios.get(`/api/v2/exams?page=${page}&per_page=10&exams_order_by=${order_by}&doctor_id=${doctor_id}`);

const getHistoryExamsByPatientCode = (patient_code, page, params, date, order_by, order_by_number) => axios.get(`/api/v2/exams/${patient_code}?page=0&per_page=${page}&admission_to_work=${params}&exam_datetime_lte=${date}&exams_order_by=${order_by}%2C%20${order_by_number}`);

const getExamsByDevice = (page, device_serial) => axios.get(`/api/v2/exams?page=${page}&serial=${device_serial}`);

const getExamDetail = (exams_id) => axios.get(`/api/v2/exams/detail/${exams_id}`);

const getExamsReasons = (exam_id) => axios.get(`/api/v2/exams/medical_helper/${exam_id}`);

const avgExams = (patient_code) => axios.get(`/api/v2/patient/avg_exams_result/${patient_code}`);

const getExamById = (id) => axios.get(`/api/v1/exams/detail/${id}`);

const getCountExams = (credentials) => axios.post('/api/v1/exams/count', credentials);

const exportExams = (credentials) =>
  axios.post('/api/v1/exams/export', credentials, { responseType: 'blob' });

const exportExamsV2 = (credentials) =>
  axios.post(`/api/v2/exams/export?${credentials}`, {}, {responseType: 'blob'});

const exportExams266 = (credentials) =>
  axios.post('/api/v2/exams/export266', credentials);


const exportExams835 = (credentials) =>
  axios.post('/api/v1/exams/export_835', credentials, {responseType: 'blob'});

const getExamStats = (query_string) => axios.post(`/api/v2/stats/generate${query_string}`, {}, {responseType: 'blob'});


export default {
  getExams,
  getExamsNew,
  getExamsV2,
  getExamsByDevice,
  getExamById,
  getExamByPatientCode,
  getExamsForAdminPanel,
  getExamDetail,
  getCountExams,
  exportExams,
  exportExams835,
  getExamsReasons,
  getExamsNewNew,
  avgExams,
  getExamsOrderBy,
  getHistoryExamsByPatientCode,
  getExamsByUserId,
  getExamsByDoctorId,
  getExamStats,
  exportExamsV2,
  exportExams266
};
