import usersApi from '@/api/users';

const state = {
  data: null,
  count: null,
  isLoading: false,
  error: null,
  company_name: null,
};

export const mutationTypes = {
  getUsersStart: '[users] get data start',
  getUsersSuccess: '[users] get data success',
  getUsersFailure: '[users] get data failure',

  getCountUsersStart: '[users] get count start',
  getCountUsersSuccess: '[users] get count success',
  getCountUsersFailure: '[users] get count failure',

  changeDataStart: '[users] change data start',
  changeDataSuccess: '[users] change data success',
  changeDataFailure: '[users] change data failure',

  forgotPasswordStart: '[users] forgot password start',
  forgotPasswordSuccess: '[users] forgot password success',
  forgotPasswordFailure: '[users] forgot password failure',

  deleteUserStart: '[users] delete user start',
  deleteUserSuccess: '[users] delete user success',
  deleteUserFailure: '[users] delete user failure',
};

export const actionTypes = {
  getUsers: '[users] get data',
  getCountUsers: '[users] get count',
  changeEmail: '[users] change email',
  changeNames: '[users] change names',
  changePassword: '[users] change password',
  changeAvatar: '[users] change avatar',
  forgotPassword: '[users] forgot password',
  deleteUser: '[users] delete user',
};

export const gettersTypes = {};

const getters = {
  COMPANY_NAME: state => {
    return state.company_name;
  }
};

const mutations = {
  [mutationTypes.getUsersStart](state) {
    state.isLoading = true;
    state.data = null;
    state.error = null;
  },
  [mutationTypes.getUsersSuccess](state, payload) {
    state.isLoading = false;
    state.data = payload;
  },
  [mutationTypes.getUsersFailure](state, payload) {
    state.isLoading = false;
    state.error = payload;
  },

  [mutationTypes.getCountUsersStart](state) {
    state.count = null;
  },
  [mutationTypes.getCountUsersSuccess](state, payload) {
    state.count = payload;
  },
  [mutationTypes.getCountUsersFailure]() {},

  [mutationTypes.changeDataStart](state) {
    state.isLoading = true;
    state.error = null;
  },
  [mutationTypes.changeDataSuccess](state) {
    state.isLoading = false;
  },
  [mutationTypes.changeDataFailure](state, payload) {
    state.isLoading = false;
    state.error = payload;
  },

  [mutationTypes.forgotPasswordStart](state) {
    state.isLoading = true;
    state.error = null;
  },
  [mutationTypes.forgotPasswordSuccess](state) {
    state.isLoading = false;
  },
  [mutationTypes.forgotPasswordFailure](state, payload) {
    state.isLoading = false;
    state.error = payload;
  },

  [mutationTypes.deleteUserStart](state) {
    state.isLoading = true;
    state.error = null;
  },
  [mutationTypes.deleteUserSuccess](state) {
    state.isLoading = false;
  },
  [mutationTypes.deleteUserFailure](state, payload) {
    state.isLoading = false;
    state.error = payload;
  },
  SET_COMPANY_NAME: (state, payload) => {
    state.company_name = payload;
  }
};

const actions = {
  [actionTypes.getUsers](context, credentials) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getUsersStart);
      usersApi
        .getUsers(credentials)
        .then((response) => {
          context.commit(mutationTypes.getUsersSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getUsersFailure, result.response.data.message);
          reject(result.response.data.message);
        });
    });
  },
  [actionTypes.getCountUsers](context, credentials) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getCountUsersStart);
      usersApi
        .getCountUsers(credentials)
        .then((response) => {
          context.commit(mutationTypes.getCountUsersSuccess, response.data.total);
          resolve(response.data.total);
        })
        .catch((result) => {
          context.commit(mutationTypes.getCountUsersFailure);
          reject(result);
        });
    });
  },
  [actionTypes.changeEmail](context, credentials) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.changeDataStart);
      usersApi
        .changeUserEmail(credentials)
        .then(() => {
          context.commit(mutationTypes.changeDataSuccess);
          resolve();
        })
        .catch((result) => {
          context.commit(mutationTypes.changeDataFailure, result.response.data.message);
          reject(result.response.data.message);
        });
    });
  },
  [actionTypes.changeNames](context, credentials) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.changeDataStart);
      usersApi
        .changeUserNames(credentials)
        .then(() => {
          context.commit(mutationTypes.changeDataSuccess);
          resolve();
        })
        .catch((result) => {
          context.commit(mutationTypes.changeDataFailure, result.response.data.message);
          reject(result.response.data.message);
        });
    });
  },
  [actionTypes.changePassword](context, credentials) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.changeDataStart);
      usersApi
        .changeUserPassword(credentials)
        .then(() => {
          context.commit(mutationTypes.changeDataSuccess);
          resolve();
        })
        .catch((result) => {
          context.commit(mutationTypes.changeDataFailure, result.response.data.message);
          reject(result.response.data.message);
        });
    });
  },
  [actionTypes.changeAvatar](context, credentials) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.changeDataStart);
      usersApi
        .changeUserAvatar(credentials)
        .then(() => {
          context.commit(mutationTypes.changeDataSuccess);
          resolve();
        })
        .catch((result) => {
          context.commit(mutationTypes.changeDataFailure, result.response.data.message);
          reject(result.response.data.message);
        });
    });
  },
  [actionTypes.forgotPassword](context, credentials) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.forgotPasswordStart);
      usersApi
        .forgotPassword(credentials)
        .then(() => {
          context.commit(mutationTypes.forgotPasswordSuccess);
          resolve();
        })
        .catch((result) => {
          context.commit(mutationTypes.forgotPasswordFailure, result.response.data.message);
          reject(result.response.data.message);
        });
    });
  },
  [actionTypes.deleteUser](context, {id}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.deleteUserStart);
      usersApi
        .deleteUser(id)
        .then(() => {
          context.commit(mutationTypes.deleteUserSuccess);
          resolve();
        })
        .catch((result) => {
          context.commit(mutationTypes.deleteUserFailure, result.response.data.message);
          reject(result.response.data.message);
        });
    });
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
