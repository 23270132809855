const variables = {
  dom: {
    mainScroll: 'js-main-scroll',
  },
  state: {
    isModalOpen: false,
  },
  get windowWidth() {
    return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  },
  get windowHeight() {
    return (
      window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
    );
  },
  scrollbarWidth: window.innerWidth - document.documentElement.clientWidth,
  user: {
    roles: {
      admin: 'ADMIN',
      client: 'CLIENT',
      doctor: 'DOCTOR',
    },
    types: {
      person: 'person',
      company: 'company',
    },
  },
  exams: {
    statuses: {
      checked: 'checked',
      pending: 'pending',
    },
  },
  requests: {
    types: {
      user: 'registration',
      device: 'device',
      device_update: 'device_update'
    },
    statuses: {
      approved: 'approved',
      pending: 'pending',
      rejected: 'rejected',
    },
  },
  pagination: {
    limit: 15,
  },
  errors: {
    required: 'Обязательное поле',
    invalid: 'Некорректное значение',
    email: 'Некорректный e-mail',
    TIN: 'Некорректный ИНН',
    noMatches: {
      email: 'Новый e-mail должен отличаться от текущего',
      password: 'Новый пароль должен отличаться от текущего',
      names: 'Новые данные должны отличаться от текущих',
    },
    alphaNum: 'Только латинские буквы и цифры',
    password: {
      requirements:
        'Пароль должен быть не короче 6 символов и содержать цифры и буквы верхнего и нижнего регистра.',
      valid: 'Только латинские буквы, цифры и спецсимволы',
      minLength: 'Минимум 6 символов',
      containsUppercase: 'Не хватает буквы верхнего регистра',
      containsLowercase: 'Не хватает буквы нижнего регистра',
      containsNumber: 'Не хватает цифры',
      sameAs: 'Пароли не совпадают',
    },
  },
  map: {
    default: {
      apiKey: 'e2ad4f96-8633-477a-9781-718c888e12cf',
      lang: 'ru_RU',
      coordorder: 'latlong',
      version: '2.1',
    },
    router: {
      apiKey: 'cf55e06b-5c36-4e90-a5f0-9c57402dc312',
      lang: 'ru_RU',
      coordorder: 'latlong',
      version: '2.1',
    },
  },
  regexes: {
    password: new RegExp('^[a-zA-Z0-9#?!@$%^&*{}()=;:\'"<>,./|_+\\[\\]\\\\]+$'),
    alphaNumeric: new RegExp(/^[A-Za-zА-Яа-яЁё0-9]+$/),
    search: new RegExp(/\s+|[^A-Za-zА-Яа-яЁё0-9]/g),
  },
  dateFormat: 'DD.MM.YYYY HH:mm:ss',
};

export default variables;
