import axios from '@/api/axios';

const updateAutoDoctor = (credentials) => axios.post('/api/v1/auto_doctor', credentials); // update or create

const getAutoDoctor = () => axios.get('/api/v1/auto_doctor/info');

export default {
  getAutoDoctor,
  updateAutoDoctor,
};
