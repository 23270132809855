<template lang="pug">
  .profile-sidebar.d-none.d-xl-block
    component(:is="currentComponent")
</template>

<script>
import AppClientSidebar from '@/components/profile/client/ClientSidebar';
import AppAdminSidebar from '@/components/profile/admin/AdminSidebar';
import AppDoctorSidebar from '@/components/profile/doctor/DoctorSidebar';

export default {
  name: 'AppSidebar',
  data() {
    return {
      roleMapping: {
        client: AppClientSidebar,
        admin: AppAdminSidebar,
        doctor: AppDoctorSidebar,
      },
    };
  },
  computed: {
    currentComponent() {
      return this.roleMapping[this.$store.state.auth.userRole];
    },
  },
};
</script>

<style lang="scss">
.profile-sidebar {
  position: fixed;
  z-index: 10;
  width: 18%;
  height: 100%;
  left: 0;
  top: 0;
  padding-top: $spacer;
  padding-right: $spacer;

  @include mediaToDown($xxl) {
    width: 19%;
  }
  @include mediaToDown($sl) {
    width: 22%;
  }
  &__content {
    width: 100%;
    height: 100%;
    padding: $spacer * 2.5 $spacer * 2 $spacer * 4 $spacer * 2;
    background-color: get($colors, white);
    box-shadow: get($shadows, default);
    border-radius: 0 $border-radius-sm 0 0;
  }
  &__header {
    flex: 0 0 15%;
    padding-bottom: $spacer * 2;
    border-bottom: $border-width solid get($colors, gray);

    @include mediaToDown($xxl) {
      flex: 0 0 10%;
    }
  }
  &__menu {
    width: 100%;

    &-item {
      margin-bottom: $spacer * 1.4;

      &:last-child {
        margin-bottom: 0;
      }
      @include mediaToDown($xxl) {
        margin-bottom: $spacer;
      }
    }
    &-link {
      position: relative;
      padding: $spacer;

      span {
        position: relative;
        z-index: get($index, default);
      }
      &:before {
        @include transition();
        @include pseudo-wrapper();
        border-radius: $border-radius-sm;
        background: get($gradients, blue-light);
        opacity: 0;
      }
      @include interaction('mouse') {
        &:hover {
          &:before {
            opacity: 0.5;
          }
        }
      }
      &.is-active {
        &:before {
          opacity: 1;
        }
      }
      @include mediaToDown($xxl) {
        @include font-size(get($text-sizes, small));
      }
    }
    a {
      @include interaction('mouse') {
        &:hover {
          color: get($colors, blue-hover);
        }
      }
    }
  }
  .icon {
    &__plus-thin {
      width: $spacer * 1.3;
      height: $spacer * 1.3;
    }
  }
}
</style>
