<template lang="pug">
  transition(name="fade" mode="out-in")
    app-modal(v-if="isVisible" @close="closeModal" classes="small")
      template(v-slot:body)
        app-title(:classes="'mb-4'") Смена {{ isPerson ? 'Ф.И.О.' : 'наименования' }}
        app-validation-errors(
          v-if='error && showError'
          :validation-errors='error'
          :classes="'mb-4'"
        )
        form(@submit.prevent="onSubmit" :class="['form', {'is-submitting': isLoading}]")
          .row.flex-column.align-items-center.g-3.g-md-4
            template(v-if="isPerson")
              .col
                label.form__label
                  input(
                    :class="['form__field', { 'is-error': v$.firstName.$errors.length }]"
                    type="text"
                    v-model="v$.firstName.$model"
                    placeholder="Имя"
                  )
                  ul.form__error
                    li(v-for="error of v$.firstName.$errors" :key="error.$uid")
                      template(v-if="error.$validator === 'required'") {{ v.errors.required }}
                      template(v-if="error.$validator === 'noMatches'") {{ v.errors.noMatches.names }}
              
              .col
                label.form__label
                  input(
                    :class="['form__field', { 'is-error': v$.lastName.$errors.length }]"
                    type="text"
                    v-model="v$.lastName.$model"
                    placeholder="Фамилия"
                  )
                  ul.form__error
                    li(v-for="error of v$.lastName.$errors" :key="error.$uid")
                      template(v-if="error.$validator === 'required'") {{ v.errors.required }}
                      template(v-if="error.$validator === 'noMatches'") {{ v.errors.noMatches.names }}
              
              .col
                label.form__label
                  input(
                    class="form__field"
                    type="text"
                    v-model="middleName"
                    placeholder="Отчество"
                  )
            
            template(v-if="isCompany")
              .col
                label.form__label
                  input(
                    :class="['form__field', { 'is-error': v$.companyName.$errors.length }]"
                    type="text"
                    v-model="v$.companyName.$model"
                    placeholder="Полное наименование юридического лица"
                  )
                  ul.form__error
                    li(v-for="error of v$.companyName.$errors" :key="error.$uid")
                      template(v-if="error.$validator === 'required'") {{ v.errors.required }}
                      template(v-if="error.$validator === 'noMatches'") {{ v.errors.noMatches.names }}
                      
            .col.mt-5
              button(
                type="submit"
                :disabled="v$.$invalid"
                class=['button', 'button--accent', 'form__button']
              )
                span.button__content Подтвердить смену {{ isPerson ? 'Ф.И.О.' : 'наименования' }}
            
            .col-auto
              p.text-center.text-color--blue.text-size--small Для смены {{ isPerson ? 'Ф.И.О.' : 'наименования' }} введите новые данные
</template>

<script>
import {mapGetters, mapState} from 'vuex';
import {gettersTypes} from '@/store/modules/auth';

import useVuelidate from '@vuelidate/core';
import {required} from '@vuelidate/validators';

import is from 'is_js';
import variables from '@/helpers/variables';

import AppValidationErrors from '@/components/common/ValidationErrors';
import AppTitle from '@/components/common/Title';
import AppModal from '@/components/common/Modal';

export default {
  name: 'AppChangeNamesModal',
  components: {
    AppModal,
    AppTitle,
    AppValidationErrors,
  },
  props: {
    isVisible: {
      required: true,
      type: Boolean,
      default: false,
    },
    initialValues: {
      type: Object,
      required: true,
      default() {
        return null;
      },
    },
    error: {
      type: String,
      required: false,
      default: null,
    },
    isLoading: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      v: variables,
      companyName: this.initialValues.companyName,
      firstName: this.initialValues.firstName,
      middleName: this.initialValues.middleName,
      lastName: this.initialValues.lastName,
      slug: 'changeNames',
      showError: false,
    };
  },
  validations() {
    return {
      companyName: {
        noMatches: function (value) {
          return this.isCompany ? this.initialValues.companyName !== value : true;
        },
        required: this.isCompany ? required : false,
      },
      firstName: {
        noMatches: function (value) {
          return this.isPerson
            ? this.initialValues.firstName !== value ||
                this.initialValues.middleName !== this.middleName ||
                this.initialValues.lastName !== this.lastName
            : true;
        },
        required: this.isPerson ? required : false,
      },
      lastName: {
        noMatches: function (value) {
          return this.isPerson
            ? this.initialValues.firstName !== this.firstName ||
                this.initialValues.lastName !== value ||
                this.initialValues.middleName !== this.middleName
            : true;
        },
        required: this.isPerson ? required : false,
      },
      middleName: {
        noMatches: function (value) {
          if (is.not.empty(this.initialValues.middleName))
            return this.isPerson
              ? this.initialValues.firstName !== this.firstName ||
                  this.initialValues.lastName !== this.lastName ||
                  this.initialValues.middleName !== value
              : true;
          else return true;
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      currentUser: gettersTypes.currentUser,
      userRole: gettersTypes.userRole,
    }),
    ...mapState({
      currentUser: (state) => state.auth.currentUser,
    }),
    isPerson() {
      if (!this.currentUser) return null;
      return this.currentUser.user_type === this.v.user.types.person;
    },
    isCompany() {
      if (!this.currentUser) return null;
      return this.currentUser.user_type === this.v.user.types.company;
    },
  },
  methods: {
    onSubmit() {
      const form = {
        company_name: this.companyName,
        first_name: this.firstName,
        middle_name: this.middleName,
        last_name: this.lastName,
      };
      this.$emit('submit', {slug: this.slug, form});
      this.showError = true;
    },
    resetForm() {
      this.v$.$reset();

      this.companyName = this.initialValues.companyName;
      this.firstName = this.initialValues.firstName;
      this.middleName = this.initialValues.middleName;
      this.lastName = this.initialValues.lastName;

      this.showError = false;
    },
    closeModal() {
      this.resetForm();
      this.$emit('close', this.slug);
    },
  },
};
</script>

<style scoped lang="scss">
.form {
  &__field {
    color: get($colors, blue-dark);
  }
}
</style>
