<template lang="pug">
.inspection-card__exam-video
  template
    transition(name="fade" mode="out-in")
    video-component(:videoSource="videoSrc" :stopVideo="stopVideo")
</template>

<script>
import variables from '@/helpers/variables';
import AppSvgIcon from '@/components/common/SvgIcon';
import AppLoading from '@/components/common/Loading';
import {videoPlayer} from 'vue-video-player';
import videoComponent from './Video.vue';
// import examsApi from '@/api/exams';

export default {
  name: 'DoctorExamCardVideo',
  components: {
    videoPlayer,
    AppSvgIcon,
    AppLoading,
    videoComponent,
  },
  props: {
    src: {
      type: String,
      required: true,
      default: '',
    },
    stopVideo: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      v: variables,
      videoSrc: '',
      video: {
        isLoading: false,
        muted: false,
        language: 'en',
        sources: [{type: 'video/mp4', src: ''}],
        poster: '',
      },
    };
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player;
    },
  },
  watch: {
    src(value) {
      this.videoSrc = value;
    },
  },
};
</script>

<style lang="scss">
video {
  object-fit: contain;
}
// .buttons-container {
//   display: none;
// }
.inspection-card__exam-video {
  background: black;
  border-radius: 2px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.icon {
  &__no-video {
    width: $spacer * 10;
    height: $spacer * 10;
  }
}
</style>
