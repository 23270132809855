<template lang="pug">
  .profile-exams
     template(v-if='$route.params.id')
      router-view
     template(v-else)
      component( :is="currentComponent")
</template>

<script>
import AppAdminExams from '@/components/profile/admin/exams/AdminExams';
import AppDoctorExams from '@/components/profile/doctor/exams/DoctorExams';

export default {
  name: 'AppExams',
  data() {
    return {
      roleMapping: {
        client: AppAdminExams,
        admin: AppAdminExams,
        doctor: AppDoctorExams,
      },
    };
  },
  computed: {
    currentComponent() {
      return this.roleMapping[this.$store.state.auth.userRole];
    },
  },
};
</script>
