<template lang="pug">
  .select(:id="id" :class="[classes, {'is-open': isOpen}, `is-${type}`, {'text-size--small': isSorting}]")
    button(
      type="button"
      class=['select__button', 'd-flex align-items-center']
      v-click-outside="close"
      @click="isOpen = !isOpen"
    )
      span.me-2(v-if="isSorting") Сортировать&nbsp;по:
      span(v-if="selected")
        span.me-3(v-if="selected.icon")
          app-svg-icon(:name="selected.icon")
        span {{ selected.caption }}

      span.text-color--blue.text-family--light(v-else) {{ placeholder }}
      span.select__arrow.d-flex(:class="[isSorting ? 'ms-3' : 'ms-auto']")
        app-svg-icon(name="down-arrow")

    transition(name="fade")
      .select__dropdown(v-if="isOpen")
        ul.select__list
          li.select__list-el.d-flex.align-items-center(
            v-for="option in options"
            :key="option.value"
            :class="{'is-active': Boolean(option.selected)}"
            @click="select(option)"
          )
            span.d-flex.me-3(v-if="option.icon")
              app-svg-icon(:name="option.icon")
            span {{ option.caption }}

</template>

<script>
import AppSvgIcon from '@/components/common/SvgIcon';
import ClickOutside from 'vue-click-outside';

export default {
  name: 'AppSelect',
  components: {
    AppSvgIcon,
  },
  directives: {
    ClickOutside,
  },
  props: {
    options: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    type: {
      type: String,
      required: false,
      default: 'sorting',
    },
    placeholder: {
      type: String,
      required: false,
      default: null,
    },
    classes: {
      type: String,
      required: false,
      default: null,
    },
    id: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    selected() {
      return this.options.filter((option) => option.selected)[0];
    },
    isSorting() {
      return this.type === 'sorting';
    },
  },
  methods: {
    select(option) {
      this.$emit('select', option);
      this.close();
    },
    close() {
      this.isOpen = false;
    },
    clear() {
      this.options.map((el) => (el.selected = false));
    },
  },
};
</script>

<style lang="scss">
.select {
  position: relative;

  &.is-open {
    .select {
      &__arrow {
        transform: rotate(180deg);
      }
    }
  }
  &.is-form {
    width: 100%;

    @include interaction('mouse') {
      &:hover:not(.is-open) {
        .select {
          &__button {
            border-color: get($colors, blue-dark);
          }
          &__arrow {
            svg {
              fill: get($colors, blue);
            }
          }
        }
      }
    }
    &.is-open {
      .select {
        &__button {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          box-shadow: get($shadows, default);
          border-color: transparent;
        }
      }
    }
    .select {
      &__button {
        width: 100%;
        height: $input-height;
        border: $border-width solid get($colors, blue);
        border-radius: $border-radius-sm;
        padding: 0 $spacer * 1.6;
        color: get($colors, blue-dark);
        @include transition();
      }
      &__dropdown {
        margin-top: -$border-width;
        border-radius: 0 0 $border-radius-sm $border-radius-sm;
      }
      &__arrow {
        svg {
          fill: get($colors, blue);
        }
      }
    }
  }
  &.is-sorting {
    z-index: get($index, md);

    .select {
      &__dropdown {
        margin-top: $spacer;
        border-radius: $border-radius-sm;
      }
    }
  }
  &__arrow {
    @include transition(transform);

    svg {
      @include transition(fill);
      width: $spacer;
      height: $spacer;
    }
  }
  &__dropdown {
    position: absolute;
    z-index: get($index, md);
    top: 100%;
    left: 0;
    min-width: 100%;
    background-color: get($colors, white);
    box-shadow: get($shadows, down);
    overflow: hidden;
  }
  &__list {
    &-el {
      padding: $spacer $spacer * 1.6;
      @include transition();
      border-bottom: $border-width solid get($colors, blue-light);

      &:last-child {
        border-bottom: none;
      }
      @include interaction('mouse') {
        &:hover {
          cursor: pointer;
        }
      }
      &.is-active {
        color: get($colors, blue-dark);
        background: get($gradients, blue-light);
        border-color: transparent;
      }
    }
  }
}
</style>
