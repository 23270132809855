import {galleryMixin} from '@/mixins/gallery';
import variables from '@/helpers/variables';
import {eventBus} from '@/main';

export const examDetailMobileMixin = {
  mixins: [galleryMixin],
  data() {
    return {
      v: variables,
      isModalVisible: {
        examDetailMobile: false,
      },
      currentExam: {
        data: {},
        type: '',
      },
    };
  },
  mounted() {
    eventBus.$on('openGallery', (data) => this.openGallery(data));
  },
  methods: {
    openExamDetailMobile(data) {
      this.currentExam.data = data;

      this.openModal('examDetailMobile');
    },
  },
};
