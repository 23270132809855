<template lang="pug">
  fragment
    router-link(
      v-if="routerLink"
      :to="routerLink"
      :class="['title', `title--${type}`, classes || null]"
      :id="id"
    )
      slot

    h2(
      v-else
      :id="id"
      :class="['title', `title--${type}`, classes || null]"
    )
      slot

</template>

<script>
import {Fragment} from 'vue-fragment';

export default {
  name: 'AppTitle',
  components: {
    Fragment,
  },
  props: {
    type: {
      type: String,
      required: false,
      default: 'normal',
    },
    id: {
      type: String,
      required: false,
      default: null
    },
    classes: {
      type: String,
      required: false,
      default: null
    },
    routerLink: {
      type: Object,
      required: false,
      default() {
        return null
      }
    },
  },
};
</script>

<style lang="scss">
.title {
  text-transform: uppercase;
  color: get($colors, blue-dark);

  &--normal {
    @include font-size(get($title-sizes, normal));
    letter-spacing: get($letter-spacing, middle);
    font-weight: 400;
  }
  &--large {
    @include font-size(get($title-sizes, large));
    font-weight: 300;
    letter-spacing: get($letter-spacing, large);
  }
}
a.title {
  @include interaction('mouse') {
    &:hover {
      color: get($colors, turq-hover);
    }
  }
}
</style>
