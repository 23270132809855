<template lang="pug">
  button(
    type="button"
    @click="prevPage ? $router.push({name: prevPage}) : $router.go(-1)"
    class=['back-button', 'd-flex', 'link--accent']
    :class="classes"
  )
    app-svg-icon(name="back-arrow")

</template>

<script>
import AppSvgIcon from '@/components/common/SvgIcon';

export default {
  name: 'AppBackButton',
  components: {
    AppSvgIcon,
  },
  props: {
    prevPage: {
      type: String,
      required: false,
      default: null,
    },
    classes: {
      type: String,
      required: false,
      default: null,
    },
  },
};
</script>
