import {blockScroll} from '@/helpers/utils';
import variables from '@/helpers/variables';

export const modalsMixin = {
  data() {
    return {
      v: variables,
      isModalVisible: {
        success: false,
      },
    };
  },
  watch: {
    '$route.name'() {
      this.closeModal();
    },
  },
  methods: {
    openModal(slug) {
      blockScroll('enable');
      this.v.state.isModalOpen = true;
      if (slug) this.isModalVisible[slug] = true;
    },
    closeModal(slug) {
      blockScroll('disable');
      this.v.state.isModalOpen = false;
      if (slug) this.isModalVisible[slug] = false;
    },
  },
};
