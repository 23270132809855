<template>
<div class="chart" v-click-outside="onClickOutside">
    <canvas ref="canvas"></canvas>
</div>
</template>

<script>
import Chart from 'chart.js';
//import { createLogger } from 'vuex';
//import { createLogger } from 'vuex';
import vClickOutside from 'v-click-outside'
export default {
    name: 'Chart',
    data() {
        return {
            chart: {},
            //  loaded:false
        };
    },
    computed: {
        ctx() {
            return this.$refs?.canvas?.getContext('2d');
        }
    },
    directives: {
        clickOutside: vClickOutside.directive
    },
    watch: {
        '$store.state.chart.pressuare': {
            //immediate: true,
            handler() {
                // if(this.loaded===false){
                //alert(this.loaded)
                this.chart.data.labels = ["Повышенное давление", "Гипертония", "Пониженное давление", 'Нормальное давление']
                //this.chart.data.labels =  this.$store.getters.pieChart;
                this.chart.data.datasets[0] = {
                    data: this.$store.getters.pressuareRiskData,
                    borderWidth: 1,
                    pointHoverRadius: 15,
                    lineTension: 0,
                    backgroundColor: ['#B78CD9', '#E37FAF', '#90AAED', '#96E6FF', ]
                };
                this.chart.update();
                // this.loaded = true
                // }   
            }
        },

    },
    methods: {
        async onClickOutside(event) {
            console.log('Clicked outside. Event: ', event.target._prevClass)
            if (event.target._prevClass == 'col-md-12 d-md-flex align-items-center' || event.target._prevClass =='row align-items-center flex-md-row g-4') {
                this.chart.update();
                //alert(event.target.localName)
                const data = {
                    "filters": {
                        'date_from': this.$store.state.chart.chartFilter.filters.date_from,
                        'date_to': this.$store.state.chart.chartFilter.filters.date_to,
                        'time_from': this.$store.state.chart.chartFilter.filters.time_from,
                        'time_to': this.$store.state.chart.chartFilter.filters.time_to,
                        'type': '',
                        "users": {
                            "negative": false,
                             "payload": this.$store.state.chart.chartFilter.filters.users.payload
                        },
                        "patients": {
                            "negative": true,
                            "payload": this.$store.state.chart.chartFilter.filters.patients.payload
                        },

                    },
                    "paging_params": {
                        "limit": 15,
                        "offset": 0
                    },
                }
                this.$store.commit('loading', true);
                await this.$store.dispatch("actionGetDiagramPressuareTable", data);
                await this.$store.dispatch("actionGetPatients", data);
                this.$store.commit('loading', false);
            }
        }
    },
    mounted() {
        var th = this;
        this.chart = new Chart(this.ctx, {
            type: 'pie',
            data: {
                labels: ["Повышенное давление", "Гипертония", "Пониженное давление", 'Нормальное давление'],
                datasets: [{
                    data: [1],
                    //borderColor: "rgba(227, 127, 175, 0.5)",
                    borderWidth: 0,

                    lineTension: 0,
                    backgroundColor: ['#C0D8F1'],
                }, ]
            },

            options: {
                outerRadius: 10,
                layout: {
                    padding: 30
                },

                async 'onClick'(_, item) {

                    this.chart.update();
                    //  const change = {
                    //             sliceIndex: 100,
                    //             newOuterRadius: 150
                    //         }
                    item[0]['_model'].outerRadius = 207;

                    console.log('wsfewegefew', item[0])
                    // console.log ('legend onClick', evt);
                    //  let that = this;
                    const level = item[0]['_model']['label'];
                    var levelForFilter = '';
                    if (level === 'Нормальное давление') {
                        levelForFilter = 'normal_pressure'
                    } else if (level === 'Повышенное давление') {
                        levelForFilter = 'high_pressure'
                    } else if (level === 'Гипертония') {
                        levelForFilter = 'hypertension'
                    } else if (level === 'Пониженное давление') {
                        levelForFilter = 'low_pressure'
                    }
                    //alert(levelForFilter)
                    const data = {
                        "filters": {
                            'date_from': th.$store.state.chart.chartFilter.filters.date_from,
                            'date_to': th.$store.state.chart.chartFilter.filters.date_to,
                            'time_from': th.$store.state.chart.chartFilter.filters.time_from,
                            'time_to': th.$store.state.chart.chartFilter.filters.time_to,
                            'type': levelForFilter,
                            "users": {
                                "negative": false,
                                "payload": th.$store.state.chart.chartFilter.filters.users.payload
                            },
                            "patients": {
                                "negative": true,
                                "payload": th.$store.state.chart.chartFilter.filters.patients.payload
                            },

                        },
                        "paging_params": {
                            "limit": 15,
                            "offset": 0
                        },
                    }
                    th.$store.commit('loading', true);
                    //await this.$store.dispatch("actionGetDiagramPressuareTable", data);
                    await th.$store.dispatch("actionGetDiagramPressuareTable", data);
                    await th.$store.dispatch("actionGetPatients", data);
                    // console.log('legd item', item[0]['_model']['label']);
                    th.$store.commit('loading', false);
                },

                legend: {
                    display: false,
                },

                responsive: true,
                maintainAspectRatio: false,
                tooltips: {
                    displayColors: false,
                    enabled: true,
                    mode: 'single',
                    callbacks: {
                    label: function(tooltipItems,data) { 
                        console.log(data.datasets[0].data[tooltipItems.index])
                        console.log(data)
                        return data.labels[tooltipItems.index] +': '+ data.datasets[0].data[tooltipItems.index] + '%';
                    }
                }
              }
            }
        });
    }
}
</script>

<style lang="scss" scoped>
.chart {
    position: relative;
    width: 450px;
    height: 450px;

    @media (max-width: 768px) {
        width: 330px;
        height: 330px;
        margin: auto;
    }

    canvas {
        cursor: pointer;
        border-radius: 50%;
    }
}
</style>
