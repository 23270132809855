<template lang="pug">
  component(:is="currentComponent")
</template>

<script>
import AppAdminPatients from '@/components/profile/admin/patients/AdminPatients';

export default {
  name: 'AppPatients',
  data() {
    return {
      roleMapping: {
        client: AppAdminPatients,
        admin: AppAdminPatients,
      },
    };
  },
  computed: {
    currentComponent() {
      return this.roleMapping[this.$store.state.auth.userRole];
    },
  },
};
</script>


<style lang="scss">
  .profile-patients {
    .profile-table {
      &__row {
        & + .profile-table__row {
          margin-top: $border-width * 2;
        }
        @include mediaToDown($xl) {
          & + .profile-table__row {
            margin-top: $spacer * 0.6;
          }
        }
      }
    }
  }
</style>