<template>
<div class="chart" v-click-outside="onClickOutside">
    <canvas ref="canvas"></canvas>
</div>
</template>

<script>
import Chart from 'chart.js';
import vClickOutside from 'v-click-outside'
export default {
    name: 'Chart',
    data() {
        return {
            chart: {},
            // labels: [0],
            // data: [0]
             windowWidth: window.innerWidth
        };
    },
    directives: {
        clickOutside: vClickOutside.directive
    },
    computed: {
        ctx() {
            return this.$refs?.canvas?.getContext('2d');
        }
    },
    watch: {
        '$store.state.chart.chart.result': {
            //immediate: true,
            handler() {  
                this.chart.data.labels = ["Допущено","Нет заключения","Не допущено",'Не завершен']
                //this.chart.data.labels =  this.$store.getters.pieChart;
                this.chart.data.datasets[0] = {
                    data:this.$store.getters.pieChart,
                    borderWidth: 1,
                    lineTension: 0,
                    backgroundColor: ['#5BCB88' ,'#F5C261','#E37FAF', '#ccc']
                };
                this.chart.update();
            }
        }
    },
        methods: {
        async onClickOutside(event) {
            console.log('Clicked outside. Event: ', event.target._prevClass)
             if (event.target._prevClass == 'col-14 col-md-12 d-md-flex align-items-center' || event.target._prevClass =='row align-items-center flex-md-row g-4') {
                 this.chart.update();
                  //alert(event.target.localName)
            const data = {
                "filters": {
                    'date_from': this.$store.state.chart.chartFilter.filters.date_from,
                    'date_to': this.$store.state.chart.chartFilter.filters.date_to,
                    'time_from': this.$store.state.chart.chartFilter.filters.time_from,
                    'time_to': this.$store.state.chart.chartFilter.filters.time_to,
                    'admission_to_work': this.$store.state.chart.admissions,
                    'type': '',
                    "users": {
                        "negative": false,
                        "payload": this.$store.state.chart.chartFilter.filters.users.payload
                    },
                    "patients": {
                        "negative": true,
                        "payload": this.$store.state.chart.chartFilter.filters.patients.payload
                    },

                },
                "paging_params": {
                    "limit": 15,
                    "offset": 0
                },
            }
               this.$store.commit('loading', true);
                await this.$store.dispatch("actionGetChartDataTable", data);
                await this.$store.dispatch("actionGetPatients", data);
                this.$store.commit('loading', false);
            }
        }
    },
    mounted() {
         var th = this;
        this.chart = new Chart(this.ctx, {
            type: 'pie',
            data: {
                labels: [0],
                datasets: [{
                    data: [1],
                    //borderColor: "rgba(227, 127, 175, 0.5)",
                    borderWidth: 0,
                    pointRadius: 12,
                    pointHoverRadius: 15,
                    lineTension: 0,
                    backgroundColor: ['#C0D8F1']
                },
                ]
            },
            options: {
                outerRadius: 10,
                layout: {
                    padding: 30
                },
                       async 'onClick'(_, item) {

                    this.chart.update();
                    //  const change = {
                    //             sliceIndex: 100,
                    //             newOuterRadius: 150
                    //         }
                    item[0]['_model'].outerRadius =  th.windowWidth>768?210:140;

                    console.log('wsfewegefew', item[0])
                    // console.log ('legend onClick', evt);
                    //  let that = this;
                    const level = item[0]['_model']['label'];
                    var levelForFilter = '';
                    if (level === 'Допущено') {
                        levelForFilter = 'admissed'
                    } else if (level === 'Не допущено') {
                        levelForFilter = 'not_admissed'
                    } else if (level === 'Нет заключения') {
                        levelForFilter = 'not_conclused'
                    } else if (level === 'Не завершен') {
                        levelForFilter = 'not_completed'
                    }
                    
                    //alert(levelForFilter)
                    const data = {
                        "filters": {
                            'date_from': th.$store.state.chart.chartFilter.filters.date_from,
                            'date_to': th.$store.state.chart.chartFilter.filters.date_to,
                            'time_from': th.$store.state.chart.chartFilter.filters.time_from,
                            'time_to': th.$store.state.chart.chartFilter.filters.time_to,
                            'admission_to_work': [levelForFilter],
                            "users": {
                                "negative": false,
                                "payload": th.$store.state.chart.chartFilter.filters.users.payload
                            },
                            "patients": {
                                "negative": true,
                                "payload": th.$store.state.chart.chartFilter.filters.patients.payload
                            },

                        },
                        "paging_params": {
                            "limit": 15,
                            "offset": 0
                        },
                    }
                
                        //await this.$store.dispatch("actionGetDiagramPressuareTable", data);
                        th.$store.commit('loading', true);
                        await th.$store.dispatch("actionGetChartDataTable", data);
                        th.$store.commit('loading', false);
                    console.log('legd item', item[0]['_model']['label']);

                },
                legend: {
                    display: false,
                },
                responsive: true,
                maintainAspectRatio: false,
                tooltips: {
  displayColors: false
}
            }
        });
    }
}
</script>

<style lang="scss" scoped>
.chart {
    position: relative;
    width: 450px;
    height: 450px;
 @media (max-width: 768px){
  width: 330px;
  height: 330px;
  margin: auto;
 }
    canvas {
        cursor: pointer;
        border-radius: 50%;
    }
}
</style>
