import axios from '@/api/axios';

const getOutgoingMedDocumentV2 = (credentials) => axios.get(`/api/v2/med_doc/outcoming_documents?${credentials}`);

const exportOutcomingMeddocs = (credentials) =>
  axios.get(`/api/v2/med_doc/export_outcoming_documents?${credentials}`, {responseType: 'blob'});


export default {
    getOutgoingMedDocumentV2,
    exportOutcomingMeddocs,
};
