<template>
<div class="chart-wrp">
<div class="chart chart--alco" :style="{width: 'calc(107px * '+$store.state.chart.chart.total+''}">
    <canvas ref="canvas"></canvas>
</div>
</div>
</template>

<script>
import Chart from 'chart.js';

export default {
    name: 'Chart',
    data() {
        return {
            chart: {},
            // labels: [0],
            // data: [0]
        };
    },
    computed: {
        ctx() {
            return this.$refs?.canvas ?.getContext('2d');
        }
    },
    watch: {
        '$store.state.chart.chart.result': {
            //immediate: true,
            handler() {
                this.chart.data.labels = this.$store.getters.dateChart;
                this.chart.data.datasets[0] = {
                    label: 'Алкоголь',
                    data: this.$store.getters.alcohol,
                    backgroundColor: 'rgba(227, 127, 175, 0)',
                    borderColor: "rgba(245, 194, 97, 0.5)",
                    borderWidth: 2,
                    pointRadius: 12,
                    pointBackgroundColor: "rgba(245, 194, 97, 0.5)",
                    pointHoverRadius: 15,
                    lineTension: 0,
                };
                this.chart.update();
            }
        }
    },
    mounted() {
        this.chart = new Chart(this.ctx, {
            type: 'line',
            data: {
                labels: this.$store.getters.dateChart,
                datasets: [{
                    label: 'Алкоголь',
                    data: this.$store.getters.alcohol,
                    backgroundColor: 'rgba(227, 127, 175, 0)',
                    borderColor: "rgba(245, 194, 97, 0.5)",
                    borderWidth: 2,
                    pointRadius: 12,
                    pointBackgroundColor: "rgba(245, 194, 97, 0.5)",
                    pointHoverRadius: 15,
                    lineTension: 0,

                }]
            },
        options: {
                 layout: {
            padding:{
                top:15
            }
        },
                legend: {
                    display:false,
                },
                scales: {
                    yAxes: [{
                        display: false,
                        ticks: {
                            beginAtZero: true
                        }
                    }],
                    xAxis: {
                        // The axis for this scale is determined from the first letter of the id as `'x'`
                        // It is recommended to specify `position` and / or `axis` explicitly.

                        ticks: {
                            beginAtZero: true,
                        }
                    },

                },
                responsive: true,
                maintainAspectRatio: false,
            }
        });
    }
}
</script>

<style lang="scss">
.chart {
    position: relative;
    width: 100%;
    height: 150px;
         &--alco{
         background: url('../../assets/img/alco.svg') -8px 72% no-repeat;
    }
}
</style>
