<template lang="pug">
simplebar.police
  .container-fluid
    .row.flex-column.align-items-center
      .col-14.col-md-9.col-lg-12.col-xl-10.col-xxl-7
        router-link(:to="{name: 'home'}")
          app-logo
        .mt-50.mt-md-100
          .police-exam-detail.police-exam-detail--short
            .police-exam-detail__content.position-relative.check
              .check__header Проверка осмотра
              .check__desc Введите идентификатор осмотра. Идентификатор указан на талоне осмотра и отображается в мобильном приложении пользователя
              form.check__form
              input.check__input(v-model="number" placeholder="Идентификатор осмотра")
              button.check__button(@click="sendNumber()" :disabled="number=='' ? '' : disabled") Поиск
</template>

<script>
import AppLogo from '@/components/common/Logo';
import simplebar from 'simplebar-vue';
import AppPoliceExamDetail from '@/components/police/PoliceExamDetail';

export default {
    name: 'AppPolice',
    components: {
        AppLogo,
        simplebar,
        AppPoliceExamDetail,
    },
    data() {
      return {
        number:''
      }
    },
    methods:{
      sendNumber(){
window.location.href = `/police/${this.number}`
      }
    }
};
</script>

<style lang="scss">
.check {
    font-family: 'Roboto';

    &__header {
        color: #586387;
        font-weight: 300;
        font-size: 22px;
        line-height: 127%;
        text-align: center;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        margin-top: 25px;
    }

    &__desc {
        max-width: 513px;
        margin-left: auto;
        margin-right: auto;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        /* or 150% */
        margin-top: 10px;
        text-align: center;
        color: #989FB8;
    }

    &__form {}

    &__input {
        margin-top: 26px;
        max-width: 570px;
        margin-left: auto;
        margin-right: auto;
        display: block;
        width: 100%;
        border: 1px solid #DFE4F8;
        padding-top: 8px;
        padding-bottom: 8px;
        border-radius: 6px;
        padding-left: 10px;

        &::placeholder {
            color: #C5CBE1;
            font-size: 14px;
        }
    }

    &__button {
        margin-top: 26px;
        display: block;
        max-width: 570px;
        margin-left: auto;
        margin-right: auto;
        display: block;
        width: 100%;
        background: linear-gradient(98.53deg, #EC9D25 34.72%, #EC8525 91.84%);
        border-radius: 6px;
        font-size: 14px;
        line-height: 1;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        color: #FFFFFF;
        padding-top: 11px;
        padding-bottom: 11px;
        
    }

}
    .check__button[disabled=disabled], .check__button:disabled {
  cursor: inherit;
}
.police-exam-detail--short {
    max-width: 830px;
    margin-left: auto;
    margin-right: auto;
}
</style>
