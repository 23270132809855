<template lang="">
  <div class="video-container">
    <video
      v-if="videoSource !== null"
      ref="videoPlayer"
      :width="width"
      :key="videoSource"
      :height="height"
      controls
      @play="onPlay"
      @pause="onPause"
      @seeked="onSeeked"
    >
      <source :src="videoSource" type="video/mp4" />
    </video>
    <div class="buttons-container">
      <button @click="changePlaybackRate(2)">2x</button>
      <button @click="changePlaybackRate(5)">5x</button>
      <button @click="changePlaybackRate(10)">10x</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    videoSource: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      default: '450px',
    },
    height: {
      type: String,
      default: '270px',
    },
    stopVideo: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      playbackRate: 1,
    };
  },
  watch: { 
    stopVideo: function(newVal, oldVal) { // watch it
      console.log('Prop video changed: ', newVal, ' | was: ', oldVal);
      if (newVal === true) {
        this.pauseVideo();
      }
    },
    videoSource: function(newVal) {
      console.log('video component', newVal);
    }
  },
  methods: {
    changePlaybackRate(rate) {
      const videoPlayer = this.$refs.videoPlayer;
      videoPlayer.playbackRate = rate;
      this.playbackRate = rate;
    },
    pauseVideo() {
      this.$refs.videoPlayer.pause();
    },
    onPlay() {
      console.log('Video is playing');
    },
    onPause() {
      console.log('Video is paused');
    },
    onSeeked() {
      console.log('Video is seeked');
    },
  },
};
</script>

<style>
.buttons-container {
  display: flex;
  justify-content: space-evenly;
  margin-top: 5px;
}
video {
  object-fit: fill;
}
</style>
