<template lang="pug">
  transition(name="fade" mode="out-in")
    app-modal(v-if="isVisible" @close="closeModal" classes="small")
      template(v-slot:body)
        app-title(:classes="'mb-4'") Смена пароля
        app-validation-errors(
          v-if='error && showError'
          :validation-errors='error'
          :classes="'mb-4'"
        )
        form(@submit.prevent="onSubmit" :class="['form', {'is-submitting': isLoading}]")
          .row.flex-column.align-items-center.g-3.g-md-4
            .col
              label.form__label
                input(
                  :class="['form__field', { 'is-error': v$.password.$errors.length }]"
                  :type="showPass ? 'text' : 'password'"
                  v-model="v$.password.$model"
                  placeholder="Старый пароль"
                )
                span(class="form__icon cursor-pointer" @click="showPass = !showPass")
                  app-svg-icon(v-if="showPass" :name="'eye-open'")
                  app-svg-icon(v-else :name="'eye-close'")
      
              ul.form__error
                li(v-for="error of v$.password.$errors" :key="error.$uid")
                  template(v-if="error.$validator === 'required'") {{ v.errors.required }}
                  
                  template(v-if="v$.password.$model")
                    template(v-if="error.$validator === 'minLength'") {{ v.errors.password.minLength }}
                    template(v-if="error.$validator === 'valid'") {{ v.errors.password.valid }}
                    template(v-if="error.$validator === 'containsUppercase'") {{ v.errors.password.containsUppercase }}
                    template(v-if="error.$validator === 'containsLowercase'") {{ v.errors.password.containsLowercase }}
                    template(v-if="error.$validator === 'containsNumber'") {{ v.errors.password.containsNumber }}
                    
            .col
              label.form__label
                input(
                  :class="['form__field', { 'is-error': v$.newPassword.$errors.length }]"
                  :type="showPass ? 'text' : 'password'"
                  v-model="v$.newPassword.$model"
                  placeholder="Новый пароль"
                )
                span(class="form__icon cursor-pointer" @click="showPass = !showPass")
                  app-svg-icon(v-if="showPass" :name="'eye-open'")
                  app-svg-icon(v-else :name="'eye-close'")
      
              ul.form__error
                li(v-for="error of v$.newPassword.$errors" :key="error.$uid")
                  template(v-if="error.$validator === 'required'") {{ v.errors.required }}
                  
                  template(v-if="v$.newPassword.$model")
                    template(v-if="error.$validator === 'noMatches'") {{ v.errors.noMatches.password }}
                    template(v-if="error.$validator === 'minLength'") {{ v.errors.password.minLength }}
                    template(v-if="error.$validator === 'valid'") {{ v.errors.password.valid }}
                    template(v-if="error.$validator === 'containsUppercase'") {{ v.errors.password.containsUppercase }}
                    template(v-if="error.$validator === 'containsLowercase'") {{ v.errors.password.containsLowercase }}
                    template(v-if="error.$validator === 'containsNumber'") {{ v.errors.password.containsNumber }}
  
            .col
              label.form__label
                input(
                  :class="['form__field', { 'is-error': v$.confirmPassword.$errors.length }]"
                  :type="showPass ? 'text' : 'password'"
                  v-model="v$.confirmPassword.$model"
                  placeholder="Повторите новый пароль"
                )
                span(class="form__icon cursor-pointer" @click="showPass = !showPass")
                  app-svg-icon(v-if="showPass" :name="'eye-open'")
                  app-svg-icon(v-else :name="'eye-close'")
      
              ul.form__error
                li(v-for="error of v$.confirmPassword.$errors" :key="error.$uid")
                  template(v-if="error.$validator === 'required'") {{ v.errors.required }}
                  template(v-if="v$.confirmPassword.$model && error.$validator === 'sameAsPassword'") {{ v.errors.password.sameAs }}
            
            .col.mt-5
              button(
                type="submit"
                :disabled="v$.$invalid"
                class=['button', 'button--accent', 'form__button']
              )
                span.button__content Подтвердить смену пароля
          
</template>

<script>
import useVuelidate from '@vuelidate/core';
import {required, sameAs, minLength} from '@vuelidate/validators';

import variables from '@/helpers/variables';

import AppValidationErrors from '@/components/common/ValidationErrors';
import AppTitle from '@/components/common/Title';
import AppModal from '@/components/common/Modal';
import AppSvgIcon from '@/components/common/SvgIcon';

export default {
  name: 'AppChangePassModal',
  components: {
    AppModal,
    AppTitle,
    AppSvgIcon,
    AppValidationErrors,
  },
  props: {
    isVisible: {
      required: true,
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      required: false,
      default: null,
    },
    isLoading: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      v: variables,
      password: '',
      newPassword: '',
      confirmPassword: '',
      showPass: false,
      showError: false,
      slug: 'changePassword',
    };
  },
  validations() {
    return {
      password: {
        required,
        valid: function (value) {
          return this.v.regexes.password.test(value);
        },
        containsUppercase: function (value) {
          return /[A-Z]/.test(value);
        },
        containsLowercase: function (value) {
          return /[a-z]/.test(value);
        },
        containsNumber: function (value) {
          return /[0-9]/.test(value);
        },
        minLength: minLength(6),
      },
      newPassword: {
        required,
        valid: function (value) {
          return this.v.regexes.password.test(value);
        },
        containsUppercase: function (value) {
          return /[A-Z]/.test(value);
        },
        containsLowercase: function (value) {
          return /[a-z]/.test(value);
        },
        containsNumber: function (value) {
          return /[0-9]/.test(value);
        },
        noMatches: function (value) {
          return this.password !== value;
        },
        minLength: minLength(6),
      },
      confirmPassword: {
        required,
        sameAsPassword: sameAs(this.newPassword),
      },
    };
  },
  methods: {
    onSubmit() {
      const form = {
        old_password: this.password,
        new_password: this.newPassword,
      };
      this.$emit('submit', {slug: this.slug, form});
      this.showError = true;
    },
    resetForm() {
      this.v$.$reset();

      this.password = '';
      this.newPassword = '';
      this.confirmPassword = '';

      this.showPass = false;
      this.showError = false;
    },
    closeModal() {
      this.resetForm();
      this.$emit('close', this.slug);
    },
  },
};
</script>

<style scoped lang="scss">
.form {
  &__field {
    color: get($colors, blue-dark);
  }
}
</style>
