import Vue from 'vue';
import Vuex from 'vuex';

import auth from '@/store/modules/auth';
import settings from '@/store/modules/settings';
import docs from '@/store/modules/docs';
import notifications from '@/store/modules/notifications';
import filters from '@/store/modules/filters';
import requests from '@/store/modules/requests';
import devices from '@/store/modules/devices';
import exams from '@/store/modules/exams';
import users from '@/store/modules/users';
import patients from '@/store/modules/patients';
import doctors from '@/store/modules/doctors';
import autoDoctor from '@/store/modules/autoDoctor';
import chart from '@/store/modules/chart';
import firmware from '@/store/modules/firmware';
import documentation from '@/store/modules/documentation';
Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    auth,
    settings,
    docs,
    notifications,
    filters,
    requests,
    devices,
    exams,
    users,
    patients,
    doctors,
    autoDoctor,
    chart,
    firmware,
    documentation
  },
});

export default store;
