<template lang="pug">
  simplebar.police
    .container-fluid
      .row.flex-column.align-items-center
        .col-14.col-md-9.col-lg-12.col-xl-10.col-xxl-7
          router-link(:to="{name: 'home'}")
            app-logo

          .mt-50.mt-md-100
            app-police-exam-detail

</template>

<script>
import AppLogo from '@/components/common/Logo';
import simplebar from 'simplebar-vue';
import AppPoliceExamDetail from '@/components/police/PoliceExamDetail';

export default {
  name: 'AppPolice',
  components: {
    AppLogo,
    simplebar,
    AppPoliceExamDetail,
  },
};
</script>
